import {FC, useEffect, useState} from 'react'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteEp12a2DTO
  experimento: ExperimentoEp12a2DTO
}

const GraficoBurbujasReporteEp12a2: FC<Props> = ({experimento, reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  const getBubbleSize = (value: number) => {
    return Math.ceil((value * 100) / experimento.Datos.length)
  }

  useEffect(() => {
    const chartInfo = {
      title: intl.formatMessage({id: 'HEADER.BUBBLE_CHART_RESULTS'}),
      xAxis: `${intl.formatMessage({
        id: 'SETTINGS.POSITIVE',
      })} - ${intl.formatMessage({id: 'HEADER.COMPARATOR_METHOD'})} - ${intl.formatMessage({
        id: 'SETTINGS.NEGATIVE',
      })}`,
      yAxis: `${intl.formatMessage({
        id: 'SETTINGS.POSITIVE',
      })} - ${intl.formatMessage({id: 'HEADER.EVALUATED_METHOD'})} - ${intl.formatMessage({
        id: 'SETTINGS.NEGATIVE',
      })}`,
    }
    const series = []

    if (reporte.MatrizCalculos.TP)
      series.push({x: 5, y: 10, z: getBubbleSize(reporte.MatrizCalculos.TP), color: '#44b6ae'})
    if (reporte.MatrizCalculos.FP)
      series.push({x: 25, y: 10, z: getBubbleSize(reporte.MatrizCalculos.FP), color: '#e35b5a'})
    if (reporte.MatrizCalculos.TN)
      series.push({x: 25, y: 25, z: getBubbleSize(reporte.MatrizCalculos.TN), color: '#44b6ae'})
    if (reporte.MatrizCalculos.FN)
      series.push({x: 5, y: 25, z: getBubbleSize(reporte.MatrizCalculos.FN), color: '#e35b5a'})

    setOptions({
      chart: {
        type: 'bubble',
      },
      title: {
        text: chartInfo.title,
        x: -20,
      },
      xAxis: {
        min: 0,
        max: 30,
        tickInterval: 5,
        title: {
          text: chartInfo.xAxis,
        },
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        max: 30,
        tickInterval: 5,
        title: {
          text: chartInfo.yAxis,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 1,
      },
      plotOptions: {
        series: {
          animation: false,
        },
        bubble: {
          minSize: '10%',
          maxSize: '20%',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: series,
          type: 'bubble',
        },
      ],
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoBurbujasReporteEp12a2
