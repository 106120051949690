import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {convertToDecimal} from '../../../../helpers'
more(Highcharts)

interface Props {
  info: any
}

const RenderAnalisisHistogramEqapt: FC<Props> = ({info}) => {
  const [options, setOptions] = useState<Highcharts.Options>({})

  const getHistogramSerie = (values: any, color: string): Highcharts.SeriesOptionsType => {
    return {
      type: 'bar',
      color: color,
      data: values,
      pointPadding: 0,
      groupPadding: 0,
    }
  }

  useEffect(() => {
    const series: Array<Highcharts.SeriesOptionsType> = []
    const xValues = info.intervals.map((interval: any) => interval.min)
    const yValues = info.intervals.map((interval: any) => interval.count)

    series.push(getHistogramSerie(yValues, '#3568b8'))

    setOptions({
      chart: {
        inverted: true,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: undefined,
        },
        categories: xValues,
        tickInterval: 1,
      },
      yAxis: {
        title: {
          text: 'Frecuencia',
        },
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [info])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default RenderAnalisisHistogramEqapt
