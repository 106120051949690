import {FC} from 'react'
import {toValueWithPrecisionString} from '../../../helpers'
import {useIntl} from 'react-intl'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'

interface Props {
  reporte: ReporteEp6DTO
}

const ReporteEp6ExperimentoInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const experimento = reporte.Experimento
  const valorMuestraAsignada = reporte?.Experimento.ValoresAsignadosMuestras

  const getValorAsignadoMuestra = () => {
    switch (valorMuestraAsignada) {
      case 'no-seriada-con-valor-asignado':
        return intl.formatMessage({id: 'SAMPLE_1'})
      case 'no-seriada-sin-valor-asignado':
        return intl.formatMessage({id: 'SAMPLE_2'})
      case 'seriada-con-valor-asignado':
        return intl.formatMessage({id: 'SAMPLE_3'})
      case 'seriada-sin-valor-asignado':
        return intl.formatMessage({id: 'SAMPLE_4'})
      case 'con-valor-asignado':
        return intl.formatMessage({id: 'SAMPLE_5'})
    }
  }

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-5'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{experimento.Fecha}</span>
          <span className='text-gray-600 col-5'>{`${experimento.ProcedimientoMedida.Equipo.Descripcion} / ${experimento.ProcedimientoMedida.Equipo.Plataforma} | ${experimento.ProcedimientoMedida.Mensurando.Analito} / ${experimento.ProcedimientoMedida.Mensurando.Matriz} / ${experimento.ProcedimientoMedida.Mensurando.Unidades} | ${experimento.ProcedimientoMedida.Metodo.Reactivo} / ${experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.ESA_EAA_MODEL'})}
          </label>
          <label className='form-label col-1'>ESa</label>
          <label className='form-label col-1'>EAa</label>
          <label className='col-3 form-label'>{intl.formatMessage({id: 'HEADER.REAGENT'})}</label>
          <label className='col-3 form-label'>{intl.formatMessage({id: 'SETTINGS.CALIPER'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>
            {experimento.EspDesempAnalitico.ModeloESaEAa.Id === 1
              ? intl.formatMessage({id: 'HEADER.ETA_BUDGET'})
              : intl.formatMessage({id: 'HEADER.PERFORMANCE_SPECIFICATIONS'})}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(experimento.EspDesempAnalitico.ESa)}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(experimento.EspDesempAnalitico.EAa)}
          </span>
          <span className='col-3 text-gray-600'>{`${experimento.Reactivo.Lote} ${
            experimento.Reactivo.Vencimiento ? `(${experimento.Reactivo.Vencimiento})` : ''
          }`}</span>
          <span className='col-3 text-gray-600'>{`${experimento.Calibrador.Lote} ${
            experimento.Calibrador.Vencimiento ? `(${experimento.Calibrador.Vencimiento})` : ''
          }`}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATION_TYPE'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.SAMPLES_AMOUNT'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.REPLICATES_AMOUNT'})}
          </label>
        </div>
        <div className='row'>
          <span className='col-2 text-gray-600'>
            {reporte.Experimento.TipoEvaluacion === 'verificacion'
              ? intl.formatMessage({id: 'HEADER.VERIFICATION'})
              : intl.formatMessage({id: 'HEADER.VALIDATION'})}
          </span>
          <span className='col-2 text-gray-600'>{reporte.Experimento.CantMuestras}</span>
          <span className='col-2 text-gray-600'>{reporte.Experimento.CantReplicados}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-6'>
            {intl.formatMessage({id: 'HEADER.ASSIGNED_SAMPLE_VALUES'})}
          </label>
        </div>
        <div className='row'>
          <span className='col-6 text-gray-600'>{getValorAsignadoMuestra()}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          {(valorMuestraAsignada === 'no-seriada-con-valor-asignado' ||
            valorMuestraAsignada === 'seriada-con-valor-asignado') && (
            <>
              <label className='form-label col-3'>
                {intl.formatMessage({id: 'HEADER.HIGH_SAMPLE_ASSIGNED_VALUE'})}
              </label>
              <label className='form-label col-3'>
                {intl.formatMessage({id: 'HEADER.LOW_SAMPLE_ASSIGNED_VALUE'})}
              </label>
            </>
          )}
          {valorMuestraAsignada === 'no-seriada-sin-valor-asignado' && (
            <label className='form-label col-6'>
              {intl.formatMessage({id: 'HEADER.WHITE_SAMPLE_PANEL'})}
            </label>
          )}
          {(valorMuestraAsignada === 'seriada-con-valor-asignado' ||
            valorMuestraAsignada === 'seriada-sin-valor-asignado') && (
            <label className='form-label col-6'>
              {intl.formatMessage({id: 'HEADER.LOG10_CONCENTRATION'})}
            </label>
          )}
        </div>
        <div className='row'>
          {(valorMuestraAsignada === 'no-seriada-con-valor-asignado' ||
            valorMuestraAsignada === 'seriada-con-valor-asignado') && (
            <>
              <span className='text-gray-600 col-3'>
                {toValueWithPrecisionString(reporte.Experimento.ValorAsignadoMuestraAlta)}
              </span>
              <span className='text-gray-600 col-3'>
                {toValueWithPrecisionString(reporte.Experimento.ValorAsignadoMuestraBaja)}
              </span>
            </>
          )}
          {valorMuestraAsignada === 'no-seriada-sin-valor-asignado' && (
            <span className='text-gray-600 col-6'>
              {reporte.Experimento.IsMuestraBajaBlanco
                ? intl.formatMessage({id: 'SETTINGS.YES'})
                : intl.formatMessage({id: 'SETTINGS.NO'})}
            </span>
          )}
          {(valorMuestraAsignada === 'seriada-con-valor-asignado' ||
            valorMuestraAsignada === 'seriada-sin-valor-asignado') && (
            <span className='text-gray-600 col-6'>
              {reporte.Experimento.IsAnalisisInLog
                ? intl.formatMessage({id: 'SETTINGS.YES'})
                : intl.formatMessage({id: 'SETTINGS.NO'})}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReporteEp6ExperimentoInfo
