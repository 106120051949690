import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='ep15a3'
        to='/ep15a3'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.EP15A3'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='ep15a3-precision'
        to='/ep15a3-precision'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.EP15A3_PRECISION'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='precision-simple'
        to='/simple-precision'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.SIMPLE_PRECISION'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='ep6'
        to='/ep6'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.EP6'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        id='loq'
        to='/loq'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.LOQ'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='vir'
        to='/vir'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.VIR'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='comp-metodos'
        to='/methods-comp'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.METHODS_COMP'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='comp-metodos-eqapt'
        to='/methods-comp-eqapt'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.METHODS_COMP_EQAPT'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='incertidumbre-medida'
        to='/u'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.U'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='ep12a2'
        to='/ep12a2'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'HEADER.EP12A2'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
