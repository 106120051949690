import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {ValueWithPrecisionDTO} from '../../../models/DTOs/common/ValueWithPrecisionDTO'
import {ReporteEp15a3PrecisionDTO} from '../../../models/DTOs/SEM/ep15a3-precision/ReporteEp15a3PrecisionDTO'

interface Props {
  reporte: ReporteEp15a3PrecisionDTO
}

const TablaDesempenioPrecisionReporte: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const informesDesempenio = reporte.Niveles.map((nivel) => nivel.Desempenio)
  const informesResumen = reporte.Niveles.map((nivel) => nivel.Resumen)

  const getDesempenioWithStatusCell = (value?: ValueWithPrecisionDTO, status?: boolean) => {
    return (
      <td className={`text-right text-${status ? 'success' : 'danger'}`}>
        <i
          className={`fa fa-${status ? 'check' : 'xmark'}  text-${status ? 'success' : 'danger'}`}
        ></i>{' '}
        {toValueWithPrecisionString(value)}
      </td>
    )
  }

  const getResumenNivelStatusCell = (value: string, status: boolean) => {
    return <td className={`text-center text-${status ? 'success' : 'danger'}`}>{value}</td>
  }

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center align-middle'>
            <th style={{width: '9%'}}>
              {intl.formatMessage({id: 'HEADER.MEDICAL_DECISION_LEVEL'})}
            </th>
            <th style={{width: '9%'}}>{intl.formatMessage({id: 'HEADER.CONCENTRATION'})}</th>
            <th style={{width: '9%'}}>{intl.formatMessage({id: 'MENU.UNITS'})}</th>
            <th style={{width: '9%'}}>% ETa</th>
            <th style={{width: '9%'}}>
              % CV<sub>WL</sub>
            </th>
            <th style={{width: '9%'}}>
              % CV<sub>WL</sub> (fabricante)
            </th>
            <th style={{width: '18%'}}>
              {intl.formatMessage({id: 'HEADER.STATISTICAL_INTERMEDIATE_PRECISION'})}
            </th>
            <th style={{width: '18%'}}>
              {intl.formatMessage({id: 'HEADER.CLINICAL_INTERMEDIATE_PRECISION'})}
            </th>

            <th style={{width: '9%'}}>% EAa</th>
          </tr>
        </thead>
        <tbody>
          {informesDesempenio.map((nivel, index) => {
            return (
              <tr key={`tabla_desempenio_precision_reporte_nivel_${index + 1}`}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.Concentracion)}</td>
                <td className='text-center'>{nivel.Unidades}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.ETaPercent)}</td>
                {getDesempenioWithStatusCell(nivel.CVwl, nivel.IsEAaOk)}
                <td className='text-right'>{toValueWithPrecisionString(nivel.CVwlFabricante)}</td>
                {getResumenNivelStatusCell(
                  informesResumen[index].PrecisionIntEstEvaluada
                    ? informesResumen[index].PrecisionIntEstOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.VERIFIED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.NOT_VERIFIED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  informesResumen[index].PrecisionIntEstOk
                )}
                {getResumenNivelStatusCell(
                  informesResumen[index].PrecisionIntCliEvaluada
                    ? informesResumen[index].PrecisionIntCliOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  informesResumen[index].PrecisionIntCliOk
                )}
                <td className='text-right'>{toValueWithPrecisionString(nivel.EAaPercent)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaDesempenioPrecisionReporte
