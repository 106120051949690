import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import {convertToDecimal} from '../../../../helpers'
import {AlertWarning} from '../../../UI/alerts/Alerts'

interface Props {
  reporte: ReporteCompMetodosDTO
}

const TablaAnalisisRegresionNDM: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const unidades = reporte.Experimento.MetodoCandidato.ProcedimientoMedida?.Mensurando.Unidades
  const regresion = reporte.RegressionAnalisis
  const precision = reporte.Precision

  const getRegressionModelName = (type: string) => {
    switch (type) {
      case 'olr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.1'})
      case 'wolr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.2'})
      case 'dcsdr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.3'})
      case 'dccvr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.4'})
      case 'pbr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.5'})
    }

    return type
  }

  return (
    <div className='table-responsive col-9' style={{margin: '0 auto'}}>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead
          className='fw-bolder text-center'
          style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle'}}
        >
          <tr>
            <th className='text-center' rowSpan={3}>
              #
            </th>
            <th className='text-center' rowSpan={3}>
              NDM
            </th>
            <th className='text-center' colSpan={3}>
              {intl.formatMessage({id: 'HEADER.CONCENTRATION'})} ({unidades})
            </th>
            <th className='text-center' colSpan={3}>
              {intl.formatMessage({id: 'HEADER.PERCENT'})}
            </th>
            <th className='text-center' rowSpan={3}>
              ESa %
            </th>
          </tr>
          <tr>
            <th className='text-center' rowSpan={2}>
              Sesgo
            </th>
            <th className='text-center' colSpan={2}>
              {intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL_95'})}
            </th>
            <th className='text-center' rowSpan={2}>
              Sesgo
            </th>
            <th className='text-center' colSpan={2}>
              {intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL_95'})}
            </th>
          </tr>
          <tr>
            <th className='text-center'>{intl.formatMessage({id: 'HEADER.LOWER'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'HEADER.UPPER'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'HEADER.LOWER'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'HEADER.UPPER'})}</th>
          </tr>
        </thead>
        <tbody>
          {regresion.NDMs?.length ? (
            regresion.NDMs.map((ndm, index) => {
              return (
                <tr key={`tabla_analisis_regresion_ndm_${index + 1}`}>
                  <td className='text-center'>{index}</td>
                  <td className='text-right'>{convertToDecimal(ndm.NDM!, precision)}</td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.Bias!.Value, precision) : '-'}
                  </td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.Bias!.Range.Min, precision) : '-'}
                  </td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.Bias!.Range.Max, precision) : '-'}
                  </td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.BiasPercent!.Value, 1) : '-'}
                  </td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.BiasPercent!.Range.Min, 1) : '-'}
                  </td>
                  <td className='text-right'>
                    {ndm.CanCalculate ? convertToDecimal(ndm.BiasPercent!.Range.Max, 1) : '-'}
                  </td>
                  <td className='text-right'>{convertToDecimal(ndm.ESaPercent, 1)}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td className='text-center' colSpan={10}>
                {intl.formatMessage({id: 'WARNING.NOT_NDM'})}
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={15}>
              <small>
                {intl
                  .formatMessage({id: 'HEADER.EVALUATED_WITH'})
                  .replace('{value}', getRegressionModelName(regresion.BiasModel.Type.Name))}
              </small>
            </td>
          </tr>
        </tfoot>
      </table>
      {regresion.NDMs?.length && (
        <>
          {!regresion.CanCalcBias ? (
            <div className='mb-10'>
              <AlertWarning>{intl.formatMessage({id: 'WARNING.NDM_1'})}</AlertWarning>
            </div>
          ) : (
            !regresion.HasMinDataToCalcNDMs && (
              <div className='mb-10'>
                <AlertWarning>{intl.formatMessage({id: 'WARNING.NDM_2'})}</AlertWarning>
              </div>
            )
          )}
          {regresion.NDMsOutsideLimits?.map((ndm) => (
            <div className='mb-10'>
              <AlertWarning>
                {intl
                  .formatMessage({id: 'WARNING.NDM_3'})
                  .replace('{value}', convertToDecimal(ndm.NDM!, precision))}
              </AlertWarning>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default TablaAnalisisRegresionNDM
