import {FC, useEffect, useState} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  experimento: ExperimentoEp12a2DTO
  reporte: ReporteEp12a2DTO
}

const GraficoAnexoReporteEp12a2: FC<Props> = ({experimento, reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  const DesempTable = {
    xValues: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
      50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
      73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
      96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
      115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133,
      134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152,
      153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171,
      172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190,
      191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209,
      210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
      229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
      248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266,
      267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285,
      286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304,
      305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323,
      324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342,
      343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361,
      362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380,
      381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 399,
      400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418,
      419, 420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437,
      438, 439, 440, 441, 442, 443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456,
      457, 458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472, 473, 474, 475,
      476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487, 488, 489, 490, 491, 492, 493, 494,
      495, 496, 497, 498, 499, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513,
      514, 515, 516, 517, 518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
      533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551,
      552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562, 563, 564, 565, 566, 567, 568, 569, 570,
      571, 572, 573, 574, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589,
      590, 591, 592, 593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607, 608,
      609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622, 623, 624, 625, 626, 627,
      628, 629, 630, 631, 632, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646,
      647, 648, 649, 650, 651, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665,
      666, 667, 668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682, 683, 684,
      685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697, 698, 699, 700, 701, 702, 703,
      704, 705, 706, 707, 708, 709, 710, 711, 712, 713, 714, 715, 716, 717, 718, 719, 720, 721, 722,
      723, 724, 725, 726, 727, 728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741,
      742, 743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    ],
    yValues: [
      20.65, 34.24, 43.85, 51.02, 56.56, 60.97, 64.57, 67.56, 70.09, 72.25, 74.12, 75.76, 77.19,
      78.47, 79.62, 80.64, 81.57, 82.42, 83.19, 83.89, 84.54, 85.14, 85.69, 86.21, 86.68, 87.13,
      87.55, 87.94, 88.31, 88.65, 88.98, 89.28, 89.58, 89.85, 90.11, 90.36, 90.6, 90.82, 91.04,
      91.24, 91.44, 91.62, 91.8, 91.97, 92.14, 92.29, 92.45, 92.59, 92.73, 92.87, 93.0, 93.12,
      93.24, 93.36, 93.47, 93.58, 93.69, 93.79, 93.89, 93.98, 94.08, 94.17, 94.25, 94.34, 94.42,
      94.5, 94.58, 94.65, 94.73, 94.8, 94.87, 94.94, 95.0, 95.07, 95.13, 95.19, 95.25, 95.31, 95.36,
      95.42, 95.47, 95.53, 95.58, 95.63, 95.68, 95.73, 95.77, 95.82, 95.86, 95.91, 95.95, 95.99,
      96.03, 96.07, 96.11, 96.15, 96.19, 96.23, 96.27, 96.3, 96.34, 96.37, 96.41, 96.44, 96.47,
      96.5, 96.54, 96.57, 96.6, 96.63, 96.66, 96.68, 96.71, 96.74, 96.77, 96.8, 96.82, 96.85, 96.87,
      96.9, 96.92, 96.95, 96.97, 97.0, 97.02, 97.04, 97.06, 97.09, 97.11, 97.13, 97.15, 97.17,
      97.19, 97.21, 97.23, 97.25, 97.27, 97.29, 97.31, 97.33, 97.35, 97.37, 97.38, 97.4, 97.42,
      97.44, 97.45, 97.47, 97.49, 97.5, 97.52, 97.54, 97.55, 97.57, 97.58, 97.6, 97.61, 97.63,
      97.64, 97.66, 97.67, 97.68, 97.7, 97.71, 97.73, 97.74, 97.75, 97.77, 97.78, 97.79, 97.8,
      97.82, 97.83, 97.84, 97.85, 97.86, 97.88, 97.89, 97.9, 97.91, 97.92, 97.93, 97.94, 97.96,
      97.97, 97.98, 97.99, 98.0, 98.01, 98.02, 98.03, 98.04, 98.05, 98.06, 98.07, 98.08, 98.09,
      98.1, 98.11, 98.12, 98.13, 98.13, 98.14, 98.15, 98.16, 98.17, 98.18, 98.19, 98.2, 98.2, 98.21,
      98.22, 98.23, 98.24, 98.25, 98.25, 98.26, 98.27, 98.28, 98.28, 98.29, 98.3, 98.31, 98.31,
      98.32, 98.33, 98.34, 98.34, 98.35, 98.36, 98.36, 98.37, 98.38, 98.39, 98.39, 98.4, 98.41,
      98.41, 98.42, 98.43, 98.43, 98.44, 98.44, 98.45, 98.46, 98.46, 98.47, 98.48, 98.48, 98.49,
      98.49, 98.5, 98.5, 98.51, 98.52, 98.52, 98.53, 98.53, 98.54, 98.54, 98.55, 98.56, 98.56,
      98.57, 98.57, 98.58, 98.58, 98.59, 98.59, 98.6, 98.6, 98.61, 98.61, 98.62, 98.62, 98.63,
      98.63, 98.64, 98.64, 98.65, 98.65, 98.66, 98.66, 98.67, 98.67, 98.67, 98.68, 98.68, 98.69,
      98.69, 98.7, 98.7, 98.71, 98.71, 98.71, 98.72, 98.72, 98.73, 98.73, 98.74, 98.74, 98.74,
      98.75, 98.75, 98.76, 98.76, 98.76, 98.77, 98.77, 98.78, 98.78, 98.78, 98.79, 98.79, 98.8,
      98.8, 98.8, 98.81, 98.81, 98.81, 98.82, 98.82, 98.82, 98.83, 98.83, 98.84, 98.84, 98.84,
      98.85, 98.85, 98.85, 98.86, 98.86, 98.86, 98.87, 98.87, 98.87, 98.88, 98.88, 98.88, 98.89,
      98.89, 98.89, 98.9, 98.9, 98.9, 98.91, 98.91, 98.91, 98.91, 98.92, 98.92, 98.92, 98.93, 98.93,
      98.93, 98.94, 98.94, 98.94, 98.94, 98.95, 98.95, 98.95, 98.96, 98.96, 98.96, 98.96, 98.97,
      98.97, 98.97, 98.98, 98.98, 98.98, 98.98, 98.99, 98.99, 98.99, 98.99, 99.0, 99.0, 99.0, 99.0,
      99.01, 99.01, 99.01, 99.01, 99.02, 99.02, 99.02, 99.02, 99.03, 99.03, 99.03, 99.03, 99.04,
      99.04, 99.04, 99.04, 99.05, 99.05, 99.05, 99.05, 99.06, 99.06, 99.06, 99.06, 99.07, 99.07,
      99.07, 99.07, 99.07, 99.08, 99.08, 99.08, 99.08, 99.09, 99.09, 99.09, 99.09, 99.09, 99.1,
      99.1, 99.1, 99.1, 99.1, 99.11, 99.11, 99.11, 99.11, 99.11, 99.12, 99.12, 99.12, 99.12, 99.12,
      99.13, 99.13, 99.13, 99.13, 99.13, 99.14, 99.14, 99.14, 99.14, 99.14, 99.15, 99.15, 99.15,
      99.15, 99.15, 99.16, 99.16, 99.16, 99.16, 99.16, 99.16, 99.17, 99.17, 99.17, 99.17, 99.17,
      99.18, 99.18, 99.18, 99.18, 99.18, 99.18, 99.19, 99.19, 99.19, 99.19, 99.19, 99.19, 99.2,
      99.2, 99.2, 99.2, 99.2, 99.2, 99.21, 99.21, 99.21, 99.21, 99.21, 99.21, 99.22, 99.22, 99.22,
      99.22, 99.22, 99.22, 99.23, 99.23, 99.23, 99.23, 99.23, 99.23, 99.23, 99.24, 99.24, 99.24,
      99.24, 99.24, 99.24, 99.25, 99.25, 99.25, 99.25, 99.25, 99.25, 99.25, 99.26, 99.26, 99.26,
      99.26, 99.26, 99.26, 99.26, 99.27, 99.27, 99.27, 99.27, 99.27, 99.27, 99.27, 99.28, 99.28,
      99.28, 99.28, 99.28, 99.28, 99.28, 99.28, 99.29, 99.29, 99.29, 99.29, 99.29, 99.29, 99.29,
      99.3, 99.3, 99.3, 99.3, 99.3, 99.3, 99.3, 99.3, 99.31, 99.31, 99.31, 99.31, 99.31, 99.31,
      99.31, 99.31, 99.32, 99.32, 99.32, 99.32, 99.32, 99.32, 99.32, 99.32, 99.32, 99.33, 99.33,
      99.33, 99.33, 99.33, 99.33, 99.33, 99.33, 99.34, 99.34, 99.34, 99.34, 99.34, 99.34, 99.34,
      99.34, 99.34, 99.35, 99.35, 99.35, 99.35, 99.35, 99.35, 99.35, 99.35, 99.35, 99.36, 99.36,
      99.36, 99.36, 99.36, 99.36, 99.36, 99.36, 99.36, 99.37, 99.37, 99.37, 99.37, 99.37, 99.37,
      99.37, 99.37, 99.37, 99.37, 99.38, 99.38, 99.38, 99.38, 99.38, 99.38, 99.38, 99.38, 99.38,
      99.38, 99.39, 99.39, 99.39, 99.39, 99.39, 99.39, 99.39, 99.39, 99.39, 99.39, 99.4, 99.4, 99.4,
      99.4, 99.4, 99.4, 99.4, 99.4, 99.4, 99.4, 99.4, 99.41, 99.41, 99.41, 99.41, 99.41, 99.41,
      99.41, 99.41, 99.41, 99.41, 99.41, 99.42, 99.42, 99.42, 99.42, 99.42, 99.42, 99.42, 99.42,
      99.42, 99.42, 99.42, 99.42, 99.43, 99.43, 99.43, 99.43, 99.43, 99.43, 99.43, 99.43, 99.43,
      99.43, 99.43, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44, 99.44,
      99.44, 99.44, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45, 99.45,
      99.45, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46, 99.46,
      99.46, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47, 99.47,
      99.47, 99.47, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48, 99.48,
      99.48, 99.48, 99.48, 99.49, 99.49, 99.49, 99.49, 99.49, 99.49, 99.49, 99.49, 99.49, 99.49,
      99.49, 99.49, 99.49, 99.49, 99.49, 99.5,
    ],
    maxN: 757,
  }

  function GetLineSerie(
    data: any,
    color: string,
    style?: Highcharts.DashStyleValue
  ): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: style || 'ShortDot',
      type: 'line',
    }
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  useEffect(() => {
    const series = []
    const xValues = DesempTable.xValues
    const yValues = DesempTable.yValues
    const minValueAxisX = 0
    const maxValueAxisX = DesempTable.maxN
    const minValueAxisY = 0
    const maxValueAxisY = 100

    series.push(
      GetLineSerie(
        xValues.map((v, index) => [v, yValues[index]]),
        '#5C9BD1',
        'ShortDot'
      )
    )

    setOptions({
      title: {
        text: intl.formatMessage({id: 'HEADER.BINARY_RESPONSE_TEST_PERFORMANCE'}),
      },
      xAxis: {
        title: {
          text: intl.formatMessage({id: 'HEADER.SAMPLES_AMOUNT'}),
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 2)
          },
        },
        min: minValueAxisX,
        max: maxValueAxisX,
        endOnTick: true,
      },
      yAxis: {
        title: {
          text: intl.formatMessage({id: 'HEADER.LOWER_LIMIT_IC_95'}),
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 2)
          },
        },
        min: minValueAxisY,
        max: maxValueAxisY,
        tickInterval: 10,
        endOnTick: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '[' + this.x + ' ; ' + this.y + ']'
        },
      },
      legend: {
        enabled: false,
      },
      chart: {
        height: 500,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoAnexoReporteEp12a2
