import {FC} from 'react'
import {ReporteEp15a3DTO} from '../../../models/DTOs/SEM/ep15a3/ReporteEp15a3DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {AlertDanger, AlertSuccess, AlertWarning} from '../../UI/alerts/Alerts'
import {ReporteEp15a3PrecisionDTO} from '../../../models/DTOs/SEM/ep15a3-precision/ReporteEp15a3PrecisionDTO'

interface Props {
  reporte: ReporteEp15a3DTO | ReporteEp15a3PrecisionDTO
  indexNivel: number
}

const VerificacionPrecisionWithoutOutliers: FC<Props> = ({reporte, indexNivel}) => {
  const intl = useIntl()

  const precision = reporte.Niveles[indexNivel].Precision.InformeWithoutOutliers!

  const conclusionUvlText = (conclusion: string) => {
    return conclusion === 'verif-aceptada'
      ? intl.formatMessage({id: 'CONCLUSION_UVL.ACCEPTED'})
      : intl.formatMessage({id: 'CONCLUSION_UVL.REJECTED'})
  }

  const conclusionFabricanteText = (conclusion: string) => {
    return conclusion === 'aceptada'
      ? intl.formatMessage({id: 'CONCLUSION_MAKER.ACCEPTED'})
      : conclusion === 'rechazada'
      ? intl.formatMessage({id: 'CONCLUSION_MAKER.REJECTED'})
      : intl.formatMessage({id: 'CONCLUSION_MAKER.OTHER'})
  }

  return (
    <div className='row mb-10'>
      <div className='col-6'>
        <div className='card border-secondary' style={{border: '1px solid'}}>
          <div className='card-header border-secondary' style={{borderBottom: '1px solid'}}>
            <div className='card-title fw-bolder'>
              {intl.formatMessage({id: 'HEADER.REPEATABILITY_VERIFICATION'})}
            </div>
          </div>
          <div className='card-body'>
            <div className='mb-10'>
              <div className='row'>
                <span className='col-2 form-label fs-7'>
                  S<sub>R</sub>
                </span>
                <span className='col-2 form-label fs-7'>
                  % CV<sub>R</sub>
                </span>
                <span className='col-4 form-label fs-7'>
                  σ<sub>R</sub> (fabricante)
                </span>
                <span className='col-4 form-label fs-7'>
                  UVL σ<sub>R</sub> (fabricante)
                </span>
              </div>
              <div className='row'>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(precision.Repetibilidad.Sr)}
                </span>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(precision.Repetibilidad.SigmaRFab)}
                </span>
                <span className='col-4 text-gray-600'>
                  {toValueWithPrecisionString(
                    reporte.Experimento.Niveles[indexNivel].Precision.SigmaRFab
                  )}
                </span>
                <span className='col-4 text-gray-600'>
                  {toValueWithPrecisionString(precision.Repetibilidad.UvlSigmaRFab)}
                </span>
              </div>
            </div>
            <div className='row'>
              {precision.Repetibilidad.ConclusionUvl.Name === 'utilizar-uvl' ? (
                <AlertWarning>
                  {conclusionUvlText(precision.Repetibilidad.ConclusionUvl.Name)}
                </AlertWarning>
              ) : (
                <AlertSuccess>
                  {conclusionUvlText(precision.Repetibilidad.ConclusionUvl.Name)}
                </AlertSuccess>
              )}
            </div>
            {precision.Repetibilidad.ConclusionUvl.Name === 'utilizar-uvl' ? (
              <div className='row'>
                {precision.Repetibilidad.Conclusion.Name === 'aceptada' ? (
                  <AlertSuccess>
                    {conclusionFabricanteText(precision.Repetibilidad.Conclusion.Name)}
                  </AlertSuccess>
                ) : (
                  <AlertDanger>
                    {conclusionFabricanteText(precision.Repetibilidad.Conclusion.Name)}
                  </AlertDanger>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card border-secondary' style={{border: '1px solid'}}>
          <div className='card-header border-secondary' style={{borderBottom: '1px solid'}}>
            <div className='card-title fw-bolder'>
              {intl.formatMessage({id: 'HEADER.INTERMEDIATE_PRECISION_VERIFICATION'})}
            </div>
          </div>
          <div className='card-body'>
            <div className='mb-10'>
              <div className='row'>
                <span className='col-2 form-label fs-7'>
                  S<sub>WL</sub>
                </span>
                <span className='col-2 form-label fs-7'>
                  % CV<sub>WL</sub>
                </span>
                <span className='col-4 form-label fs-7'>
                  σ<sub>WL</sub> (fabricante)
                </span>
                <span className='col-4 form-label fs-7'>
                  UVL σ<sub>WL</sub> (fabricante)
                </span>
              </div>
              <div className='row'>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(precision.PrecisionIntermedia.Swl)}
                </span>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(precision.PrecisionIntermedia.SigmaWLFab)}
                </span>
                <span className='col-4 text-gray-600'>
                  {toValueWithPrecisionString(
                    reporte.Experimento.Niveles[indexNivel].Precision.SigmaWLFab
                  )}
                </span>
                <span className='col-4 text-gray-600'>
                  {toValueWithPrecisionString(precision.PrecisionIntermedia.UvlSigmaWLFab)}
                </span>
              </div>
            </div>
            <div className='row'>
              {precision.PrecisionIntermedia.ConclusionUvl.Name === 'utilizar-uvl' ? (
                <AlertWarning>
                  {conclusionUvlText(precision.PrecisionIntermedia.ConclusionUvl.Name)}
                </AlertWarning>
              ) : (
                <AlertSuccess>
                  {conclusionUvlText(precision.PrecisionIntermedia.ConclusionUvl.Name)}
                </AlertSuccess>
              )}
            </div>
            {precision.PrecisionIntermedia.ConclusionUvl.Name === 'utilizar-uvl' && (
              <div className='row'>
                {precision.PrecisionIntermedia.Conclusion.Name === 'aceptada' ? (
                  <AlertSuccess>
                    {conclusionFabricanteText(precision.PrecisionIntermedia.Conclusion.Name)}
                  </AlertSuccess>
                ) : (
                  <AlertDanger>
                    {conclusionFabricanteText(precision.PrecisionIntermedia.Conclusion.Name)}
                  </AlertDanger>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerificacionPrecisionWithoutOutliers
