import React, {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const Ep15a3 = lazy(() => import('../pages/ep15a3/Ep15a3'))
  const Ep15a3Precision = lazy(() => import('../pages/ep15a3-precision/Ep15a3Precision'))
  const PrecisionSimple = lazy(() => import('../pages/precision-simple/PrecisionSimple'))
  const Ep6 = lazy(() => import('../pages/ep6/Ep6'))
  const LoQ = lazy(() => import('../pages/loq/LoQ'))
  const VIR = lazy(() => import('../pages/vir/VIR'))
  const ComparacionMetodos = lazy(() => import('../pages/comp-metodos/ComparacionMetodos'))
  const CompMetodosEqapt = lazy(() => import('../pages/comp-metodos-eqapt/CompMetodosEqapt'))
  const IncertidumbreMedida = lazy(() => import('../pages/u/IncertidumbreMedida'))
  const Ep12a2 = lazy(() => import('../pages/ep12a2/Ep12a2'))

  const isAdmin = user.Rol.Name === RolUsuario.AdministradorLaboratorio

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route path='/ep15a3' exact component={Ep15a3} />
        <Route path='/ep15a3-precision' exact component={Ep15a3Precision} />
        <Route path='/simple-precision' exact component={PrecisionSimple} />
        <Route path='/ep6' exact component={Ep6} />
        <Route path='/loq' exact component={LoQ} />
        <Route path='/vir' exact component={VIR} />
        <Route path='/methods-comp' exact component={ComparacionMetodos} />
        <Route path='/methods-comp-eqapt' exact component={CompMetodosEqapt} />
        <Route path='/u' exact component={IncertidumbreMedida} />
        <Route path='/ep12a2' exact component={Ep12a2} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
