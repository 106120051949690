import {FC} from 'react'
import {ReporteEp12a2DTO} from '../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import {ExperimentoEp12a2DTO} from '../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import AnalisisReporteEp12a2 from '../../components/ep12a2/reporte/AnalisisReporteEp12a2'
import AnexoReporteEp12a2 from '../../components/ep12a2/reporte/AnexoReporteEp12a2'
import ReporteExperimentoInfoEp12a2 from '../../components/ep12a2/reporte/ReporteExperimentoInfoEp12a2'
import TablaDatosReporteEp12a2 from '../../components/ep12a2/reporte/TablaDatosReporteEp12a2'

const ReporteEp12a2: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteEp12a2DTO = dataDecoded.Reporte
  const experimentoFull: ExperimentoEp12a2DTO = dataDecoded.Experimento

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReporteExperimentoInfoEp12a2 experimento={experimentoFull} />
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaDatosReporteEp12a2 experimento={experimentoFull} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <AnalisisReporteEp12a2 experimento={experimentoFull} reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <AnexoReporteEp12a2 experimento={experimentoFull} reporte={reporte} />
        </div>
      </div>
    </>
  )
}

export default ReporteEp12a2
