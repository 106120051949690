import {FC} from 'react'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteEp6DTO
}

const TablaReplicadosReporteEp6: FC<Props> = ({reporte}) => {
  const intl = useIntl()
  const valoresAsignados = reporte.Experimento.ValoresAsignadosMuestras
  const cantColumnas = reporte.Experimento.CantReplicados
  const cantFilas = reporte.Experimento.CantMuestras

  let arrayColumnas: number[] = []
  for (let i = 1; i <= cantColumnas; i++) {
    arrayColumnas.push(i)
  }

  let arrayFilas: number[] = []
  for (let i = 1; i <= cantFilas; i++) {
    arrayFilas.push(i)
  }

  const tableWidth =
    80 +
    110 * cantColumnas +
    (valoresAsignados === 'no-seriada-con-valor-asignado' ||
    valoresAsignados === 'no-seriada-sin-valor-asignado' ||
    valoresAsignados === 'con-valor-asignado'
      ? 110
      : 0)
  return (
    <div className='table-responsive mb-10'>
      <table
        style={{width: `${tableWidth}px`}}
        className='table table-bordered table-row-bordered align-middle gs-3 gy-3'
      >
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center'>
            <th style={{width: '80px'}} className='align-middle' rowSpan={2}>
              {intl.formatMessage({id: 'HEADER.SAMPLE'})}
            </th>
            {(valoresAsignados === 'no-seriada-con-valor-asignado' ||
              valoresAsignados === 'no-seriada-sin-valor-asignado' ||
              valoresAsignados === 'con-valor-asignado') && (
              <th style={{width: '110px'}} className='align-middle' rowSpan={2}>
                {valoresAsignados === 'con-valor-asignado'
                  ? intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})
                  : intl.formatMessage({id: 'HEADER.HIGH_SAMPLE_RATIO'})}
              </th>
            )}
            <th colSpan={cantColumnas} className='text-center'>
              {intl.formatMessage({id: 'HEADER.REPLICATES'})}
            </th>
          </tr>
          <tr>
            {arrayColumnas.map((columna) => {
              return (
                <th
                  key={`ep6_tabla_replicados_replicado_${columna}`}
                  className='text-center'
                  style={{width: '110px'}}
                >
                  {columna}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {arrayFilas.map((fila, filaIndex) => {
            return (
              <tr key={`muestra_${fila}`}>
                <td className='text-center'>{fila}</td>
                {(valoresAsignados === 'con-valor-asignado' ||
                  valoresAsignados === 'no-seriada-con-valor-asignado' ||
                  valoresAsignados === 'no-seriada-sin-valor-asignado') && (
                  <td className='text-right'>
                    {toValueWithPrecisionString(reporte.Experimento.Muestras![filaIndex].Valor)}
                  </td>
                )}
                {arrayColumnas.map((columna, columnaIndex) => {
                  return (
                    <td key={`muestra_${fila}_replica_${columna}`} className='text-right'>
                      {toValueWithPrecisionString(
                        reporte.Experimento.Datos![filaIndex].Replicas[columnaIndex].Valor
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaReplicadosReporteEp6
