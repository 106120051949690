import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteCompMetodosDTO
}

const ReporteCompMetodosExperimentoInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{reporte.Experimento.Fecha}</span>
          <span className='text-gray-600 col-3'>{reporte.Experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>{intl.formatMessage({id: 'HEADER.SOURCE'})}</label>
          <label className='form-label col-1'>ETa %</label>
          <label className='form-label col-1'>ETa cc</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.ESA_EAA_MODEL'})}
          </label>
          <label className='form-label col-1'>ESa</label>
          <label className='form-label col-1'>EAa</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>
            {reporte.Experimento.EspDesempAnalitico.FuenteETa}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(reporte.Experimento.EspDesempAnalitico.ETa?.Percent) || '-'}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(
              reporte.Experimento.EspDesempAnalitico.ETa?.Concentration
            ) || '-'}
          </span>
          <span className='text-gray-600 col-2'>
            {reporte.Experimento.EspDesempAnalitico.ModeloESaEAa === 'presupuesto-eta'
              ? intl.formatMessage({id: 'HEADER.ETA_BUDGET'})
              : intl.formatMessage({id: 'HEADER.PERFORMANCE_SPECIFICATIONS'})}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(reporte.Experimento.EspDesempAnalitico.ESa)}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(reporte.Experimento.EspDesempAnalitico.EAa)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ReporteCompMetodosExperimentoInfo
