import {FC} from 'react'
import {ReporteUDTO} from '../../models/DTOs/SEM/u/ReporteUDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import ReporteInfoExperimentoU from '../../components/u/reporte/ReporteInfoExperimentoU'
import ReporteNDMU from '../../components/u/reporte/ReporteNDMU'
import ReporteResumenU from '../../components/u/reporte/ReporteResumenU'
import ReporteValoresAsignadosU from '../../components/u/reporte/ReporteValoresAsignadosU'
import {ExperimentoUDTO} from '../../models/DTOs/SEM/u/ExperimentoUDTO'

const ReporteU: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteUDTO = dataDecoded.Reporte
  const experimentoFull: ExperimentoUDTO = dataDecoded.Experimento

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div style={{pageBreakInside: 'avoid'}}>
          <ReporteInfoExperimentoU experimento={experimentoFull} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <ReporteNDMU experimento={experimentoFull} reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <ReporteValoresAsignadosU experimento={experimentoFull} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <h1 className='my-8'>{intl.formatMessage({id: 'SETTINGS.SUMMARY'})}</h1>
          <ReporteResumenU reporte={reporte} experimento={experimentoFull} />
        </div>
      </div>
    </>
  )
}

export default ReporteU
