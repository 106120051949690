import {FC} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {convertToDecimal, toAbsoluteUrl} from '../../../../helpers'
import {ReporteCompMetodosEqaptDTO} from '../../../../models/DTOs/SEM/comp-metodos-eqapt/ReporteCompMetodosEqaptDTO'
more(Highcharts)

interface Props {
  reporte: ReporteCompMetodosEqaptDTO
}

const GraficosNDMAnalisisRegresionEqapt: FC<Props> = ({reporte}) => {
  const regresion = reporte.RegressionAnalisis
  const precision = reporte.Precision

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  function GetLineSerie(
    data: any,
    color: string,
    style?: Highcharts.DashStyleValue
  ): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: style || 'ShortDot',
      type: 'line',
    }
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  return (
    <div style={{flexWrap: 'wrap'}} className='row'>
      {regresion.NDMs?.length &&
        regresion.NDMs.filter((ndm) => ndm.CanCalculate).map((ndm, index) => {
          const series = []
          const info = {
            Number: index + 1,
            Bias: ndm.BiasPercent?.Value,
            ESaTop: ndm.ESaPercent,
            ESaBottom: -ndm.ESaPercent,
            BiasICBottom: ndm.BiasPercent?.Range.Min,
            BiasICTop: ndm.BiasPercent?.Range.Max,
          }

          const maxValueY =
            Math.max.apply(null, [
              Math.abs(info.ESaTop),
              Math.abs(info.ESaBottom),
              Math.abs(info.BiasICBottom ?? 0),
              Math.abs(info.BiasICTop ?? 0),
            ]) * 1.1

          const minValueX = 0
          const maxValueX = 4
          const middlePointX = maxValueX / 2

          // Zero.
          series.push(
            GetLineSerie(
              [
                [0, 0],
                [maxValueX, 0],
              ],
              '#26C281',
              'Solid'
            )
          )

          // ESa %.
          series.push(
            GetLineSerie(
              [
                [0, info.ESaTop],
                [maxValueX, info.ESaTop],
              ],
              '#5C9BD1',
              'Solid'
            )
          )
          series.push(
            GetLineSerie(
              [
                [0, info.ESaBottom],
                [maxValueX, info.ESaBottom],
              ],
              '#5C9BD1',
              'Solid'
            )
          )

          // Bias IC 95 %.
          series.push(
            GetLineSerie(
              [
                [middlePointX, info.BiasICBottom],
                [middlePointX, info.BiasICTop],
              ],
              '#E43A45',
              'Solid'
            )
          )

          // Bias %.
          series.push(GetScatterSerie([[middlePointX, info.Bias]], '#5C9BD1', '#3598DC'))

          const options: Highcharts.Options = {
            title: {
              text: `NDM ${info.Number}`,
              align: 'left',
            },
            xAxis: {
              title: {
                text: `NDM ${info.Number}`,
              },
              labels: {
                enabled: false,
              },
              min: minValueX,
              max: maxValueX,
              tickInterval: 1,
            },
            yAxis: {
              title: {
                text: 'Sesgo %',
              },
              labels: {
                formatter: function () {
                  return GetNumberFormatter(+this.value, 1)
                },
              },
              lineWidth: 1,
              min: -maxValueY,
              max: maxValueY,
            },
            plotOptions: {
              series: {
                animation: false,
              },
            },
            tooltip: {
              headerFormat: '',
              pointFormatter: function () {
                return (
                  '[' +
                  convertToDecimal(this.x, precision) +
                  ' ; ' +
                  convertToDecimal(this.y!, precision) +
                  ']'
                )
              },
            },
            legend: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },
            series: series,
          }

          return (
            <div
              className='mb-10'
              style={{margin: '0 auto', width: '33%'}}
              key={`grafico_ndm_analisis_regresion_eqapt_${index + 1}`}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />
              <div className='row margin-bottom-30 text-center'>
                <div className='text-center' style={{margin: '0 auto'}}>
                  <span className='mx-3'>
                    <img src={toAbsoluteUrl('/media/otros/comp-metodos/Green.png')} /> 0
                  </span>
                  <span className='mx-3'>
                    <img src={toAbsoluteUrl('/media/otros/comp-metodos/LightBlue.png')} /> ESa %
                  </span>
                  <span className='mx-3'>
                    <img src={toAbsoluteUrl('/media/otros/comp-metodos/ESa.png')} /> IC 95%
                  </span>
                  <span className='mx-3'>
                    <img src={toAbsoluteUrl('/media/otros/comp-metodos/Bullet_blue.png')} /> Sesgo
                  </span>
                </div>
                <div className='col-md-5'>&nbsp;</div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default GraficosNDMAnalisisRegresionEqapt
