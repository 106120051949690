import {FC} from 'react'
import {ReportePrecisionSimpleDTO} from '../../models/DTOs/SEM/precision-simple/ReportePrecisionSimpleDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import GraficoPrecisionSimpleNivel from '../../components/precision-simple/reporte/GraficoPrecisionSimpleNivel'
import ReportePrecisionSimpleExperimentoInfo from '../../components/precision-simple/reporte/ReportePrecisionSimpleExperimentoInfo'
import TablaEstadoPrecisionSimple from '../../components/precision-simple/reporte/TablaEstadoPrecisionSimple'
import TablaValoresReportePrecisionSimple from '../../components/precision-simple/reporte/TablaValoresReportePrecisionSimple'

const ReportePrecisionSimple: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReportePrecisionSimpleDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReportePrecisionSimpleExperimentoInfo reporte={reporte} />
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaEstadoPrecisionSimple reporte={reporte} />
        </div>
        <div className='row mb-10'>
          {reporte.Niveles.map((nivel) => {
            return (
              <div
                className='col-4'
                key={`grafico_precision_simple_nivel_${nivel.Numero}`}
                style={{pageBreakInside: 'avoid'}}
              >
                <GraficoPrecisionSimpleNivel nivel={nivel} reporte={reporte} />
              </div>
            )
          })}
        </div>
        <div className='row mb-10'>
          {reporte.Experimento.Niveles.map((nivel) => {
            return (
              <div
                className='col-4'
                key={`tabla_valores_precision_simple_nivel_${nivel.Numero}`}
                style={{pageBreakInside: 'avoid'}}
              >
                <TablaValoresReportePrecisionSimple nivel={nivel} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ReportePrecisionSimple
