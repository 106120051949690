import {FC} from 'react'
import {ReporteVIRDTO} from '../../../models/DTOs/SEM/vir/ReporteVIRDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteVIRDTO
}

const TablaAnalisisValoresReporteVIR: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center'>
            <th style={{width: '50%'}}>{intl.formatMessage({id: 'HEADER.INTERVAL'})}</th>
            <th style={{width: '25%'}}>{intl.formatMessage({id: 'HEADER.PERCENT'})}</th>
            <th style={{width: '25%'}}>{intl.formatMessage({id: 'HEADER.AMOUNT'})}</th>
          </tr>
        </thead>
        <tbody>
          {reporte.Analisis.Intervalos.map((intervalo) => {
            return (
              <tr key={`tabla_analisis_valores_reporte_vir_intervalo_${intervalo.Description}`}>
                <td className='text-center'>{intervalo.Description}</td>
                <td className='text-right'>{toValueWithPrecisionString(intervalo.Percent)}</td>
                <td className='text-right'>{intervalo.Count}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaAnalisisValoresReporteVIR
