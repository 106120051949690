import {FC} from 'react'

type Props = {
  text: string
  color: string
}

const BulletComponent: FC<Props> = ({text, color}) => {
  return (
    <>
      <li className='d-flex align-items-center py-2'>
        <span className='bullet h-15px w-25px me-5' style={{background: color, opacity: 0.5}} />
        {text}
      </li>
    </>
  )
}

export default BulletComponent
