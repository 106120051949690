export interface DoubleExpressionValueDTO<T> {
  Percent: T
  Concentration: T
}

export function getByKey<T>(item: DoubleExpressionValueDTO<T>, key: string) {
  const keyLower = key.toLowerCase()
  if (keyLower !== 'concentration' && keyLower !== 'percent') {
    console.warn(`Invalid argument (${key})`)
  }
  return keyLower === 'concentration' ? item.Concentration : item.Percent
}
