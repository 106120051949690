import {FC} from 'react'
import {ReporteUDTO} from '../../../models/DTOs/SEM/u/ReporteUDTO'
import {useIntl} from 'react-intl'
import {convertToDecimal, toValueWithPrecisionString} from '../../../helpers'
import {ExperimentoUDTO} from '../../../models/DTOs/SEM/u/ExperimentoUDTO'
import {AlertDanger, AlertSuccess} from '../../UI/alerts/Alerts'
import GraficoResumenU from './GraficoResumenU'

interface Props {
  reporte: ReporteUDTO
  experimento: ExperimentoUDTO
}

const ReporteResumenU: FC<Props> = ({reporte, experimento}) => {
  const intl = useIntl()

  return (
    <>
      {reporte.ResumenNiveles.map((resumen, indexResumen) => {
        const conclusionConcentrationAcepted =
          resumen.ConclusionMU.Concentration.Name === 'aceptado'
        const conclusionPercentAcepted = resumen.ConclusionMU.Percent.Name === 'aceptado'

        return (
          <div
            key={`resumen_nivel_${resumen.Numero}_ndm`}
            className='card mb-5'
            style={{border: '1px ridge'}}
          >
            <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
              <div className='card-title align-items-start col-7'>{`${intl.formatMessage({
                id: 'HEADER.LEVEL',
              })} ${resumen.Numero} (${intl.formatMessage({
                id: 'HEADER.NDM',
              })})`}</div>
            </div>
            <div className='card-body py-8'>
              <div className='mb-8'>
                <div className='row'>
                  <label className='col-2 form-label'>
                    {intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})}
                  </label>
                  <label className='col-3 form-label'>
                    {intl.formatMessage({id: 'HEADER.COVERAGE_FACTOR'})} (k)
                  </label>
                </div>
                <div className='row'>
                  <span className='col-2 text-gray-600'>
                    {toValueWithPrecisionString(resumen.ValorAsignado)} (
                    {experimento.ProcedimientoMedida.Mensurando.Unidades})
                  </span>
                  <span className='col-3 text-gray-600'>
                    {toValueWithPrecisionString(resumen.KFactor)}
                  </span>
                </div>
              </div>
              <div className='row mb-8'>
                <div className='col-3'>
                  <div className='table-responsive mb-8'>
                    <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                      <thead
                        style={{
                          backgroundColor: '#f5f8ff',
                          verticalAlign: 'middle',
                          textAlign: 'center',
                        }}
                      >
                        <tr className='fw-bolder text-center'>
                          <th colSpan={2}>{intl.formatMessage({id: 'HEADER.CONCENTRATION'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='text-center'>
                            u<sub>RW</sub>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(
                              resumen.uRW.Concentration,
                              resumen.ValorAsignado.Precision + 2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-center'>
                            u<sub>sesgo</sub>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(
                              resumen.uSesgo.Concentration,
                              resumen.ValorAsignado.Precision + 2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-center'>
                            u<sub>c</sub>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(
                              resumen.UComb.Concentration,
                              resumen.ValorAsignado.Precision + 2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-center'>U</td>
                          <td className='text-right'>
                            {convertToDecimal(
                              resumen.UExp.Concentration,
                              resumen.ValorAsignado.Precision + 2
                            )}
                          </td>
                        </tr>
                        <tr style={{background: '#e2e4e9'}}>
                          <td className='text-center'>
                            U<sup>obj</sup>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(
                              resumen.MUObj.Concentration,
                              resumen.ValorAsignado.Precision + 2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {conclusionConcentrationAcepted ? (
                      <AlertSuccess>
                        {intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})}
                      </AlertSuccess>
                    ) : (
                      <AlertDanger>
                        {intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})}
                      </AlertDanger>
                    )}
                  </div>
                </div>
                <div className='col-3'>
                  <div className='table-responsive mb-8'>
                    <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                      <thead
                        style={{
                          backgroundColor: '#f5f8ff',
                          verticalAlign: 'middle',
                          textAlign: 'center',
                        }}
                      >
                        <tr className='fw-bolder text-center'>
                          <th colSpan={2}>{intl.formatMessage({id: 'HEADER.PERCENT'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='text-center'>
                            u<sub>RW</sub>
                          </td>
                          <td className='text-right'>{convertToDecimal(resumen.uRW.Percent, 1)}</td>
                        </tr>
                        <tr>
                          <td className='text-center'>
                            u<sub>sesgo</sub>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(resumen.uSesgo.Percent, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-center'>
                            u<sub>c</sub>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(resumen.UComb.Percent, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-center'>U</td>
                          <td className='text-right'>
                            {convertToDecimal(resumen.UExp.Percent, 1)}
                          </td>
                        </tr>
                        <tr style={{background: '#e2e4e9'}}>
                          <td className='text-center'>
                            U<sup>obj</sup>
                          </td>
                          <td className='text-right'>
                            {convertToDecimal(resumen.MUObj.Percent, 1)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {conclusionPercentAcepted ? (
                      <AlertSuccess>
                        {intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})}
                      </AlertSuccess>
                    ) : (
                      <AlertDanger>
                        {intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})}
                      </AlertDanger>
                    )}
                  </div>
                </div>
                <div className='col-3'>
                  <GraficoResumenU
                    indexNivel={indexResumen}
                    reporte={reporte}
                    tipo='Concentration'
                    unidades={experimento.ProcedimientoMedida.Mensurando.Unidades}
                  />
                </div>
                <div className='col-3'>
                  <GraficoResumenU
                    indexNivel={indexResumen}
                    reporte={reporte}
                    tipo='Percent'
                    unidades='%'
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ReporteResumenU
