import {FC, useEffect, useState} from 'react'
import {ReporteCompMetodosDTO} from '../../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteCompMetodosDTO
}

const GraficoDispersionCompMetodos: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  function GetLineSerie(data: any, color: string): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: 'ShortDot',
      type: 'line',
    }
  }

  const getTickInterval = (minValue: number, maxValue: number) => {
    return (maxValue - minValue) / 5
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  const ConvertToDecimal = (value: number, decimalPlaces: number) => {
    if (!value) return ''
    let result = value.toString().replace(',', '.')

    if (decimalPlaces !== undefined) result = parseFloat(result).toFixed(decimalPlaces)

    return result
  }

  useEffect(() => {
    const series = []
    const xValues = reporte.Experimento.Datos.map((item) => item.MetodoComparador.Value)
    const yValues = reporte.Experimento.Datos.map((item) => item.MetodoCandidato.Value)
    const minValueX = 0
    const maxValueX = Math.max(...xValues)
    const minValueY = 0
    const maxValueY = Math.max(...yValues)
    const xAxisDecimalPlaces = reporte.Precision
    const yAxisDecimalPlaces = reporte.Precision
    const ticksX = getTickInterval(minValueX, maxValueX)
    const ticksY = getTickInterval(minValueY, maxValueY)
    const maxTicksInterval = Math.max(ticksX, ticksY).toFixed(1)

    // Identity.
    series.push(
      GetLineSerie(
        [
          [minValueX, minValueX],
          [maxValueX, maxValueX],
        ],
        '#5C9BD1'
      )
    )

    // X-Y.
    series.push(
      GetScatterSerie(
        xValues.map(function (v, index) {
          return [v, yValues[index]]
        })
      )
    )
    setOptions({
      title: {
        text: 'Gráfico de dispersión',
      },
      xAxis: {
        title: {
          text: `Método comparador (${reporte.Experimento.MetodoComparador.ProcedimientoMedida?.Mensurando.Unidades})`,
        },
        labels: {
          rotation: 90,
          formatter: function () {
            return GetNumberFormatter(+this.value, xAxisDecimalPlaces)
          },
        },
        //max: maxAxisValue,
        tickInterval: +maxTicksInterval,
        endOnTick: true,
      },
      yAxis: {
        title: {
          text: `Método candidato (${reporte.Experimento.MetodoCandidato.ProcedimientoMedida?.Mensurando.Unidades})`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, yAxisDecimalPlaces)
          },
        },
        lineWidth: 1,
        min: minValueY,
        //max: maxAxisValue,
        tickInterval: +maxTicksInterval,
        endOnTick: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return (
            '[' +
            ConvertToDecimal(+this.x, reporte.Precision) +
            ' ; ' +
            ConvertToDecimal(+this.y!, reporte.Precision) +
            ']'
          )
        },
      },
      legend: {
        enabled: false,
      },
      chart: {
        height: 500,
        width: 500,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoDispersionCompMetodos
