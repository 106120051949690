import {FC} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import GraficoAnexoReporteEp12a2 from './GraficoAnexoReporteEp12a2'
import {useIntl} from 'react-intl'

interface Props {
  experimento: ExperimentoEp12a2DTO
  reporte: ReporteEp12a2DTO
}

const AnexoReporteEp12a2: FC<Props> = ({experimento, reporte}) => {
  const intl = useIntl()

  return (
    <>
      {experimento.Metas &&
        (reporte.Metas?.SensibilidadDiagnostica?.ConclusionAlarma ||
          reporte.AnalisisWithCriterioExactitudDiagnostica?.Metas?.SensibilidadDiagnostica
            ?.ConclusionAlarma) && (
          <div>
            <h1>{intl.formatMessage({id: 'HEADER.ANNEX'})}</h1>
            <GraficoAnexoReporteEp12a2 experimento={experimento} reporte={reporte} />
          </div>
        )}
    </>
  )
}

export default AnexoReporteEp12a2
