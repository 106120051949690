import {FC} from 'react'
import {ReportePrecisionSimpleDTO} from '../../../models/DTOs/SEM/precision-simple/ReportePrecisionSimpleDTO'
import {toValueWithPrecisionString} from '../../../helpers'
import {useIntl} from 'react-intl'

interface Props {
  reporte: ReportePrecisionSimpleDTO
}

const ReportePrecisionSimpleExperimentoInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const experimento = reporte.Experimento

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-5'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{experimento.Fecha}</span>
          <span className='text-gray-600 col-5'>{`${experimento.ProcedimientoMedida.Equipo.Descripcion} / ${experimento.ProcedimientoMedida.Equipo.Plataforma} | ${experimento.ProcedimientoMedida.Mensurando.Analito} / ${experimento.ProcedimientoMedida.Mensurando.Matriz} / ${experimento.ProcedimientoMedida.Mensurando.Unidades} | ${experimento.ProcedimientoMedida.Metodo.Reactivo} / ${experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.ESA_EAA_MODEL'})}
          </label>
          <label className='form-label col-1'>ESa</label>
          <label className='form-label col-1'>EAa</label>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.PRECISION_SPECIFICATIONS'})}
          </label>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.PRECISION_CONDITIONS'})}
          </label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>
            {experimento.EspDesempAnalitico.ModeloESaEAa.Id === 1
              ? intl.formatMessage({id: 'HEADER.ETA_BUDGET'})
              : intl.formatMessage({id: 'HEADER.PERFORMANCE_SPECIFICATIONS'})}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(experimento.EspDesempAnalitico.ESa)}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(experimento.EspDesempAnalitico.EAa)}
          </span>
          <span className='text-gray-600 col-3'>
            {experimento.EspecificacionesPrecision === 'cv-fabricante'
              ? intl.formatMessage({id: 'HEADER.MANUFACTURER_CV'})
              : 'EAa'}
          </span>
          <span className='text-gray-600 col-3'>
            {experimento.PrecisionCondiciones === 'repetibilidad'
              ? intl.formatMessage({id: 'HEADER.REPEATABILITY'})
              : intl.formatMessage({id: 'HEADER.INTRALABORATORY_PRECISION'})}
          </span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <span className='col-3 form-label'>{intl.formatMessage({id: 'HEADER.REAGENT'})}</span>
          <span className='col-3 form-label'>{intl.formatMessage({id: 'SETTINGS.CALIPER'})}</span>
          <span className='col-3 form-label'>{intl.formatMessage({id: 'HEADER.MATERIAL'})}</span>
        </div>
        <div className='row'>
          <span className='col-3 text-gray-600'>{`${experimento.Reactivo.Lote} ${
            experimento.Reactivo.Vencimiento ? `(${experimento.Reactivo.Vencimiento})` : ''
          }`}</span>
          <span className='col-3 text-gray-600'>{`${experimento.Calibrador.Lote} ${
            experimento.Calibrador.Vencimiento ? `(${experimento.Calibrador.Vencimiento})` : ''
          }`}</span>
          <span className='col-3 text-gray-600'>{`${experimento.Material.Nombre} | ${
            experimento.Material.Lote
          } ${
            experimento.Material.Vencimiento ? `(${experimento.Material.Vencimiento})` : ''
          }`}</span>
        </div>
      </div>
    </div>
  )
}

export default ReportePrecisionSimpleExperimentoInfo
