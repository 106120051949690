import {FC, useEffect, useState} from 'react'
import {ReporteVIRDTO} from '../../../models/DTOs/SEM/vir/ReporteVIRDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteVIRDTO
}

const GraficoAnalisisReporteVIR: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  useEffect(() => {
    const valores = reporte.Analisis.Intervalos.map((intervalo) => intervalo.Percent.Value)
    const categorias = reporte.Analisis.Intervalos.map((intervalo) => intervalo.Description)

    setOptions({
      title: {
        text: 'Histograma de intervalos de referencia',
        x: -20,
      },
      chart: {
        type: 'column',
      },
      xAxis: {
        categories: categorias,
        title: {
          text: reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades,
        },
        plotLines: [
          {
            color: '#404040',
            dashStyle: 'Dot',
            value: 0.5,
            width: 2,
          },
          {
            color: '#404040',
            dashStyle: 'Dot',
            value: 5.5,
            width: 2,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 10,
        title: {
          text: intl.formatMessage({id: 'HEADER.PERCENT'}),
        },
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: '',
          data: valores,
          type: 'column',
        },
      ],
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoAnalisisReporteVIR
