import {FC, useEffect, useRef} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import {convertToDecimal} from '../../../helpers'
import './Chart.css'

interface Props {
  experimento: ExperimentoEp12a2DTO
  reporte: ReporteEp12a2DTO
}

const GraficosAcuerdosReporteEp12a2: FC<Props> = ({reporte, experimento}) => {
  const acuerdoPositivoContainer = useRef<HTMLDivElement>(null)
  const acuerdoNegativoContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!acuerdoPositivoContainer.current || !acuerdoNegativoContainer.current) return

    const informe: any = reporte.EspecificacionesFabricante
      ? reporte
      : reporte.AnalisisWithCriterioExactitudDiagnostica

    const experimentoAcuerdoPositivo: any = experimento.EspecificacionesFabricante
      ? experimento.IsCriterioExactitudDiagnostica
        ? experimento.EspecificacionesFabricante.SensibilidadDiagnostica
        : experimento.EspecificacionesFabricante.AcuerdoPositivo
      : experimento.IsCriterioExactitudDiagnostica
      ? experimento.Metas?.SensibilidadDiagnostica
      : experimento.Metas?.AcuerdoPositivo
    const experimentoAcuerdoNegativo: any = experimento.EspecificacionesFabricante
      ? experimento.IsCriterioExactitudDiagnostica
        ? experimento.EspecificacionesFabricante.EspecificidadDiagnostica
        : experimento.EspecificacionesFabricante.AcuerdoNegativo
      : experimento.IsCriterioExactitudDiagnostica
      ? experimento.Metas?.EspecificidadDiagnostica
      : experimento.Metas?.AcuerdoNegativo
    const informeAcuerdoPositivo = informe.EspecificacionesFabricante?.SensibilidadDiagnostica
    const informeAcuerdoNegativo = informe.EspecificacionesFabricante?.EspecificidadDiagnostica

    const chartTypePositivo = experimento.IsCriterioExactitudDiagnostica ? 'SE' : 'PPA'
    const chartTypeNegativo = experimento.IsCriterioExactitudDiagnostica ? 'SP' : 'NPA'
    const dataPositivo = {Limites: {}}
    const dataNegativo = {Limites: {}}

    function GetTextWidth(text: string, font: string): any {
      const myCanvas = document.createElement('canvas')
      const context = myCanvas.getContext('2d')!
      context.font = font

      const metrics = context.measureText(text)
      return metrics.width
    }

    const fillData = (chartType: string, data: any) => {
      switch (chartType) {
        case 'SE':
          data.Valor =
            informe?.EspecificacionesFabricante?.SensibilidadDiagnostica?.Valor ||
            informe?.EspecificacionesFabricante?.AcuerdoPositivo?.Valor
          data.Limites.Inferior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoPositivo.LimiteInferiorIC.Value
            : informeAcuerdoPositivo?.IC95.Min
          data.Limites.Superior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoPositivo.Valor.Value
            : informeAcuerdoPositivo?.IC95.Max
          data.Objetivo = experimentoAcuerdoPositivo.Valor.Value
          break
        case 'SP':
          data.Valor =
            informe?.EspecificacionesFabricante?.EspecificidadDiagnostica?.Valor ||
            informe?.EspecificacionesFabricante?.AcuerdoNegativo?.Valor
          data.Limites.Inferior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoNegativo.LimiteInferiorIC.Value
            : informeAcuerdoNegativo?.IC95.Min
          data.Limites.Superior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoNegativo.Valor.Value
            : informeAcuerdoNegativo?.IC95.Max
          data.Objetivo = experimentoAcuerdoNegativo.Valor.Value
          break
        case 'PPA':
          data.Valor =
            informe?.EspecificacionesFabricante?.SensibilidadDiagnostica?.Valor ||
            informe?.EspecificacionesFabricante?.AcuerdoPositivo?.Valor
          data.Limites.Inferior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoPositivo.LimiteInferiorIC.Value
            : informeAcuerdoPositivo?.IC95.Min
          data.Limites.Superior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoPositivo.Valor.Value
            : informeAcuerdoPositivo?.IC95.Max
          data.Objetivo = experimentoAcuerdoPositivo.Valor.Value
          break
        case 'NPA':
          data.Valor =
            informe?.EspecificacionesFabricante?.EspecificidadDiagnostica?.Valor ||
            informe?.EspecificacionesFabricante?.AcuerdoNegativo?.Valor
          data.Limites.Inferior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoNegativo.LimiteInferiorIC.Value
            : informeAcuerdoNegativo?.IC95.Min
          data.Limites.Superior = experimento.EspecificacionesFabricante
            ? experimentoAcuerdoNegativo.Valor.Value
            : informeAcuerdoNegativo?.IC95.Max
          data.Objetivo = experimentoAcuerdoNegativo.Valor.Value
          break
      }
    }

    fillData(chartTypePositivo, dataPositivo)
    fillData(chartTypeNegativo, dataNegativo)

    const getChartInfo = (chartType: string, data: any) => {
      const items = [] // Límite Inferior (fab);91.70,SE (fab);95.30,SE (obt);100.00
      let rangeMin // 91.70
      let rangeMax // 100.00
      let rangeStatus // 0

      if (experimento.EspecificacionesFabricante) {
        if (data.Valor >= data.Limites.Inferior) {
          rangeMin = data.Limites.Inferior
          rangeMax = 100
          rangeStatus = 0 // Aceptado.
        } else {
          rangeMin = 0
          rangeMax = data.Limites.Inferior
          rangeStatus = 2 // Rechazado.
        }
      } else {
        // Metas.
        if (data.Objetivo < data.Limites.Inferior) {
          rangeMin = 0
          rangeMax = data.Limites.Inferior
          rangeStatus = 0 // Aceptado.
        } else if (
          data.Objetivo >= data.Limites.Inferior &&
          data.Objetivo <= data.Limites.Superior
        ) {
          rangeMin = data.Limites.Inferior
          rangeMax = data.Limites.Superior
          rangeStatus = 1 // Aceptado alerta.
        } else {
          rangeMin = data.Limites.Superior
          rangeMax = 100
          rangeStatus = 2 // Rechazado.
        }
      }

      const itemsData = {
        EspecificacionesFabricante: {
          TipoValor: '',
          TipoValorObtenido: '',
        },
        Metas: {
          Tipo: '',
          TipoValor: '',
        },
      }
      switch (chartType) {
        case 'PPA':
          if (experimento.EspecificacionesFabricante) {
            itemsData.EspecificacionesFabricante.TipoValor = 'Límite inferior (fab)'
            itemsData.EspecificacionesFabricante.TipoValorObtenido = 'PPA (obt)'
          } else {
            itemsData.Metas.Tipo = 'PPA (obt)'
            itemsData.Metas.TipoValor = 'Meta PPA'
          }
          break
        case 'NPA':
          if (experimento.EspecificacionesFabricante) {
            itemsData.EspecificacionesFabricante.TipoValor = 'NPA (fab)'
            itemsData.EspecificacionesFabricante.TipoValorObtenido = 'NPA (obt)'
          } else {
            itemsData.Metas.Tipo = 'NPA (obt)'
            itemsData.Metas.TipoValor = 'Meta NPA'
          }
          break
        case 'SP':
          if (experimento.EspecificacionesFabricante) {
            itemsData.EspecificacionesFabricante.TipoValor = 'SP (fab)'
            itemsData.EspecificacionesFabricante.TipoValorObtenido = 'SP (obt)'
          } else {
            itemsData.Metas.Tipo = 'SP (obt)'
            itemsData.Metas.TipoValor = 'Meta SP'
          }
          break
        case 'SE':
          if (experimento.EspecificacionesFabricante) {
            itemsData.EspecificacionesFabricante.TipoValor = 'SE (fab)'
            itemsData.EspecificacionesFabricante.TipoValorObtenido = 'SE (obt)'
          } else {
            itemsData.Metas.Tipo = 'SE (obt)'
            itemsData.Metas.TipoValor = 'Meta SE'
          }
          break
      }

      if (experimento.EspecificacionesFabricante) {
        items.push(`Límite inferior (fab);${convertToDecimal(data.Limites.Inferior, 2)}`)
        items.push(
          `${itemsData.EspecificacionesFabricante.TipoValor};${convertToDecimal(data.Objetivo, 2)}`
        )
        items.push(
          `${itemsData.EspecificacionesFabricante.TipoValorObtenido};${convertToDecimal(
            data.Valor,
            2
          )}`
        )
      } else {
        items.push(`Límite inferior;${convertToDecimal(data.Limites.Inferior, 2)}`)
        items.push(`Límite superior;${convertToDecimal(data.Limites.Superior, 2)}`)
        items.push(`${itemsData.Metas.TipoValor};${convertToDecimal(data.Objetivo, 2)}`)
        items.push(`${itemsData.Metas.Tipo};${convertToDecimal(data.Valor, 2)}`)
      }

      return {
        Items: items.join(','),
        Range: {
          Min: rangeMin,
          Max: rangeMax,
          Status: rangeStatus,
        },
      }
    }

    const renderCustomIntervalChart = (container: HTMLElement) => {
      const data = {
        Items: container
          .getAttribute('data-items')!
          .split(',')
          .map((item) => {
            const aux = item.split(';')
            return {
              Item: aux[0],
              Value: +aux[1],
            }
          }),
        Range: {
          Min: +container.getAttribute('data-range-min')!,
          Max: +container.getAttribute('data-range-max')!,
          Status: +container.getAttribute('data-range-status')!,
        },
      }

      const limiteInferior = data.Items[0].Value

      const itemsValues = data.Items.map((item) => item.Value)
      const minValue = Math.min(...itemsValues)
      const maxValue = Math.max(...itemsValues)
      const minMaxValueOffset = Math.abs(maxValue - minValue) * 0.2
      const minChartValue = minValue - minMaxValueOffset
      const maxChartValue = maxValue + minMaxValueOffset
      const minMaxChartValueDiff = Math.abs(maxChartValue - minChartValue)
      const containerWidth = +container.style.width.replace('px', '')
      const coefProp = containerWidth / (maxChartValue - minChartValue)
      const offset = 0 - minChartValue * coefProp

      const scaleValue = (value: number) => value * coefProp + offset

      // Marks.
      const lineMarks = data.Items.map(
        (item) =>
          `<div class="cht-custom-interval-line-mark" style="left: ${Math.round(
            scaleValue(item.Value)
          )}px"></div>`
      )
      container.insertAdjacentHTML('beforeend', lineMarks.join(''))

      // Labels.
      const markLabelTypes = ['top', 'bottom']
      const markLabelsInfo: any = []
      const markLabelsThreshold = minMaxChartValueDiff * 0.2

      for (let markLabelType in markLabelTypes) {
        markLabelsInfo[markLabelTypes[markLabelType]] = {
          Values: [],
          Add: function (v: any) {
            this.Values.push(v)
          },
          GetPosition: function (v: any) {
            return this.Values.length > 0 &&
              Math.abs(this.Values[this.Values.length - 1] - v) < markLabelsThreshold
              ? 20
              : 0
          },
        }
      }
      let isLabelTop = false
      const marksLabels = data.Items.sort(function (a, b) {
        return a.Value - b.Value
      }).map((item) => {
        const textWidth = GetTextWidth(item.Item, '12px sans-serif ')
        const positionType = isLabelTop ? 'top' : 'bottom'
        const positionDist = markLabelsInfo[positionType].GetPosition(item.Value)
        const positionStyle = (isLabelTop ? 'top: -' : 'bottom: ') + positionDist

        isLabelTop = !isLabelTop
        markLabelsInfo[positionType].Add(item.Value)

        return (
          '<div class="cht-custom-interval-line-mark-label" style="left: ' +
          Math.round(scaleValue(item.Value) - textWidth / 2) +
          'px; ' +
          positionStyle +
          'px">' +
          item.Item +
          '</div>'
        )
      })
      container.insertAdjacentHTML('beforeend', marksLabels.join(''))

      // Range.
      let rangeWidth =
        Math.round(scaleValue(data.Range.Max)) - Math.round(scaleValue(data.Range.Min))
      if (data.Range.Max <= limiteInferior) {
        rangeWidth = Math.round(scaleValue(data.Range.Max))
        data.Range.Min = minChartValue
      } else if (data.Range.Min >= maxValue) {
        rangeWidth = containerWidth - Math.round(scaleValue(data.Range.Min))
      }
      const rangeStyle =
        data.Range.Status == 0 ? 'bg-success' : data.Range.Status == 1 ? 'bg-warning' : 'bg-danger'
      const rangeElement =
        '<div class="cht-custom-interval-range ' +
        rangeStyle +
        '" style="left: ' +
        Math.round(scaleValue(data.Range.Min)) +
        'px; width: ' +
        rangeWidth +
        'px"></div>'
      container.insertAdjacentHTML('beforeend', rangeElement)
    }

    const renderChartAcuerdoLimite = (data: any, container: HTMLDivElement) => {
      container.innerHTML = `
                          <div class="col-md-12">
                              <div class="cht-custom-interval"
                                   style="width: 450px"
                                   data-items="${data.Items}"
                                   data-range-min="${data.Range.Min}"
                                   data-range-max="${data.Range.Max}"
                                   data-range-status="${data.Range.Status}">
                                   <div></div>
                                  <div class="cht-custom-interval-main-line"></div>
                                  <div></div>
                              </div>  
                          </div>
                          `

      renderCustomIntervalChart(container.querySelector('.cht-custom-interval')!)
    }

    renderChartAcuerdoLimite(
      getChartInfo(chartTypePositivo, dataPositivo),
      acuerdoPositivoContainer.current
    )
    renderChartAcuerdoLimite(
      getChartInfo(chartTypeNegativo, dataNegativo),
      acuerdoNegativoContainer.current
    )
  }, [acuerdoPositivoContainer.current, acuerdoNegativoContainer.current])

  return (
    <>
      {(experimento.EspecificacionesFabricante || experimento.Metas) && (
        <div className='row'>
          <div className='col-6'>
            <div
              ref={acuerdoPositivoContainer}
              className='row mb-30'
              data-role='acuerdo-positivo-chart-limites-container'
            ></div>
            <div
              ref={acuerdoNegativoContainer}
              className='row mb-30'
              data-role='acuerdo-negativo-chart-limites-container'
            ></div>
          </div>
        </div>
      )}
    </>
  )
}

export default GraficosAcuerdosReporteEp12a2
