import {FC} from 'react'
import {ReporteUDTO} from '../../../models/DTOs/SEM/u/ReporteUDTO'
import {useIntl} from 'react-intl'
import {ExperimentoUDTO} from '../../../models/DTOs/SEM/u/ExperimentoUDTO'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  experimento: ExperimentoUDTO
}

const ReporteInfoExperimentoU: FC<Props> = ({experimento}) => {
  const intl = useIntl()

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-5'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{experimento.Fecha}</span>
          <span className='text-gray-600 col-5'>{`${experimento.ProcedimientoMedida.Equipo.Descripcion} / ${experimento.ProcedimientoMedida.Equipo.Plataforma} | ${experimento.ProcedimientoMedida.Mensurando.Analito} / ${experimento.ProcedimientoMedida.Mensurando.Matriz} / ${experimento.ProcedimientoMedida.Mensurando.Unidades} | ${experimento.ProcedimientoMedida.Metodo.Reactivo} / ${experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>{intl.formatMessage({id: 'HEADER.PERIOD'})}</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.COVERAGE_FACTOR'})}
          </label>
          {experimento.EQA && <label className='form-label col-6'>EQA</label>}
          {experimento.EsquemaInterlab && (
            <label className='form-label col-6'>
              {intl.formatMessage({id: 'HEADER.INTERLAB_SCHEME'})}
            </label>
          )}
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{`${experimento.Periodo.Desde} - ${experimento.Periodo.Hasta}`}</span>
          <span className='text-gray-600 col-2'>
            {toValueWithPrecisionString(experimento.KFactor)}
          </span>
          {experimento.EQA && <span className='text-gray-600 col-6'>{experimento.EQA}</span>}
          {experimento.EsquemaInterlab && (
            <span className='text-gray-600 col-6'>{experimento.EsquemaInterlab}</span>
          )}
        </div>
      </div>
      <h3 className='my-5'>{intl.formatMessage({id: 'HEADER.MU_LIMITS'})}</h3>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>{intl.formatMessage({id: 'HEADER.SOURCE'})}</label>
          <label className='form-label col-2'>
            U<sup>obj</sup> %
          </label>
          <label className='form-label col-2'>
            U<sup>obj</sup> ({experimento.ProcedimientoMedida.Mensurando.Unidades})
          </label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{experimento.LimitesTolerablesMU.Fuente}</span>
          <span className='text-gray-600 col-2'>
            {toValueWithPrecisionString(experimento.LimitesTolerablesMU.Uobj.Percent) || '-'}
          </span>
          <span className='text-gray-600 col-2'>
            {toValueWithPrecisionString(experimento.LimitesTolerablesMU.Uobj.Concentration) || '-'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ReporteInfoExperimentoU
