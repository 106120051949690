import {FC} from 'react'
import {ReportePrecisionSimpleDTO} from '../../../models/DTOs/SEM/precision-simple/ReportePrecisionSimpleDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReportePrecisionSimpleDTO
}

const TablaEstadoPrecisionSimple: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='table-responsive mb-10'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center align-middle'>
            <th style={{width: '13%'}}>{intl.formatMessage({id: 'HEADER.LEVEL'})}</th>
            <th style={{width: '13%'}}>N</th>
            <th style={{width: '13%'}}>{intl.formatMessage({id: 'HEADER.MEAN'})}</th>
            <th style={{width: '13%'}}>DE</th>
            <th style={{width: '13%'}}>CV %</th>
            <th style={{width: '13%'}}>EAa %</th>
            <th style={{width: '22%'}}>Estado de precisión</th>
          </tr>
        </thead>
        <tbody>
          {reporte.Niveles.map((nivel) => {
            return (
              <tr key={`precision_simple_tabla_estado_nivel_${nivel.Numero}`}>
                <td className='text-center'>{nivel.Numero}</td>
                <td className='text-right'>{nivel.N}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.Mean)}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.StdDev)}</td>

                <td className='text-right'>{toValueWithPrecisionString(nivel.Cv)}</td>

                <td className='text-right'>{toValueWithPrecisionString(nivel.CvFabricante)}</td>
                <td
                  className={`text-center text-${nivel.IsEstadoPrecisionOk ? 'success' : 'danger'}`}
                >
                  <i
                    className={`fa ${
                      nivel.IsEstadoPrecisionOk ? 'fa-check text-success' : 'fa-xmark text-danger'
                    }`}
                  ></i>{' '}
                  {nivel.IsEstadoPrecisionOk
                    ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaEstadoPrecisionSimple
