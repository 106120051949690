export default class ScatterWithHistogramChartBuilderEqapt {
  values: any
  limits: any
  yPlotLines: any
  yPlotBands: any
  info: any
  lines: any

  constructor() {
    this.values = []
    this.limits = []
    this.yPlotLines = []
    this.yPlotBands = []
    this.info = {}
    this.lines = []
  }

  #addHorizontalPlotLineAt(value: number, color: string, style: string) {
    this.yPlotLines.push({
      value: value,
      color: color || '#525E64',
      style: style || 'solid',
    })
  }

  withInfo(xAxisTitle: string, yAxisTitle: string, precision: number) {
    this.info = {
      axis: {
        x: {
          title: xAxisTitle,
        },
        y: {
          title: yAxisTitle,
          tickInterval: 1,
          plotLines: [],
        },
      },
      precision: precision,
    }
    return this
  }

  withValues(values: any[]) {
    this.values = values || []
    return this
  }

  withHorizontalLineAt(value: number, color: string, style: string) {
    if (value === undefined) return this

    this.#addHorizontalPlotLineAt(value, color, style)

    return this
  }

  withCustomLine(values: any[], color: string, style: string) {
    if (!values?.length) return this

    this.lines.push({
      values: values,
      color: color || '#525E64',
      style: style || 'solid',
    })

    return this
  }

  withHorizontalPlotBand(
    min: number,
    max: number,
    lineColor: string,
    lineStyle: string,
    bgColor: string
  ) {
    if (min === undefined || max === undefined) return this

    this.#addHorizontalPlotLineAt(min, lineColor, lineStyle || 'shortdash')
    this.#addHorizontalPlotLineAt(max, lineColor, lineStyle || 'shortdash')

    this.yPlotBands.push({
      min: min,
      max: max,
      background: bgColor || '#f5f5f5',
    })
    return this
  }

  build() {
    const xValues = this.values.map((value: any) => value.x)
    const classIntervals = buildClassIntervals(this.values, this.info.precision)
    const scatterChartInfo = {...this.info}
    scatterChartInfo.axis.x = {
      ...scatterChartInfo.axis.x,
      range: {
        min: Math.min(...xValues),
        max: Math.max(...xValues),
      },
    }
    scatterChartInfo.axis.y = {
      ...scatterChartInfo.axis.y,
      tickInterval: classIntervals.intervalWidth,
      range: classIntervals.range,
      plotLines: this.yPlotLines,
      plotBands: this.yPlotBands,
    }

    return {
      scatterChart: {
        ...scatterChartInfo,
        values: this.values,
        limits: this.limits,
        lines: this.lines,
      },
      histogramChart: classIntervals,
    }
  }
}

const buildClassInterval = (min: number, max: number, precision: number, count = 0) => {
  return {
    min: +min.toFixed(precision),
    max: +max.toFixed(precision),
    count: count,
  }
}

const buildClassIntervals = (values: any[], precision: number, extraValues = []) => {
  const intervals_count = 10
  const allValuesAbs = [
    ...values.map((value) => Math.abs(value.y)),
    ...extraValues.map((value) => Math.abs(value)),
  ]
  let intervals = []

  const maxAbsValue = Math.max(...allValuesAbs)
  let intervalWidth = +Math.abs(maxAbsValue / (intervals_count / 2)).toFixed(precision)
  if (intervalWidth == 0) {
    intervalWidth = 1
  }

  // Around zero.
  intervals.push(buildClassInterval(0, intervalWidth, precision, 0))
  intervals.push(buildClassInterval(-intervalWidth, 0, precision, 0))

  for (let i = 1; i < (intervals_count + 4) / 2; i++) {
    const intervalTop = buildClassInterval(i * intervalWidth, (i + 1) * intervalWidth, precision, 0)
    intervals.push(intervalTop)

    const intervalBottom = buildClassInterval(
      0 - (i + 1) * intervalWidth,
      0 - i * intervalWidth,
      precision,
      0
    )
    intervals.push(intervalBottom)
  }

  intervals = intervals.sort((a, b) => (a.min > b.min ? -1 : 1))

  // [min; max)
  intervals.forEach((interval) => {
    interval.count = values.filter(
      (value) => value.y >= interval.min && value.y < interval.max
    ).length
  })

  return {
    intervalWidth: intervalWidth,
    intervals: intervals,
    range: {
      max: intervals[0].max,
      min: intervals[intervals.length - 1].min,
    },
    maxCount: Math.max(...intervals.map((interval) => interval.count)),
  }
}
