import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import {convertToDecimal} from '../../../../helpers'

interface Props {
  reporte: ReporteCompMetodosDTO
}

const TablasModelosAnalisisRegresion: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const regresion = reporte.RegressionAnalisis

  const getRegressionModelName = (type: string) => {
    switch (type) {
      case 'olr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.1'})
      case 'wolr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.2'})
      case 'dcsdr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.3'})
      case 'dccvr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.4'})
      case 'pbr':
        return intl.formatMessage({id: 'METHOD_COMP.REGRESSION_MODEL.5'})
    }

    return type
  }

  return (
    <>
      {regresion.Models.map((model, indexModel) => {
        return (
          <div
            className='table-responsive col-7 mb-10'
            style={{margin: '0 auto'}}
            key={`tabla_modelo_analisis_regresion_${indexModel + 1}`}
          >
            <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
              <thead
                style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
              >
                <tr className='fw-bolder text-center'>
                  <th style={{width: '100%'}} colSpan={4}>
                    {getRegressionModelName(model.Type.Name)}
                  </th>
                </tr>
                <tr className='fw-bolder text-center'>
                  <th style={{width: '60%'}} colSpan={2} rowSpan={2}></th>
                  <th style={{width: '40%'}} colSpan={2}>
                    {intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL_95'})}
                  </th>
                </tr>
                <tr className='fw-bolder text-center'>
                  <th>{intl.formatMessage({id: 'HEADER.LOWER'})}</th>
                  <th>{intl.formatMessage({id: 'HEADER.UPPER'})}</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-right'>
                  <td style={{width: '40%'}} className='text-left'>
                    {intl.formatMessage({id: 'HEADER.SLOPE'})} (b)
                  </td>
                  <td style={{width: '20%'}}>{convertToDecimal(model.Slope.Value, 3)}</td>
                  <td>{convertToDecimal(model.Slope.Range.Min, 3)}</td>
                  <td>{convertToDecimal(model.Slope.Range.Max, 3)}</td>
                </tr>
                <tr className='text-right'>
                  <td className='text-left'>{intl.formatMessage({id: 'HEADER.INTERCEPT'})} (a)</td>
                  <td>{convertToDecimal(model.Intercept.Value, 3)}</td>
                  <td>{convertToDecimal(model.Intercept.Range.Min, 3)}</td>
                  <td>{convertToDecimal(model.Intercept.Range.Max, 3)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}
    </>
  )
}

export default TablasModelosAnalisisRegresion
