import {FC} from 'react'
import {ReporteEp15a3DTO} from '../../../models/DTOs/SEM/ep15a3/ReporteEp15a3DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {Enumeration} from '../../../models/DTOs/common/Enumeration'
import {ValueWithPrecisionDTO} from '../../../models/DTOs/common/ValueWithPrecisionDTO'

interface Props {
  reporte: ReporteEp15a3DTO
}

const TablaResumenReporte: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const resumenNiveles = reporte.Niveles.map((nivel) => nivel.Resumen)

  const getResumenNivelStatusCell = (value: string, status: boolean) => {
    return <td className={`text-center text-${status ? 'success' : 'danger'}`}>{value}</td>
  }

  const getResumenNivelesStatusCell = (conclusion: Enumeration, nivelesCount: number) => {
    let color
    let icon
    let text

    switch (conclusion.Name) {
      case 'aceptado':
        color = 'success'
        icon = 'fa-check'
        text = intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
        break
      case 'rechazado':
        color = 'danger'
        icon = 'fa-xmark'
        text = intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
        break
      case 'revision-director':
        color = 'warning'
        icon = 'fa-exclamation'
        text = intl.formatMessage({id: 'RESULT_STATUS.DIRECTOR_REVIEW'})
        break
    }

    return (
      <td rowSpan={nivelesCount} className={`text-center align-middle text-${color}`}>
        <i className={`fa ${icon} text-${color}`}></i> {text}
      </td>
    )
  }

  const getResumenNivelesSigmaCell = (sigma?: ValueWithPrecisionDTO) => {
    let color
    const sigmaValue = sigma?.Value

    if (sigmaValue) {
      if (sigmaValue <= 3) {
        color = 'red'
      } else if (sigmaValue <= 4) {
        color = 'orange'
      } else if (sigmaValue <= 5) {
        color = 'yellow'
      } else if (sigmaValue < 6) {
        color = '#00CED1'
      } else {
        color = 'green'
      }
    }

    return (
      <td className='text-right' style={{color: color}}>
        {toValueWithPrecisionString(sigma) || '-'}
      </td>
    )
  }

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center align-middle'>
            <th style={{width: '8%'}}>{intl.formatMessage({id: 'HEADER.LEVEL'})}</th>
            <th style={{width: '8%'}}>% ETa</th>
            <th style={{width: '17%'}}>
              {intl.formatMessage({id: 'HEADER.STATISTICAL_INTERMEDIATE_PRECISION'})}
            </th>
            <th style={{width: '17%'}}>
              {intl.formatMessage({id: 'HEADER.CLINICAL_INTERMEDIATE_PRECISION'})}
            </th>
            <th style={{width: '17%'}}>
              {intl.formatMessage({id: 'HEADER.STATISTICAL_VERACITY'})}
            </th>
            <th style={{width: '15%'}}>{intl.formatMessage({id: 'HEADER.CLINICAL_VERACITY'})}</th>
            <th style={{width: '10%'}}>
              {intl.formatMessage({id: 'HEADER.STATUS'})}
              <sup>(1)</sup>
            </th>
            <th style={{width: '8%'}}>Sigma</th>
          </tr>
        </thead>
        <tbody>
          {resumenNiveles.map((nivel, index) => {
            return (
              <tr key={`tabla_resumen_reporte_nivel_${nivel.Numero}`}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.ETaPercent)}</td>
                {getResumenNivelStatusCell(
                  nivel.PrecisionIntEstEvaluada
                    ? nivel.PrecisionIntEstOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.VERIFIED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.NOT_VERIFIED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  nivel.PrecisionIntEstOk
                )}
                {getResumenNivelStatusCell(
                  nivel.PrecisionIntCliEvaluada
                    ? nivel.PrecisionIntCliOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  nivel.PrecisionIntCliOk
                )}
                {getResumenNivelStatusCell(
                  nivel.VeracidadEstEvaluada
                    ? nivel.VeracidadEstOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  nivel.VeracidadEstOk
                )}
                {getResumenNivelStatusCell(
                  nivel.VeracidadCliEvaluada
                    ? nivel.VeracidadCliOk
                      ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
                      : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
                    : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'}),
                  nivel.VeracidadCliOk
                )}
                {index === 0
                  ? getResumenNivelesStatusCell(reporte.ConclusionResumen, resumenNiveles.length)
                  : ''}
                {getResumenNivelesSigmaCell(nivel.Sigma)}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={15}>
              <small>{intl.formatMessage({id: 'INFO.EP15A3_REPORT_SUMMARY'})}</small>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default TablaResumenReporte
