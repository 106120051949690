import {FC, useEffect, useState} from 'react'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteEp6DTO
}

const GraficoDispersionValorAsignadoValorMedido: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  function GetLineSerie(data: any, color: string): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: 'Solid',
      type: 'line',
    }
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  useEffect(() => {
    const unidades = reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
    const series = []
    const xValues = reporte.AnalisisDatos.Muestras.map((muestra) =>
      Number(muestra.ValorAsignado?.Value)
    )
    const valuesPrecision = reporte.Experimento.Muestras!.map((muestra) => muestra.Valor.Precision)
    const precision = Math.max(...valuesPrecision)

    // Replicados.
    for (let muestraIndex = 0; muestraIndex < reporte.Experimento.CantMuestras; muestraIndex++) {
      series.push(
        GetScatterSerie(
          reporte.Experimento.Datos![muestraIndex].Replicas.map(function (v) {
            return [xValues[muestraIndex], v.Valor.Value]
          }),
          '#FFF',
          '#4B77BE'
        )
      )
    }

    // Regression.
    const maxValorValue = Math.max(...xValues)
    const minRegressionValue = Math.min(
      ...reporte.AnalisisDatos.Muestras.map((muestra) => muestra.RegressionValue!.Value)
    )
    const maxRegressionValue = Math.max(
      ...reporte.AnalisisDatos.Muestras.map((muestra) => muestra.RegressionValue!.Value)
    )
    series.push(
      GetLineSerie(
        [
          [0, minRegressionValue],
          [maxValorValue, maxRegressionValue],
        ],
        '#E08283'
      )
    )

    setOptions({
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})} (${unidades})`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, precision)
          },
        },
        endOnTick: true,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.MEASURED_VALUE'})} (${unidades})`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 1)
          },
        },
        lineWidth: 1,
        endOnTick: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '[' + this.x.toFixed(precision) + ' ; ' + this.y?.toFixed(precision) + ']'
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoDispersionValorAsignadoValorMedido
