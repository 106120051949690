import {FC} from 'react'
import {ReporteEp15a3DTO} from '../../../models/DTOs/SEM/ep15a3/ReporteEp15a3DTO'
import {toValueWithPrecisionString} from '../../../helpers'
import {AlertDanger, AlertSuccess} from '../../UI/alerts/Alerts'
import {useIntl} from 'react-intl'

interface Props {
  reporte: ReporteEp15a3DTO
  indexNivel: number
}

const DatosVeracidad: FC<Props> = ({indexNivel, reporte}) => {
  const intl = useIntl()

  const veracidad = reporte.Niveles[indexNivel].Veracidad

  const conclusionText = (conclusion: string) => {
    return conclusion === 'aceptada'
      ? intl.formatMessage({id: 'CONCLUSION.ACCEPTED'})
      : conclusion === 'rechazada'
      ? intl.formatMessage({id: 'CONCLUSION.REJECTED'})
      : intl.formatMessage({id: 'CONCLUSION.OTHER'})
  }

  const conclusionESaText = (conclusion: string) => {
    return conclusion === 'ok'
      ? intl.formatMessage({id: 'CONCLUSION_ESA.ACCEPTED'})
      : intl.formatMessage({id: 'CONCLUSION_ESA.REJECTED'})
  }

  const conclusionSesgoText = (conclusion: string) => {
    return conclusion === 'no-clinicamente-significativo'
      ? intl.formatMessage({id: 'CONCLUSION_SESGO.ACCEPTED'})
      : intl.formatMessage({id: 'CONCLUSION_SESGO.REJECTED'})
  }

  return (
    <>
      <div className='mb-10'>
        <div className='row'>
          <span className='col form-label'>Valor evaluado</span>
          <span className='col form-label'>
            se<sub>RM</sub>
          </span>
          <span className='col form-label'>Media</span>
          <span className='col form-label'>
            se<sub>x</sub>
          </span>
          <span className='col form-label'>
            se<sub>c</sub>
          </span>
          <span className='col form-label'>Valor inferior</span>
          <span className='col form-label'>Valor superior</span>
        </div>
        <div className='row'>
          <span className='col text-gray-600'>
            {toValueWithPrecisionString(
              reporte?.Experimento.Niveles[indexNivel].Precision.Concentracion
            )}
          </span>
          <span className='col text-gray-600'>{toValueWithPrecisionString(veracidad.SErm)}</span>
          <span className='col text-gray-600'>{toValueWithPrecisionString(veracidad.Mean)}</span>
          <span className='col text-gray-600'>{toValueWithPrecisionString(veracidad.SEx)}</span>
          <span className='col text-gray-600'>{toValueWithPrecisionString(veracidad.SEc)}</span>
          <span className='col text-gray-600'>
            {toValueWithPrecisionString(veracidad.Limits?.Lower)}
          </span>
          <span className='col text-gray-600'>
            {toValueWithPrecisionString(veracidad.Limits?.Higher)}
          </span>
        </div>
      </div>
      {veracidad.Conclusion?.Name === 'aceptada' ? (
        <AlertSuccess>{conclusionText(veracidad.Conclusion.Name)}</AlertSuccess>
      ) : (
        <AlertDanger>{conclusionText(veracidad.Conclusion!.Name)}</AlertDanger>
      )}
      {veracidad.ConclusionESa?.Name === 'datos-insuficientes' ? (
        <AlertDanger>{conclusionESaText(veracidad.ConclusionESa.Name)}</AlertDanger>
      ) : (
        <>
          <div className='mb-10'>
            <div className='row'>
              <span className='col form-label'>
                ESa<sub>(c)</sub>
              </span>
              <span className='col form-label'>% ESa</span>
              <span className='col form-label'>
                Sesgo<sub>(c)</sub>
              </span>
              <span className='col form-label'>% Sesgo</span>
              <span className='col form-label'>
                |Sesgo<sub>(c)</sub>|
              </span>
              <span className='col form-label'>|% Sesgo|</span>
            </div>
            <div className='row'>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.ESa?.Concentration)}{' '}
                {reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades}
              </span>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.ESa?.Percent)} %
              </span>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.Sesgo?.Concentration)}{' '}
                {reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades}
              </span>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.Sesgo?.Percent)} %
              </span>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.SesgoAbs?.Concentration)}{' '}
                {reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades}
              </span>
              <span className='col text-gray-600'>
                {toValueWithPrecisionString(veracidad.SesgoAbs?.Percent)} %
              </span>
            </div>
          </div>
          {veracidad.ConclusionSesgo &&
            (veracidad.ConclusionSesgo.Name === 'no-clinicamente-significativo' ? (
              <AlertSuccess>{conclusionSesgoText(veracidad.ConclusionSesgo.Name)}</AlertSuccess>
            ) : (
              <AlertDanger>{conclusionSesgoText(veracidad.ConclusionSesgo.Name)}</AlertDanger>
            ))}
        </>
      )}
    </>
  )
}

export default DatosVeracidad
