import {FC} from 'react'
import {ReporteLoQDTO} from '../../models/DTOs/SEM/loq/ReporteLoQDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import {AlertSuccess, AlertDanger} from '../../components/UI/alerts/Alerts'
import ReporteLoQInfo from '../../components/loq/reporte/ReporteLoQInfo'
import TablaAnalisisReporteLoQ from '../../components/loq/reporte/TablaAnalisisReporteLoQ'
import TablasMuestrasReporteLoQ from '../../components/loq/reporte/TablasMuestrasReporteLoQ'

const ReporteLoQ: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteLoQDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReporteLoQInfo reporte={reporte} />
        <div style={{pageBreakInside: 'avoid'}}>
          <TablasMuestrasReporteLoQ reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaAnalisisReporteLoQ reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <h1>{intl.formatMessage({id: 'HEADER.CONCLUSION'})}</h1>
          {reporte.Conclusion.Name === 'aceptado' ? (
            <AlertSuccess>{intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})}</AlertSuccess>
          ) : (
            <AlertDanger>{intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})}</AlertDanger>
          )}
        </div>
      </div>
    </>
  )
}

export default ReporteLoQ
