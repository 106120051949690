import {FC} from 'react'
import {ReporteLoQDTO} from '../../../models/DTOs/SEM/loq/ReporteLoQDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteLoQDTO
}

const ReporteLoQInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-5'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{reporte.Experimento.Fecha}</span>
          <span className='text-gray-600 col-5'>{`${reporte.Experimento.ProcedimientoMedida.Equipo.Descripcion} / ${reporte.Experimento.ProcedimientoMedida.Equipo.Plataforma} | ${reporte.Experimento.ProcedimientoMedida.Mensurando.Analito} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Matriz} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades} | ${reporte.Experimento.ProcedimientoMedida.Metodo.Reactivo} / ${reporte.Experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{reporte.Experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {`${intl.formatMessage({id: 'HEADER.SOURCE'})} ETa`}
          </label>
          <label className='form-label col-1'>ETa %</label>
          <label className='form-label col-1'>ETa cc</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.DECLARED_LOQ'})}
          </label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>
            {reporte.Experimento.EspDesempAnalitico.FuenteETa.Name}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(reporte.Experimento.EspDesempAnalitico.ETa?.Percent) || '-'}
          </span>
          <span className='text-gray-600 col-1'>
            {toValueWithPrecisionString(
              reporte.Experimento.EspDesempAnalitico.ETa?.Concentration
            ) || '-'}
          </span>
          <span className='text-gray-600 col-2'>
            {toValueWithPrecisionString(reporte.Experimento.LoQDeclarado)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ReporteLoQInfo
