import {FC} from 'react'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {Enumeration} from '../../../models/DTOs/common/Enumeration'

interface Props {
  reporte: ReporteEp6DTO
}

const TablaAnalisisDatos: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const tipoEvaluacion = reporte.Experimento.TipoEvaluacion
  const unidades = reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
  let headerRows
  let muestrasRows
  const showUnidades = tipoEvaluacion === 'validacion'

  const getEstadoAnalisisDatosCell = (estado: Enumeration) => {
    return (
      <td className={`text-center text-${estado.Name === 'aceptada' ? 'success' : 'danger'}`}>
        <i
          className={`fa fa-${
            estado.Name === 'aceptada' ? 'check text-success' : 'xmark text-danger'
          }`}
        ></i>{' '}
        {estado.Name === 'aceptada'
          ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
          : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})}
      </td>
    )
  }

  if (tipoEvaluacion === 'validacion') {
    // Headers.
    headerRows = [
      <th key={`tabla_analisis_datos_muestras_header_%ESa`} className='text-center align-middle'>
        % ESa
      </th>,
      <th
        key={`tabla_analisis_datos_muestras_header_desviacioncc`}
        className='text-center align-middle'
      >{`${intl.formatMessage({id: 'HEADER.DEVIATION'})} (${unidades})`}</th>,
      <th
        key={`tabla_analisis_datos_muestras_header_desviacion%`}
        className='text-center align-middle'
      >{`${intl.formatMessage({id: 'HEADER.DEVIATION'})} (%)`}</th>,
    ]

    // Rows.
    muestrasRows = reporte.AnalisisDatos.Muestras.map((muestra) => (
      <tr key={`tabla_analisis_datos_muestras_row_${muestra.Muestra}`}>
        <td className='text-center'>{muestra.Muestra}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ValorAsignado)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ValorPredicho)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.Mean)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ESa?.Percent)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.StdDev?.Concentration)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.StdDev?.Percent)}</td>
        {getEstadoAnalisisDatosCell(muestra.Estado)}
      </tr>
    ))
  } else {
    // Headers.
    headerRows = [
      <th
        key={`tabla_analisis_datos_muestras_header_desviacion`}
        className='text-center align-middle'
      >
        {intl.formatMessage({id: 'HEADER.DEVIATION'})}
      </th>,
      <th
        key={`tabla_analisis_datos_muestras_header_intervalo_confianza`}
        className='text-center align-middle'
        colSpan={2}
      >{`${intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL'})} 90%`}</th>,
      <th key={`tabla_analisis_datos_muestras_header_ESa`} className='text-center align-middle'>
        ESa
      </th>,
    ]

    // Rows.
    muestrasRows = reporte.AnalisisDatos.Muestras.map((muestra) => (
      <tr key={`tabla_analisis_datos_muestras_row_${muestra.Muestra}`}>
        <td className='text-center'>{muestra.Muestra}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ValorAsignado)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ValorPredicho)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.Mean)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.StdDev?.Concentration)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.IC90Percent?.Lower)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.IC90Percent?.Higher)}</td>
        <td className='text-right'>{toValueWithPrecisionString(muestra.ESa?.Concentration)}</td>
        {getEstadoAnalisisDatosCell(muestra.Estado)}
      </tr>
    ))
  }

  const footerContent = reporte.Experimento.IsAnalisisInLog
    ? 'Datos expresados en log10 (concentración)'
    : `Datos expresados en unidades de concentración (${unidades})`

  return (
    <div className='table-responsive mb-10'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center'>
            <th className='text-center align-middle'>
              {intl.formatMessage({id: 'HEADER.SAMPLE'})}
            </th>
            <th className='text-center align-middle'>
              {`${intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})} ${
                showUnidades && '(' + unidades + ')'
              }`}
            </th>
            <th className='text-center align-middle'>
              {`${intl.formatMessage({id: 'HEADER.PREDICTED_VALUE'})} ${
                showUnidades && '(' + unidades + ')'
              }`}
            </th>
            <th className='text-center align-middle'>
              {`${intl.formatMessage({id: 'HEADER.MEAN'})} ${showUnidades && '(' + unidades + ')'}`}
            </th>
            {headerRows}
            <th className='text-center align-middle col-md-2'>
              {intl.formatMessage({id: 'HEADER.STATUS'})}
            </th>
          </tr>
        </thead>
        <tbody>{muestrasRows}</tbody>
        <tfoot>
          <tr>
            <td className='text-center' colSpan={12}>
              {footerContent}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default TablaAnalisisDatos
