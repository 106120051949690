import {FC} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import {useIntl} from 'react-intl'
import GraficoBurbujasReporteEp12a2 from './GraficoBurbujasReporteEp12a2'
import AcuerdoInfoReporteEp12a2 from './AcuerdoInfoReporteEp12a2'

interface Props {
  experimento: ExperimentoEp12a2DTO
  reporte: ReporteEp12a2DTO
}

const AnalisisReporteEp12a2: FC<Props> = ({experimento, reporte}) => {
  const intl = useIntl()

  return (
    <>
      <h1 className='mb-5'>{intl.formatMessage({id: 'HEADER.DATA_ANALYSIS'})}</h1>
      <div className='row mb-8'>
        <div className='col-6'>
          <div className='table-responsive mb-5'>
            <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
              <thead
                style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
              >
                <tr className='fw-bolder text-center align-middle'>
                  <th rowSpan={2}>{intl.formatMessage({id: 'HEADER.EVALUATED_METHOD'})}</th>
                  <th colSpan={2}>{intl.formatMessage({id: 'HEADER.COMPARATOR_METHOD'})}</th>
                </tr>
                <tr className='fw-bolder text-center'>
                  <th>{intl.formatMessage({id: 'SETTINGS.POSITIVE'})}</th>
                  <th>{intl.formatMessage({id: 'SETTINGS.NEGATIVE'})}</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>{intl.formatMessage({id: 'SETTINGS.POSITIVE'})}</td>
                  <td>{reporte.MatrizCalculos.TP}</td>
                  <td>{reporte.MatrizCalculos.FP}</td>
                </tr>
                <tr className='text-center'>
                  <td>{intl.formatMessage({id: 'SETTINGS.NEGATIVE'})}</td>
                  <td>{reporte.MatrizCalculos.FN}</td>
                  <td>{reporte.MatrizCalculos.TN}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <GraficoBurbujasReporteEp12a2 experimento={experimento} reporte={reporte} />
        </div>
        <div className='col-6'>
          <AcuerdoInfoReporteEp12a2 experimento={experimento} reporte={reporte} />
        </div>
      </div>
    </>
  )
}

export default AnalisisReporteEp12a2
