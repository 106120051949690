import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {FC} from 'react'
import CardButton from '../../components/UI/card-button/CardButton'
import {useHistory} from 'react-router-dom'

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  let history = useHistory()

  const modulos = [
    {
      Nombre: intl.formatMessage({id: 'HEADER.EP15A3'}),
      Link: '/ep15a3',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.EP15A3_PRECISION'}),
      Link: '/ep15a3-precision',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.SIMPLE_PRECISION'}),
      Link: '/simple-precision',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.EP6'}),
      Link: '/ep6',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.LOQ'}),
      Link: '/loq',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.VIR'}),
      Link: '/vir',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.METHODS_COMP'}),
      Link: '/methods-comp',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.METHODS_COMP_EQAPT'}),
      Link: '/methods-comp-eqapt',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.U'}),
      Link: '/u',
    },
    {
      Nombre: intl.formatMessage({id: 'HEADER.EP12A2'}),
      Link: '/ep12a2',
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: process.env.REACT_APP_NAME})}</PageTitle>

      <div className='row'>
        {modulos.map((modulo) => {
          return (
            <div className='col-4 my-5' key={`modulo_sem_${modulo.Nombre}`}>
              <CardButton
                title={modulo.Nombre}
                description={''}
                onClick={() => history.push(modulo.Link)}
                icon='/media/logosG/logo_gm_sem.png'
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export {DashboardWrapper}
