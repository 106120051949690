import {FC, useEffect} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {ReporteEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ReporteEp12a2DTO'
import {useIntl} from 'react-intl'
import {
  capitalizeFirstLetter,
  convertToDecimal,
  intervalToString,
  toValueWithPrecisionString,
} from '../../../helpers'
import {AlertWarning} from '../../UI/alerts/Alerts'
import GraficosAcuerdosReporteEp12a2 from './GraficosAcuerdosReporteEp12a2'

interface Props {
  experimento: ExperimentoEp12a2DTO
  reporte: ReporteEp12a2DTO
}

const AcuerdoInfoReporteEp12a2: FC<Props> = ({experimento, reporte}) => {
  const intl = useIntl()

  const experimentoAcuerdoPositivo: any = experimento.EspecificacionesFabricante
    ? experimento.IsCriterioExactitudDiagnostica
      ? experimento.EspecificacionesFabricante.SensibilidadDiagnostica
      : experimento.EspecificacionesFabricante.AcuerdoPositivo
    : experimento.IsCriterioExactitudDiagnostica
    ? experimento.Metas?.SensibilidadDiagnostica
    : experimento.Metas?.AcuerdoPositivo
  const experimentoAcuerdoNegativo: any = experimento.EspecificacionesFabricante
    ? experimento.IsCriterioExactitudDiagnostica
      ? experimento.EspecificacionesFabricante.EspecificidadDiagnostica
      : experimento.EspecificacionesFabricante.AcuerdoNegativo
    : experimento.IsCriterioExactitudDiagnostica
    ? experimento.Metas?.EspecificidadDiagnostica
    : experimento.Metas?.AcuerdoNegativo

  const getInterpretacionCohensKappa = (name: string) => {
    switch (name) {
      case 'sin-acuerdo':
        return intl.formatMessage({id: 'COHENS_KAPPA.1'})
      case 'insignificante':
        return intl.formatMessage({id: 'COHENS_KAPPA.2'})
      case 'discreto':
        return intl.formatMessage({id: 'COHENS_KAPPA.3'})
      case 'moderado':
        return intl.formatMessage({id: 'COHENS_KAPPA.4'})
      case 'sustancial':
        return intl.formatMessage({id: 'COHENS_KAPPA.5'})
      case 'casi-perfecto':
        return intl.formatMessage({id: 'COHENS_KAPPA.6'})
    }

    return name
  }

  const formatAcuerdo = (conclusion: string | undefined, value: number | undefined) => {
    let color
    let icon
    let text

    switch (conclusion) {
      case 'verificada':
      case 'aceptada':
        color = 'success'
        icon = 'check'
        text = capitalizeFirstLetter(conclusion)
        break
      case 'no-verificada':
      case 'rechazada':
        color = 'danger'
        icon = 'xmark'
        text =
          conclusion === 'no-verificada'
            ? intl.formatMessage({id: 'RESULT_STATUS.NOT_VERIFIED'})
            : intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
        break
      case 'indeterminado':
        color = 'warning'
        icon = 'exclamation'
        text = intl.formatMessage({id: 'RESULT_STATUS.INDETERMINATE'})
        break
    }

    return (
      <>
        {`${convertToDecimal(value, 2)}%`}
        <span className={`text-${color} mx-2`}>
          <i className={`fa fa-${icon} text-${color}`}></i> {text}
        </span>
      </>
    )
  }

  const getConclusionAlarma = (name: string) => {
    switch (name) {
      case 'aumentar-muestras-positivas':
        return intl.formatMessage({id: 'AGREEMENT_ALERT.1'})
      case 'aumentar-muestras-negativas':
        return intl.formatMessage({id: 'AGREEMENT_ALERT.2'})
      case 'metodo-screening':
        return intl.formatMessage({id: 'AGREEMENT_ALERT.3'})
      case 'metodo-diagnostico':
        return intl.formatMessage({id: 'AGREEMENT_ALERT.4'})
    }

    return name
  }

  return (
    <>
      {!experimento.EspecificacionesFabricante && !experimento.Metas && reporte.Acuerdo && (
        <div className='row mb-5'>
          <div className='col-6'>
            <label className='form-label'>{intl.formatMessage({id: 'HEADER.AGREEMENT'})}</label>
            <span className='text-gray-600'>{`${convertToDecimal(
              reporte.Acuerdo.Valor,
              2
            )}%`}</span>
          </div>
          <div className='col'>
            <label className='form-label'>IC</label>
            <span className='text-gray-600'>{intervalToString(reporte.Acuerdo.IC95)}</span>
          </div>
        </div>
      )}

      <div className='row mb-5'>
        <div className='col-6'>
          <label className='form-label'>
            {experimento.IsCriterioExactitudDiagnostica
              ? intl.formatMessage({id: 'HEADER.DIAGNOSTIC_SENSITIVITY_O'})
              : `${intl.formatMessage({id: 'HEADER.POSITIVE_AGREEMENT'})} ${
                  experimento.EspecificacionesFabricante || experimento.Metas
                    ? intl.formatMessage({id: 'HEADER.OBTAINED'}).toLowerCase()
                    : ''
                }`}
          </label>
          <br />
          <span className='text-gray-600'>{`${convertToDecimal(
            reporte.AcuerdoPositivo?.Valor ||
              reporte.EspecificacionesFabricante?.SensibilidadDiagnostica?.Valor,
            2
          )}%`}</span>
        </div>
        <div className='col-6'>
          <label className='form-label'>IC</label>
          <br />
          <span className='text-gray-600'>
            {intervalToString(
              reporte.AcuerdoPositivo?.IC95 ||
                reporte.EspecificacionesFabricante?.SensibilidadDiagnostica?.IC95
            )}
          </span>
        </div>
      </div>

      {experimentoAcuerdoPositivo && (
        <div className='row mb-5'>
          <div className='col-6'>
            <label className='form-label'>
              {experimento.IsCriterioExactitudDiagnostica
                ? intl.formatMessage({id: 'HEADER.DIAGNOSTIC_SENSITIVITY_F'})
                : experimento.EspecificacionesFabricante
                ? intl.formatMessage({id: 'HEADER.POSITIVE_AGREEMENT_F'})
                : intl.formatMessage({id: 'HEADER.POSITIVE_AGREEMENT_GOAL'})}
            </label>
            <br />
            <span className='text-gray-600'>
              {formatAcuerdo(
                experimento.EspecificacionesFabricante
                  ? reporte.EspecificacionesFabricante?.SensibilidadDiagnostica.Conclusion.Name ||
                      reporte.AnalisisWithCriterioExactitudDiagnostica?.EspecificacionesFabricante
                        ?.SensibilidadDiagnostica.Conclusion.Name
                  : reporte.Metas?.SensibilidadDiagnostica.Conclusion.Name ||
                      reporte.AnalisisWithCriterioExactitudDiagnostica?.Metas
                        ?.SensibilidadDiagnostica.Conclusion.Name,
                experimentoAcuerdoPositivo?.Valor.Value
              )}
            </span>
          </div>
          {experimentoAcuerdoPositivo?.LimiteInferiorIC && (
            <div className='col-6'>
              <label className='form-label'>
                {intl.formatMessage({id: 'HEADER.LOWER_LIMIT_IC'})}
              </label>
              <br />
              <span className='text-gray-600'>
                {`${toValueWithPrecisionString(experimentoAcuerdoPositivo.LimiteInferiorIC)}%`}
              </span>
            </div>
          )}
        </div>
      )}

      <div className='row mb-5'>
        <div className='col-6'>
          <label className='form-label'>
            {experimento.IsCriterioExactitudDiagnostica
              ? intl.formatMessage({id: 'HEADER.DIAGNOSTIC_SPECIFICITY_O'})
              : `${intl.formatMessage({id: 'HEADER.NEGATIVE_AGREEMENT'})} ${
                  experimento.EspecificacionesFabricante || experimento.Metas
                    ? intl.formatMessage({id: 'HEADER.OBTAINED'}).toLowerCase()
                    : ''
                }`}
          </label>
          <br />
          <span className='text-gray-600'>{`${convertToDecimal(
            reporte.AcuerdoNegativo?.Valor ||
              reporte.EspecificacionesFabricante?.EspecificidadDiagnostica?.Valor,
            2
          )}%`}</span>
        </div>
        <div className='col-6'>
          <label className='form-label'>IC</label>
          <br />
          <span className='text-gray-600'>
            {intervalToString(
              reporte.AcuerdoNegativo?.IC95 ||
                reporte.EspecificacionesFabricante?.EspecificidadDiagnostica?.IC95
            )}
          </span>
        </div>
      </div>

      {experimentoAcuerdoNegativo && (
        <div className='row mb-5'>
          <div className='col-6'>
            <label className='form-label'>
              {experimento.IsCriterioExactitudDiagnostica
                ? intl.formatMessage({id: 'HEADER.DIAGNOSTIC_SPECIFICITY_F'})
                : experimento.EspecificacionesFabricante
                ? intl.formatMessage({id: 'HEADER.NEGATIVE_AGREEMENT_F'})
                : intl.formatMessage({id: 'HEADER.NEGATIVE_AGREEMENT_GOAL'})}
            </label>
            <br />
            <span className='text-gray-600'>
              {formatAcuerdo(
                experimento.EspecificacionesFabricante
                  ? reporte.EspecificacionesFabricante?.EspecificidadDiagnostica.Conclusion.Name ||
                      reporte.AnalisisWithCriterioExactitudDiagnostica?.EspecificacionesFabricante
                        ?.EspecificidadDiagnostica.Conclusion.Name
                  : reporte.Metas?.EspecificidadDiagnostica.Conclusion.Name ||
                      reporte.AnalisisWithCriterioExactitudDiagnostica?.Metas
                        ?.EspecificidadDiagnostica.Conclusion.Name,
                experimentoAcuerdoNegativo?.Valor.Value
              )}
            </span>
          </div>
          {experimentoAcuerdoNegativo?.LimiteInferiorIC && (
            <div className='col-6'>
              <label className='form-label'>
                {intl.formatMessage({id: 'HEADER.LOWER_LIMIT_IC'})}
              </label>
              <br />
              <span className='text-gray-600'>
                {`${toValueWithPrecisionString(experimentoAcuerdoNegativo.LimiteInferiorIC)}%`}
              </span>
            </div>
          )}
        </div>
      )}

      {reporte.CohensKappa && (
        <div className='row mb-5'>
          <div className='col-6'>
            <label className='form-label'>Cohen's kappa</label>
            <br />
            <span className='text-gray-600'>
              {`${convertToDecimal(reporte.CohensKappa.Valor, 2)} (${getInterpretacionCohensKappa(
                reporte.CohensKappa.Interpretacion.Name
              )})`}
            </span>
          </div>
        </div>
      )}

      <div className='row mb-20' id='alarmas-container'>
        {experimento.EspecificacionesFabricante ? (
          <>
            {reporte.EspecificacionesFabricante?.SensibilidadDiagnostica.ConclusionAlarma?.Name &&
              reporte.EspecificacionesFabricante?.SensibilidadDiagnostica.ConclusionAlarma?.Name !==
                'ninguna' && (
                <AlertWarning>
                  {intl.formatMessage({id: 'HEADER.ALERT'})}:{' '}
                  {getConclusionAlarma(
                    reporte.EspecificacionesFabricante.SensibilidadDiagnostica.ConclusionAlarma.Name
                  )}
                </AlertWarning>
              )}
          </>
        ) : experimento.Metas ? (
          <>
            {reporte.Metas?.SensibilidadDiagnostica.ConclusionAlarma?.Name &&
              reporte.Metas?.SensibilidadDiagnostica.ConclusionAlarma?.Name !== 'ninguna' && (
                <AlertWarning>
                  {intl.formatMessage({id: 'HEADER.ALERT'})}:{' '}
                  {getConclusionAlarma(reporte.Metas.SensibilidadDiagnostica.ConclusionAlarma.Name)}
                  <br />
                  {intl.formatMessage({id: 'HEADER.SUGGESTED_AMOUNT'})}:{' '}
                  {reporte.Metas.CantMuestrasSugeridasPositivas}
                </AlertWarning>
              )}
            {reporte.Metas?.EspecificidadDiagnostica.ConclusionAlarma?.Name &&
              reporte.Metas?.EspecificidadDiagnostica.ConclusionAlarma?.Name !== 'ninguna' && (
                <AlertWarning>
                  {intl.formatMessage({id: 'HEADER.ALERT'})}:{' '}
                  {getConclusionAlarma(
                    reporte.Metas.EspecificidadDiagnostica.ConclusionAlarma.Name
                  )}
                  <br />
                  {intl.formatMessage({id: 'HEADER.SUGGESTED_AMOUNT'})}:{' '}
                  {reporte.Metas.CantMuestrasSugeridasNegativas}
                </AlertWarning>
              )}
          </>
        ) : (
          <></>
        )}
      </div>
      <GraficosAcuerdosReporteEp12a2 experimento={experimento} reporte={reporte} />
    </>
  )
}

export default AcuerdoInfoReporteEp12a2
