import {FC} from 'react'
import {ReporteVIRDTO} from '../../models/DTOs/SEM/vir/ReporteVIRDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import {AlertSuccess, AlertDanger} from '../../components/UI/alerts/Alerts'
import AnalisisReporteVIR from '../../components/vir/reporte/AnalisisReporteVIT'
import ReporteVIRExperimentoInfo from '../../components/vir/reporte/ReporteVIRExperimentoInfo'
import TablaValoresVIRReporte from '../../components/vir/reporte/TablaValoresVIRReporte'

const ReporteVIR: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteVIRDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReporteVIRExperimentoInfo reporte={reporte} />
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaValoresVIRReporte reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <AnalisisReporteVIR reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <h1 className='my-5'>{intl.formatMessage({id: 'HEADER.CONCLUSION'})}</h1>
          {reporte.Analisis.Conclusion.Name === 'verificado' ? (
            <AlertSuccess>{intl.formatMessage({id: 'VIR_RESULT_STATUS.VERIFIED'})}</AlertSuccess>
          ) : (
            <AlertDanger>{intl.formatMessage({id: 'VIR_RESULT_STATUS.NOT_VERIFIED'})}</AlertDanger>
          )}
        </div>
      </div>
    </>
  )
}

export default ReporteVIR
