import {FC, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Error404} from '../errors/Error404'
import {setLanguageTemp} from '../../../_metronic/i18n/Metronici18n'
import {setTempStorage} from '../../helpers/StorageHelper'
import ReporteEp15a3 from '../../pages/pdf/ReporteEp15a3'
import ReporteEp15a3Precision from '../../pages/pdf/ReporteEp15a3Precision'
import ReportePrecisionSimple from '../../pages/pdf/ReportePrecisionSimple'
import ReporteEp6 from '../../pages/pdf/ReporteEp6'
import ReporteLoQ from '../../pages/pdf/ReporteLoQ'
import ReporteVIR from '../../pages/pdf/ReporteVIR'
import ReporteU from '../../pages/pdf/ReporteU'
import ReporteEp12a2 from '../../pages/pdf/ReporteEp12a2'
import ReporteCompMetodos from '../../pages/pdf/ReporteCompMetodos'
import ReporteCompMetodosEqapt from '../../pages/pdf/ReporteCompMetodosEqapt'

const GetExternalComponent: FC = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)

  const action = params.get('action')
  const accessToken = params.get('token')
  const lang = params.get('lang')

  useEffect(() => {
    if (!lang || !accessToken) return
    setLanguageTemp(lang)
    setTempStorage(accessToken)
  }, [])

  if (!accessToken || !lang) return <Error404 />

  switch (action) {
    case 'REPORTE_PDF':
      const type = params.get('type')

      switch (type) {
        case 'EP15A3':
          return <ReporteEp15a3 />
        case 'EP15A3_PRECISION':
          return <ReporteEp15a3Precision />
        case 'PRECISION_SIMPLE':
          return <ReportePrecisionSimple />
        case 'EP6':
          return <ReporteEp6 />
        case 'LOQ':
          return <ReporteLoQ />
        case 'VIR':
          return <ReporteVIR />
        case 'COMP_METODOS':
          return <ReporteCompMetodos />
        case 'COMP_METODOS_EQAPT':
          return <ReporteCompMetodosEqapt />
        case 'U':
          return <ReporteU />
        case 'EP12A2':
          return <ReporteEp12a2 />
        default:
          return <Error404 />
      }

    default:
      return <Error404 />
  }
}

export default GetExternalComponent
