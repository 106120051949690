import {FC, useEffect, useState} from 'react'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteEp6DTO
  indexMuestra: number
  numeroMuestra: number
}

const GraficoMuestraReplicados: FC<Props> = ({reporte, indexMuestra, numeroMuestra}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  useEffect(() => {
    const replicados = reporte.Experimento.Datos![indexMuestra].Replicas
    const series = []
    const xValues = replicados.map((replicado) => replicado.Numero)
    const valuesPrecision = replicados.map((replicado) => replicado.Valor.Precision)
    const precision = Math.max(...valuesPrecision)

    // Replicados.
    series.push(
      GetScatterSerie(
        replicados.map(function (replicado, index) {
          return [xValues[index], Number(replicado.Valor.Value)]
        }),
        '#FFF',
        '#4B77BE'
      )
    )

    setOptions({
      title: {
        text: `${intl.formatMessage({id: 'HEADER.SAMPLE'})} ${numeroMuestra}`,
      },
      xAxis: {
        title: {
          text: intl.formatMessage({id: 'HEADER.REPLICATE'}),
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 0)
          },
        },
        tickInterval: 1,
        endOnTick: true,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.MEASURED_VALUE'})} ${numeroMuestra} (${
            reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
          })`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 1)
          },
        },
        lineWidth: 1,
        endOnTick: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '[' + this.x.toFixed(precision) + ' ; ' + this.y?.toFixed(precision) + ']'
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoMuestraReplicados
