import {FC} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {useIntl} from 'react-intl'

interface Props {
  experimento: ExperimentoEp12a2DTO
}

const TablaDatosReporteEp12a2: FC<Props> = ({experimento}) => {
  const intl = useIntl()

  const columnsCount = 3
  const datos = [...experimento.Datos]
  const datosPerColumn = Math.ceil(datos.length / columnsCount)
  const columnas = []
  for (let i = 0; i < columnsCount; i++) {
    const columnDatos = datos.slice(i * datosPerColumn, i * datosPerColumn + datosPerColumn)
    columnas.push(columnDatos)
  }

  return (
    <div className='row mb-10'>
      <h1 className='mb-5'>{intl.formatMessage({id: 'HEADER.DATA'})}</h1>
      {columnas?.map((columna, indexColumna) => {
        return (
          <div className='col-4 table-responsive' key={`tabla_columna_${indexColumna + 1}`}>
            <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
              <thead
                style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
              >
                <tr className='fw-bolder text-center'>
                  <th style={{width: '33%'}}>
                    {intl.formatMessage({id: 'HEADER.IDENTIFICATION'})}
                  </th>
                  <th style={{width: '33%'}}>{intl.formatMessage({id: 'HEADER.REFERENCE'})}</th>
                  <th style={{width: '33%'}}>{intl.formatMessage({id: 'HEADER.EVALUATION'})}</th>
                </tr>
              </thead>
              <tbody>
                {columna.map((fila, indexFila) => {
                  return (
                    <tr
                      key={`tabla_datos_reporte_ep12a2_columna_${indexColumna + 1}_fila_${
                        indexFila + 1
                      }`}
                      className='text-center'
                    >
                      <td>{fila.Identificacion}</td>
                      <td>{fila.Referencia}</td>
                      <td>{fila.Evaluacion}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
    </div>
  )
}

export default TablaDatosReporteEp12a2
