import {FC} from 'react'
import {ReporteVIRDTO} from '../../../models/DTOs/SEM/vir/ReporteVIRDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteVIRDTO
}

const TablaValoresVIRReporte: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='col-6'>
      <h1 className='my-5'>{`${intl.formatMessage({id: 'HEADER.VALUES'})} ${
        reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
      }`}</h1>
      <div className='row'>
        <div className='col-6 table-responsive'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder text-center'>
                <th style={{width: '25%'}}>#</th>
                <th style={{width: '75%'}}>
                  {intl.formatMessage({
                    id: 'HEADER.VALUE',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((numeroFila, indexFila) => {
                return (
                  <tr key={`tabla_valores_vir_fila_${numeroFila}`}>
                    <td className='text-center'>{numeroFila}</td>
                    <td className='text-right'>
                      {toValueWithPrecisionString(reporte.Experimento.Valores[indexFila])}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className='col-6 table-responsive'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder text-center'>
                <th style={{width: '25%'}}>#</th>
                <th style={{width: '75%'}}>{intl.formatMessage({id: 'HEADER.VALUE'})}</th>
              </tr>
            </thead>
            <tbody>
              {[11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((numeroFila, indexFila) => {
                return (
                  <tr key={`tabla_valores_vir_fila_${numeroFila}`}>
                    <td className='text-center'>{numeroFila}</td>
                    <td className='text-right'>
                      {toValueWithPrecisionString(reporte.Experimento.Valores[indexFila + 10])}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TablaValoresVIRReporte
