import {FC} from 'react'
import {ExperimentoEp12a2DTO} from '../../../models/DTOs/SEM/ep12a2/ExperimentoEp12a2DTO'
import {useIntl} from 'react-intl'

interface Props {
  experimento: ExperimentoEp12a2DTO
}

const ReporteExperimentoInfoEp12a2: FC<Props> = ({experimento}) => {
  const intl = useIntl()

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-4'>
            {intl.formatMessage({id: 'HEADER.REFERENCE_METHOD'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.ISCED'})}</label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{experimento.Fecha}</span>
          <span className='text-gray-600 col-4'>{experimento.MetodoReferencia}</span>
          <span className='text-gray-600 col-3'>
            {experimento.IsCriterioExactitudDiagnostica
              ? intl.formatMessage({id: 'SETTINGS.YES'})
              : intl.formatMessage({id: 'SETTINGS.NO'})}
          </span>
          <span className='text-gray-600 col-3'>{experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-6'>
            {intl.formatMessage({id: 'HEADER.EVALUATION_METHOD'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.REAGENT'})}</label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'SETTINGS.CALIPER'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-6'>{`${experimento.ProcedimientoMedida.Equipo.Descripcion} / ${experimento.ProcedimientoMedida.Equipo.Plataforma} | ${experimento.ProcedimientoMedida.Mensurando.Analito} / ${experimento.ProcedimientoMedida.Mensurando.Matriz} / ${experimento.ProcedimientoMedida.Mensurando.Unidades} | ${experimento.ProcedimientoMedida.Metodo.Reactivo} / ${experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{`${experimento.Reactivo.Lote} (${experimento.Reactivo.Vencimiento})`}</span>
          <span className='text-gray-600 col-3'>{`${experimento.Calibrador.Lote} (${experimento.Calibrador.Vencimiento})`}</span>
        </div>
      </div>
    </div>
  )
}

export default ReporteExperimentoInfoEp12a2
