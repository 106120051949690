import {FC} from 'react'
import {ReporteEp15a3DTO} from '../../../models/DTOs/SEM/ep15a3/ReporteEp15a3DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {ReporteEp15a3PrecisionDTO} from '../../../models/DTOs/SEM/ep15a3-precision/ReporteEp15a3PrecisionDTO'

interface Props {
  reporte: ReporteEp15a3DTO | ReporteEp15a3PrecisionDTO
  indexNivel: number
}

const TablaCorridasReporte: FC<Props> = ({reporte, indexNivel}) => {
  const intl = useIntl()

  const isOutlier = (nivelIndex: number, corridaIndex: number, repeticionIndex: number) => {
    const corrida = corridaIndex + 1
    const repeticion = repeticionIndex + 1

    return reporte?.Niveles[nivelIndex].Precision.Outliers.some(
      (o) => o.Corrida === corrida && o.Repeticion === repeticion
    )
  }

  return (
    <div className='mb-10 table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center'>
            <th style={{width: '14%'}}>{intl.formatMessage({id: 'HEADER.RUN'})}</th>
            <th style={{width: '16%'}}>{intl.formatMessage({id: 'HEADER.DATE'})}</th>
            <th style={{width: '14%'}}>Rep. 1</th>
            <th style={{width: '14%'}}>Rep. 2</th>
            <th style={{width: '14%'}}>Rep. 3</th>
            <th style={{width: '14%'}}>Rep. 4</th>
            <th style={{width: '14%'}}>Rep. 5</th>
          </tr>
        </thead>
        <tbody>
          {reporte.Experimento.Niveles[indexNivel].Precision.Corridas.map(
            (corrida, corridaIndex) => {
              return (
                <tr key={`corrida-numero_${corrida.Numero}`}>
                  <td className='text-center'>{corrida.Numero}</td>
                  <td className='text-center'>{corrida.Fecha}</td>
                  {corrida.Repeticiones.map((repeticion, repeticionIndex) => {
                    return (
                      <td
                        key={`nivel-${reporte.Experimento.Niveles[indexNivel].Numero}_corrida-${
                          corrida.Numero
                        }_repeticion-${repeticionIndex + 1}`}
                        className={`text-right ${
                          isOutlier(indexNivel, corridaIndex, repeticionIndex) && 'text-danger'
                        }`}
                      >
                        {toValueWithPrecisionString(repeticion)}
                      </td>
                    )
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TablaCorridasReporte
