import {FC} from 'react'
import {ReporteLoQDTO} from '../../../models/DTOs/SEM/loq/ReporteLoQDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteLoQDTO
}

const TablaAnalisisReporteLoQ: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <>
      <h1 className='mb-5'>{intl.formatMessage({id: 'HEADER.DATA_ANALYSIS'})}</h1>
      <div className='table-responsive mb-10 col-8'>
        <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
          <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
            <tr className='fw-bolder text-center'>
              <td style={{width: '50%'}}></td>
              <td style={{width: '25%'}}>{`${intl.formatMessage({id: 'HEADER.SAMPLE'})} 1`}</td>
              <td style={{width: '25%'}}>{`${intl.formatMessage({id: 'HEADER.SAMPLE'})} 2`}</td>
            </tr>
          </thead>
          <tbody>
            <tr className='text-right'>
              <td className='text-center'>{intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})}</td>
              <td>{toValueWithPrecisionString(reporte.Experimento.ValorAsignadoMuestra1)}</td>
              <td>{toValueWithPrecisionString(reporte.Experimento.ValorAsignadoMuestra2)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>ETa cc</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.ETaConcentration.Muestra1)}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.ETaConcentration.Muestra2)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>{intl.formatMessage({id: 'HEADER.UPPER_LIMIT'})}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.LimiteSuperior.Muestra1)}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.LimiteSuperior.Muestra2)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>{intl.formatMessage({id: 'HEADER.LOWER_LIMIT'})}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.LimiteInferior.Muestra1)}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.LimiteInferior.Muestra2)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>
                {intl.formatMessage({id: 'HEADER.ACCEPTED_VALUES_AMOUNT'})}
              </td>
              <td>{toValueWithPrecisionString(reporte.Analisis.Aceptados.Muestra1)}</td>
              <td>{toValueWithPrecisionString(reporte.Analisis.Aceptados.Muestra2)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>{intl.formatMessage({id: 'HEADER.ACCEPTED_VALUES'})}</td>
              <td colSpan={2}>{reporte.Analisis.CantDatos}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>
                {intl.formatMessage({id: 'HEADER.ACCEPTED_VALUES_PERCENT'})}
              </td>
              <td colSpan={2}>{toValueWithPrecisionString(reporte.Analisis.AceptadosPercent)}</td>
            </tr>
            <tr className='text-right'>
              <td className='text-center'>
                {intl.formatMessage({id: 'HEADER.ACCEPTED_VALUES_PERCENT_EP17A2'})}
              </td>
              <td colSpan={2}>
                {toValueWithPrecisionString(reporte.Analisis.AceptadosPercentTableEP17A2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TablaAnalisisReporteLoQ
