import {FC} from 'react'
import {ExperimentoUDTO} from '../../../models/DTOs/SEM/u/ExperimentoUDTO'
import {ReporteUDTO} from '../../../models/DTOs/SEM/u/ReporteUDTO'
import {useIntl} from 'react-intl'
import {convertToDecimal, toValueWithPrecisionString} from '../../../helpers'
import moment from 'moment'

interface Props {
  experimento: ExperimentoUDTO
  reporte: ReporteUDTO
}

const ReporteNDMU: FC<Props> = ({experimento, reporte}) => {
  const intl = useIntl()

  const tableConfig = (formato: string) => {
    if (formato === 'mensual') {
      return {
        table: 'w-75',
        periodo: '18%',
        th: '14%',
      }
    } else {
      return {
        table: 'w-100',
        periodo: '34%',
        th: '10%',
      }
    }
  }

  return (
    <>
      {experimento.NDMs.map((nivel, indexnivel) => {
        return (
          <div
            key={`nivel_${nivel.Numero}_ndm`}
            className='card mb-5'
            style={{border: '1px ridge'}}
          >
            <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
              <div className='card-title align-items-start col-7'>{`${intl.formatMessage({
                id: 'HEADER.LEVEL',
              })} ${nivel.Numero} (${intl.formatMessage({
                id: 'HEADER.NDM',
              })})`}</div>
            </div>
            <div className='card-body py-8'>
              <h4>
                u<sub>RW</sub>
              </h4>
              <div className='mb-8'></div>
              <div className='row'>
                <label className='col-1 form-label'>{intl.formatMessage({id: 'MENU.UNITS'})}</label>
                <label className='col-2 form-label'>
                  {intl.formatMessage({id: 'HEADER.MEAN'})}
                </label>
                <label className='col-2 form-label'>
                  {intl.formatMessage({id: 'HEADER.STANDARD_DEVIATION'})}
                </label>
                <label className='col-2 form-label'>%CV</label>
                <label className='col-2 form-label'>
                  {intl.formatMessage({id: 'HEADER.POINTS_AMOUNT'})}
                </label>
              </div>
              <div className='row'>
                <span className='col-1 text-gray-600'>
                  {experimento.ProcedimientoMedida.Mensurando.Unidades}
                </span>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(nivel.uRW.Mean)}
                </span>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(nivel.uRW.StdDev)}
                </span>
                <span className='col-2 text-gray-600'>
                  {toValueWithPrecisionString(nivel.uRW.Cv)}
                </span>
                <span className='col-2 text-gray-600'>{nivel.uRW.N}</span>
              </div>
              {nivel.uSesgo && (
                <>
                  <h4 className='my-8'>
                    u<sub>sesgo</sub>
                  </h4>
                  <div className='table-responsive mb-8'>
                    <table
                      className={`table table-bordered table-row-bordered align-middle gs-3 gy-3 ${
                        tableConfig(nivel.uSesgo.FormatoPeriodo).table
                      }`}
                    >
                      <thead
                        style={{
                          backgroundColor: '#f5f8ff',
                          verticalAlign: 'middle',
                          textAlign: 'center',
                        }}
                      >
                        <tr className='fw-bolder text-center' style={{verticalAlign: 'middle'}}>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {intl.formatMessage({id: 'HEADER.SURVEY'})}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).periodo}}>
                            {intl.formatMessage({id: 'HEADER.PERIOD'})}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {experimento.EQA ? (
                              intl.formatMessage({id: 'HEADER.INFORMED_VALUE'})
                            ) : (
                              <>
                                {intl.formatMessage({id: 'HEADER.MEAN'})}
                                <sub>Lab</sub>
                              </>
                            )}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {experimento.EQA ? (
                              <span>
                                X<sub>PT</sub>
                              </span>
                            ) : (
                              <>
                                {intl.formatMessage({id: 'HEADER.MEAN'})}
                                <sub>Grupo</sub>
                              </>
                            )}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {experimento.EQA ? (
                              <span>%CV</span>
                            ) : (
                              <span>
                                %CV<sub>Grupo</sub>
                              </span>
                            )}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {experimento.EQA ? (
                              <span>
                                σ<sub>PT</sub>
                              </span>
                            ) : (
                              <span>
                                DE<sub>Grupo</sub>
                              </span>
                            )}
                          </th>
                          <th style={{width: tableConfig(nivel.uSesgo.FormatoPeriodo).th}}>
                            {experimento.EQA ? (
                              <span>p</span>
                            ) : (
                              <span>
                                n<sub>Grupo</sub>
                              </span>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {nivel.uSesgo.Encuestas.map((encuesta) => {
                          return (
                            <tr key={`nivel_${nivel.Numero}_encuesta_${encuesta.Numero}_u`}>
                              <td className='text-center'>{encuesta.Numero}</td>
                              <td className='text-center'>
                                {nivel.uSesgo?.FormatoPeriodo === 'mensual'
                                  ? moment(encuesta.Periodo.Desde, 'DD/MM/YYYY').format('MM/YYYY')
                                  : `${encuesta.Periodo.Desde} - ${encuesta.Periodo.Hasta}`}
                              </td>
                              <td className='text-right'>
                                {toValueWithPrecisionString(encuesta.MeanLab)}
                              </td>
                              <td className='text-right'>
                                {toValueWithPrecisionString(encuesta.MeanGrupo)}
                              </td>
                              <td className='text-right'>
                                {toValueWithPrecisionString(encuesta.CvGrupo)}
                              </td>
                              <td className='text-right'>
                                {toValueWithPrecisionString(encuesta.StdDevGrupo)}
                              </td>
                              <td className='text-right'>{encuesta.NGrupo}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {(experimento.EQA || experimento.EsquemaInterlab) && (
                <div className='mb-8'>
                  <div className='row'>
                    <label className='form-label col-2'>
                      u<sub>sesgo</sub>
                    </label>
                    <label className='form-label col-2'>
                      % u<sub>sesgo</sub>
                    </label>
                  </div>
                  <div className='row'>
                    <span className='text-gray-600 col-2'>
                      {`${convertToDecimal(
                        reporte.ResumenNiveles[indexnivel].uSesgo.Concentration,
                        reporte.ResumenNiveles[indexnivel].ValorAsignado.Precision + 2
                      )} (${experimento.ProcedimientoMedida.Mensurando.Unidades})`}
                    </span>
                    <span className='text-gray-600 col-2'>
                      {convertToDecimal(reporte.ResumenNiveles[indexnivel].uSesgo.Percent, 1)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ReporteNDMU
