import {FC} from 'react'
import {ReporteEp6DTO} from '../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import BulletComponent from '../../components/UI/BulletComponent'
import {AlertSuccess, AlertDanger} from '../../components/UI/alerts/Alerts'
import GraficoDispersionValorAsignadoValorMedido from '../../components/ep6/reporte/GraficoDispersionValorAsignadoValorMedido'
import GraficoDispersionValorPredichoDesviacion from '../../components/ep6/reporte/GraficoDispersionValorPredichoDesviacion'
import GraficoMuestraReplicados from '../../components/ep6/reporte/GraficoMuestraReplicados'
import GraficosDispersion from '../../components/ep6/reporte/GraficosDispersion'
import ReporteEp6ExperimentoInfo from '../../components/ep6/reporte/ReporteEp6ExperimentoInfo'
import TablaAnalisisDatos from '../../components/ep6/reporte/TablaAnalisisDatos'
import TablaReplicadosReporteEp6 from '../../components/ep6/reporte/TablaReplicadosReporteEp6'

const ReporteEp6: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteEp6DTO = dataDecoded.Reporte

  const ConclusionAnalisisMuestraTypes = [
    {
      key: 'validado',
      value: intl.formatMessage({id: 'CONCLUSION_ANALYSIS.VALIDATED'}),
    },
    {
      key: 'no-validado',
      value: intl.formatMessage({id: 'CONCLUSION_ANALYSIS.Not_VALIDATED'}),
    },
    {
      key: 'verificado',
      value: intl.formatMessage({id: 'CONCLUSION_ANALYSIS.VERIFIED'}),
    },
    {
      key: 'no-verificado',
      value: intl.formatMessage({id: 'CONCLUSION_ANALYSIS.NOT_VERIFIED'}),
    },
  ]

  const getConclusionAnalisis = (name: string) =>
    ConclusionAnalisisMuestraTypes.find((item) => item.key === name)

  const formatConclusionAnalisisDatos = (informe: ReporteEp6DTO) => {
    const conclusion = getConclusionAnalisis(informe.AnalisisDatos.Conclusion.Name)!.value
    const valuesPrecision = informe.AnalisisDatos.Muestras.map(
      (muestra) => muestra.ValorAsignado!.Precision
    )
    const precision = Math.max(...valuesPrecision)
    const valoresAsignados = informe.AnalisisDatos.Muestras.map(
      (muestra) => muestra.ValorAsignado!.Value
    )
    const valorAsignadoLower = Math.min(...valoresAsignados)
    const valorAsignadoHigher = Math.max(...valoresAsignados)

    return conclusion
      .replace('$VALOR_ASIGNADO_LOWER', valorAsignadoLower.toFixed(precision))
      .replace('$VALOR_ASIGNADO_HIGHER', valorAsignadoHigher.toFixed(precision))
      .replaceAll('$PROC_MED_UNIDADES', informe.Experimento.ProcedimientoMedida.Mensurando.Unidades)
  }

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReporteEp6ExperimentoInfo reporte={reporte} />
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaReplicadosReporteEp6 reporte={reporte} />
        </div>
        <div className='mb-10' style={{pageBreakInside: 'avoid'}}>
          <div className='row'>
            <label className='col form-label'>{intl.formatMessage({id: 'HEADER.COMMENTS'})}</label>
          </div>
          <div className='row'>
            <span className='col text-gray-600'>{reporte.Experimento.Comentarios || '-'}</span>
          </div>
        </div>
        <h1 className='my-10'>{intl.formatMessage({id: 'HEADER.SCATTER_PLOTS'})}</h1>
        <GraficosDispersion reporte={reporte} />
        {reporte.Experimento.TipoEvaluacion === 'validacion' && (
          <div className='d-flex flex-row justify-content-start grow-0 flex-wrap'>
            {reporte.Experimento.Muestras?.map((muestra, indexMuestra) => {
              return (
                <div
                  key={`grafico_muestra_replicados_${muestra.Muestra}`}
                  style={{flexBasis: '33%', pageBreakInside: 'avoid'}}
                >
                  <GraficoMuestraReplicados
                    reporte={reporte}
                    indexMuestra={indexMuestra}
                    numeroMuestra={muestra.Muestra}
                  />
                </div>
              )
            })}
          </div>
        )}
        <h1 className='my-10'>{intl.formatMessage({id: 'HEADER.DATA_ANALYSIS'})}</h1>
        <div style={{pageBreakInside: 'avoid'}}>
          <TablaAnalisisDatos reporte={reporte} />
        </div>
        <GraficoDispersionValorAsignadoValorMedido reporte={reporte} />
        <GraficoDispersionValorPredichoDesviacion reporte={reporte} />
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            pageBreakInside: 'avoid',
          }}
          className='mb-10'
        >
          {reporte.Experimento.TipoEvaluacion === 'verificacion' && (
            <BulletComponent text='ESa' color='#E08283' />
          )}
          {reporte.Experimento.TipoEvaluacion === 'validacion' && (
            <BulletComponent text='ESa %' color='#E08283' />
          )}
          {reporte.Experimento.TipoEvaluacion === 'verificacion' && (
            <BulletComponent text='IC 90%' color='#26C281' />
          )}
        </div>
        {['validado', 'verificado'].includes(reporte.AnalisisDatos.Conclusion.Name) ? (
          <AlertSuccess>{formatConclusionAnalisisDatos(reporte)}</AlertSuccess>
        ) : (
          <AlertDanger>{formatConclusionAnalisisDatos(reporte)}</AlertDanger>
        )}
      </div>
    </>
  )
}

export default ReporteEp6
