import {FC} from 'react'
import {convertToDecimal} from '../../../helpers'
import {AlertWarning} from '../../UI/alerts/Alerts'
import TablasModelosAnalisisRegresion from './tablas/TablasModelosAnalisisRegresionEqapt'
import {useIntl} from 'react-intl'
import TablaAnalisisRegresionNDM from './tablas/TablaAnalisisRegresionNDMEqapt'
import GraficoDispersionAnalisisRegresion from './graficos/GraficoDispersionAnalisisRegresionEqapt'
import GraficosNDMAnalisisRegresion from './graficos/GraficosNDMAnalisisRegresionEqapt'
import {ReporteCompMetodosEqaptDTO} from '../../../models/DTOs/SEM/comp-metodos-eqapt/ReporteCompMetodosEqaptDTO'

interface Props {
  reporte: ReporteCompMetodosEqaptDTO
}

const ReporteAnalisisRegresionEqapt: FC<Props> = ({reporte}) => {
  const intl = useIntl()
  const regresion = reporte.RegressionAnalisis
  return (
    <>
      <div style={{pageBreakInside: 'avoid'}}>
        <h1>{intl.formatMessage({id: 'HEADER.REGRESSION_ANALYSIS'})}</h1>
        <div className='table-responsive mb-10' style={{margin: '0 auto', width: '200px'}}>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead>
              <tr>
                <th
                  className='text-center fw-bold'
                  style={{backgroundColor: '#f5f8ff', width: '50%'}}
                >
                  r
                </th>
                <th className='text-right'>{convertToDecimal(regresion.BiasModel.r, 3)}</th>
              </tr>
            </thead>
          </table>
        </div>
        {regresion.rAlarmLow && (
          <div className='col-7 mb-10' style={{margin: '0 auto'}}>
            <AlertWarning>
              {intl
                .formatMessage({id: 'WARNING.R_LOWER'})
                .replace('{value}', regresion.rThreshold.toString())}
            </AlertWarning>
          </div>
        )}
        <TablasModelosAnalisisRegresion reporte={reporte} />
        <TablaAnalisisRegresionNDM reporte={reporte} />
      </div>
      <div className='mb-10' style={{margin: '0 auto', width: '500px'}}>
        <GraficoDispersionAnalisisRegresion reporte={reporte} />
      </div>
      <GraficosNDMAnalisisRegresion reporte={reporte} />
    </>
  )
}

export default ReporteAnalisisRegresionEqapt
