import {FC} from 'react'
import {ReportePrecisionSimpleDTO} from '../../../models/DTOs/SEM/precision-simple/ReportePrecisionSimpleDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {ValueWithPrecisionDTO} from '../../../models/DTOs/common/ValueWithPrecisionDTO'

interface Props {
  nivel: {
    Numero: number
    CVFabPercent?: ValueWithPrecisionDTO
    Resultados: ValueWithPrecisionDTO[]
  }
}

const TablaValoresReportePrecisionSimple: FC<Props> = ({nivel}) => {
  const intl = useIntl()

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center'>
            <th style={{width: '30%'}}>#</th>
            <th style={{width: '70%'}}>{intl.formatMessage({id: 'HEADER.VALUE'})}</th>
          </tr>
        </thead>
        <tbody>
          {nivel.Resultados.map((resultado, filaIndex: number) => {
            return (
              <tr key={`nivel_${nivel.Numero}_fila_${filaIndex + 1}`}>
                <td className='text-center'>{filaIndex + 1}</td>
                <td className='text-right'>{toValueWithPrecisionString(resultado)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaValoresReportePrecisionSimple
