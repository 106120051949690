import {FC, useEffect, useState} from 'react'
import {ReporteEp6DTO} from '../../../models/DTOs/SEM/ep6/ReporteEp6DTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReporteEp6DTO
}

const GraficoDispersionValorPredichoDesviacion: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  function GetLineSerie(data: any, color: string): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: 'Solid',
      type: 'line',
    }
  }

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  useEffect(() => {
    const unidades = reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
    const series = []
    const precisionValues = reporte.AnalisisDatos.Muestras.map(
      (muestra) => muestra.ValorAsignado!.Precision
    )
    const precision = Math.max(...precisionValues)

    series.push(
      GetScatterSerie(
        reporte.AnalisisDatos.Muestras.map(function (muestra, index) {
          return [
            muestra.ValorPredicho!.Value,
            reporte.Experimento.TipoEvaluacion === 'validacion'
              ? muestra.StdDev?.Percent.Value
              : muestra.StdDev?.Concentration.Value,
          ]
        }),
        '#FFF',
        '#4B77BE'
      )
    )

    // ESa.
    series.push(
      GetLineSerie(
        reporte.AnalisisDatos.Muestras.map(function (muestra) {
          return [
            muestra.ValorPredicho?.Value,
            reporte.Experimento.TipoEvaluacion === 'validacion'
              ? muestra.ESa!.Percent.Value
              : muestra.ESa!.Concentration.Value,
          ]
        }),
        '#E08283'
      )
    )
    series.push(
      GetLineSerie(
        reporte.AnalisisDatos.Muestras.map(function (muestra) {
          return [
            muestra.ValorPredicho?.Value,
            -(reporte.Experimento.TipoEvaluacion === 'validacion'
              ? muestra.ESa!.Percent.Value
              : muestra.ESa!.Concentration.Value),
          ]
        }),
        '#E08283'
      )
    )

    // IC 90%.
    if (reporte.Experimento.TipoEvaluacion === 'verificacion') {
      reporte.AnalisisDatos.Muestras.forEach((muestra) => {
        series.push(
          GetLineSerie(
            [
              [muestra.ValorPredicho?.Value, muestra.IC90Percent?.Lower.Value],
              [muestra.ValorPredicho?.Value, muestra.IC90Percent?.Higher.Value],
            ],
            '#26C281'
          )
        )
      })
    }

    setOptions({
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})} (${unidades})`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, precision)
          },
        },
        endOnTick: true,
      },
      yAxis: {
        title: {
          text:
            reporte.Experimento.TipoEvaluacion === 'validacion'
              ? `${intl.formatMessage({id: 'HEADER.DEVIATION'})} (%)`
              : `${intl.formatMessage({id: 'HEADER.MEASURED_VALUE'})} (${unidades})`,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, 1)
          },
        },
        lineWidth: 1,
        endOnTick: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '[' + this.x.toFixed(precision) + ' ; ' + this.y?.toFixed(precision) + ']'
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoDispersionValorPredichoDesviacion
