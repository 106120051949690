import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import ReporteAnalisisRegresion from '../../components/comp-metodos/reporte/ReporteAnalisisRegresion'
import ReporteCompMetodosExperimentoInfo from '../../components/comp-metodos/reporte/ReporteCompMetodosExperimentoInfo'
import ReporteMetodoCandidatoInfo from '../../components/comp-metodos/reporte/ReporteMetodoCandidatoInfo'
import ReporteMetodoComparadorInfo from '../../components/comp-metodos/reporte/ReporteMetodoComparadorInfo'
import ReporteRenderGraficosAnalisisCompMetodos from '../../components/comp-metodos/reporte/ReporteRenderGraficosAnalisisCompMetodos'
import GraficoDispersionCompMetodos from '../../components/comp-metodos/reporte/graficos/GraficoDispersionCompMetodos'
import ReporteCalculosCompMetodos from '../../components/comp-metodos/reporte/tablas/ReporteCalculosCompMetedos'
import {toValueWithPrecisionString} from '../../helpers'

const ReporteCompMetodos: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteCompMetodosDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  const columnsCount = 3
  const datos = reporte?.Experimento.Datos ? [...reporte.Experimento.Datos] : []
  const datosPerColumn = Math.ceil(datos.length / columnsCount)
  const columnas = []
  for (let i = 0; i < columnsCount; i++) {
    const columnDatos = datos.slice(i * datosPerColumn, i * datosPerColumn + datosPerColumn)
    columnas.push(columnDatos)
  }
  const checkeado = reporte.Experimento.Datos.map((dato) => dato.IsExcluido)

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <ReporteCompMetodosExperimentoInfo reporte={reporte} />
        <div className='row mb-10' style={{pageBreakInside: 'avoid'}}>
          <div className='col-6'>
            <ReporteMetodoComparadorInfo reporte={reporte} />
          </div>
          <div className='col-6'>
            <ReporteMetodoCandidatoInfo reporte={reporte} />
          </div>
        </div>
        <div className='row mb-10' style={{pageBreakInside: 'avoid'}}>
          <h1 className='mb-5'>{intl.formatMessage({id: 'HEADER.DATA'})}</h1>
          {columnas?.map((columna, indexColumna) => {
            return (
              <div className='col-4 table-responsive' key={`tabla_columna_${indexColumna + 1}`}>
                <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                  <thead
                    style={{
                      backgroundColor: '#f5f8ff',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    }}
                  >
                    <tr className='fw-bolder text-center'>
                      <th style={{width: '10%'}}>#</th>
                      <th style={{width: '45%'}}>
                        {`${intl.formatMessage({
                          id: 'HEADER.COMPARATOR_METHOD',
                        })} (X)`}
                      </th>
                      <th style={{width: '45%'}}>
                        {`${intl.formatMessage({
                          id: 'HEADER.CANDIDATE_METHOD',
                        })} (Y)`}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {columna.map((fila, indexFila) => {
                      return (
                        <tr
                          key={`tabla_datos_reporte_comp_metodos_columna_${indexColumna + 1}_fila_${
                            indexFila + 1
                          }`}
                          className={`${checkeado[fila.Numero - 1] && 'text-danger'}`}
                        >
                          <td className='text-center'>{fila.Numero}</td>
                          <td className='text-right'>
                            {toValueWithPrecisionString(fila.MetodoComparador)}
                          </td>
                          <td className='text-right'>
                            {toValueWithPrecisionString(fila.MetodoCandidato)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>
        <div style={{width: '500px', margin: '0 auto', pageBreakInside: 'avoid'}}>
          <GraficoDispersionCompMetodos reporte={reporte} />
        </div>
        <div style={{pageBreakInside: 'avoid'}}>
          <ReporteCalculosCompMetodos reporte={reporte} />
        </div>
        <ReporteRenderGraficosAnalisisCompMetodos reporte={reporte} />
        <ReporteAnalisisRegresion reporte={reporte} />
      </div>
    </>
  )
}

export default ReporteCompMetodos
