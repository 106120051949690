import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {convertToDecimal} from '../../../../helpers'
more(Highcharts)

interface Props {
  info: any
}

const RenderAnalisisChart: FC<Props> = ({info}) => {
  const [options, setOptions] = useState<Highcharts.Options>({})

  function GetNumberFormatter(value: number, decimalPlaces: number) {
    return Highcharts.numberFormat(value, decimalPlaces)
  }

  function GetLineSerie(data: any, color: string): Highcharts.SeriesOptionsType {
    const _data = data.map(function (v: any) {
      return {
        x: v[0],
        y: v[1],
      }
    })

    return {
      color: color,
      data: _data,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      dashStyle: 'ShortDot',
      type: 'line',
    }
  }

  function GetScatterSerie(
    data: any,
    fillColor?: string,
    lineColor?: string,
    lineWidth?: number
  ): Highcharts.SeriesOptionsType {
    return {
      type: 'scatter',
      data: data,

      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor ?? '#E86A04',
        fillColor: fillColor ?? '#FFF',
        lineWidth: lineWidth ?? 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  const GetPlotBand = (
    from: number,
    to: number,
    color: string
  ): Highcharts.YAxisPlotBandsOptions => {
    return {
      from: from,
      to: to,
      color: color,
    }
  }

  useEffect(() => {
    const series: Array<Highcharts.SeriesOptionsType> = []
    const plotBands: Array<Highcharts.NavigatorYAxisPlotBandsOptions> = []

    // Plot bands.
    info.axis.y.plotBands.forEach((plotBand: any) => {
      plotBands.push(GetPlotBand(plotBand.min, plotBand.max, plotBand.background))
    })

    // Plot lines.
    info.axis.y.plotLines.forEach((plotLine: any) => {
      series.push(
        GetLineSerie(
          [
            [info.axis.x.range.min, plotLine.value],
            [info.axis.x.range.max, plotLine.value],
          ],
          plotLine.color
        )
      )
    })

    // Custom lines.
    info.lines.forEach((line: any) => {
      series.push(
        GetLineSerie(
          line.values.map((value: any) => [value.x, value.y]),
          line.color
        )
      )
    })

    // Values.
    series.push(
      GetScatterSerie(
        info.values.map(function (v: any) {
          return [v.x, v.y]
        })
      )
    )

    setOptions({
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: info.axis.x.title,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, info.precision)
          },
        },
      },
      yAxis: {
        title: {
          text: info.axis.y.title,
        },
        labels: {
          formatter: function () {
            return GetNumberFormatter(+this.value, info.precision)
          },
        },
        min: info.axis.y.range.min,
        max: info.axis.y.range.max,
        tickInterval: info.axis.y.tickInterval,
        endOnTick: false,
        startOnTick: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        plotBands: plotBands,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return (
            '[' +
            convertToDecimal(this.x, info.precision) +
            ' ; ' +
            convertToDecimal(this.y!, info.precision) +
            ']'
          )
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [info])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default RenderAnalisisChart
