import {FC} from 'react'
import {ReporteEp15a3DTO} from '../../../models/DTOs/SEM/ep15a3/ReporteEp15a3DTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import {ValueWithPrecisionDTO} from '../../../models/DTOs/common/ValueWithPrecisionDTO'

interface Props {
  reporte: ReporteEp15a3DTO
}

const TablaDesempenioReporte: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const informesDesempenio = reporte.Niveles.map((nivel) => nivel.Desempenio)

  const getDesempenioWithStatusCell = (value?: ValueWithPrecisionDTO, status?: boolean) => {
    return (
      <td className={`text-right text-${status ? 'success' : 'danger'}`}>
        <i
          className={`fa fa-${status ? 'check' : 'xmark'}  text-${status ? 'success' : 'danger'}`}
        ></i>{' '}
        {toValueWithPrecisionString(value)}
      </td>
    )
  }

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder text-center align-middle'>
            <th style={{width: '10%'}}>
              {intl.formatMessage({id: 'HEADER.MEDICAL_DECISION_LEVEL'})}
            </th>
            <th style={{width: '9%'}}>{intl.formatMessage({id: 'HEADER.CONCENTRATION'})}</th>
            <th style={{width: '9%'}}>{intl.formatMessage({id: 'MENU.UNITS'})}</th>
            <th style={{width: '9%'}}>% ETa</th>
            <th style={{width: '9%'}}>
              % CV<sub>WL</sub>
            </th>
            <th style={{width: '9%'}}>|% Sesgo|</th>
            <th style={{width: '9%'}}>% ET</th>
            <th style={{width: '9%'}}>Sigma</th>
            <th style={{width: '9%'}}>ESc</th>
            <th style={{width: '9%'}}>% ESa</th>
            <th style={{width: '9%'}}>% EAa</th>
          </tr>
        </thead>
        <tbody>
          {informesDesempenio.map((nivel, index) => {
            return (
              <tr key={`tabla_desempenio_reporte_nivel_${index + 1}`}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.Concentracion)}</td>
                <td className='text-center'>{nivel.Unidades}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.ETaPercent)}</td>
                {getDesempenioWithStatusCell(nivel.CVwl, nivel.IsEAaOk)}
                {nivel.IsAvailable ? (
                  <>
                    {getDesempenioWithStatusCell(nivel.Sesgo, nivel.IsESaOk)}
                    <td className='text-right'>{toValueWithPrecisionString(nivel.ET)}</td>
                    <td className='text-right'>{toValueWithPrecisionString(nivel.Sigma)}</td>
                    <td className='text-right'>{toValueWithPrecisionString(nivel.ESc)}</td>
                  </>
                ) : (
                  <td className='text-center text-danger' colSpan={4}>
                    {intl.formatMessage({id: 'WARNING.CALCULATE_PERFORMANCE'})}
                  </td>
                )}
                <td className='text-right'>{toValueWithPrecisionString(nivel.ESaPercent)}</td>
                <td className='text-right'>{toValueWithPrecisionString(nivel.EAaPercent)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaDesempenioReporte
