import {FC, useEffect, useState} from 'react'
import {
  ReportePrecisionSimpleDTO,
  ReportePrecisionSimpleNivelDTO,
} from '../../../models/DTOs/SEM/precision-simple/ReportePrecisionSimpleDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: ReportePrecisionSimpleDTO
  nivel: ReportePrecisionSimpleNivelDTO
}

const GraficoPrecisionSimpleNivel: FC<Props> = ({reporte, nivel}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  useEffect(() => {
    const isEAa = reporte.Experimento.EspecificacionesPrecision === 'eaa'
    const limit = nivel.CvFabricante.Value
    const max = Math.max(Number(nivel.Cv.Value), Number(limit))
    const data = [[' ', nivel.Cv.Value]]

    setOptions({
      chart: {
        type: 'column',
      },
      title: {
        text: `Nivel ${nivel.Numero}`,
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {},
      },
      yAxis: {
        max: max - (0 - max) * 0.05,
        min: 0,
        title: {
          text: 'CV %',
        },
        plotLines: [
          {
            value: limit,
            color: 'red',
            dashStyle: 'ShortDash',
            width: 2,
            label: {
              text: isEAa ? 'EAa' : 'CV (fabricante)',
            },
          },
        ],
      },
      legend: {
        enabled: false,
      },
      credits: {enabled: false},
      series: [
        {
          type: 'column',
          data: data,
          animation: false,
          color: nivel.IsEstadoPrecisionOk ? '#5C9BD1' : '#FF0000',
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '13px',
              fontFamily: 'Poppins, Helvetica, "sans-serif"',
            },
          },
        },
      ],
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoPrecisionSimpleNivel
