import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ReporteLoQDTO} from '../../../models/DTOs/SEM/loq/ReporteLoQDTO'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteLoQDTO
}

const TablasMuestrasReporteLoQ: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <>
      <h1 className='mb-5'>{`${intl.formatMessage({id: 'HEADER.VALUES'})} (${
        reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
      })`}</h1>
      <div className='row mb-10'>
        {reporte.Experimento.FechasContainer.map((fecha, index) => {
          return (
            <div key={`tabla_valores_loq_${index + 1}`} className='col-4 table-responsive'>
              <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                <thead
                  style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
                >
                  <tr className='fw-bolder text-center'>
                    <th colSpan={2} className='align-middle'>
                      {fecha.Fecha}
                    </th>
                  </tr>
                  <tr className='fw-bolder text-center'>
                    <th style={{width: '50%'}}>{`${intl.formatMessage({
                      id: 'HEADER.SAMPLE',
                    })} 1`}</th>
                    <th style={{width: '50%'}}>{`${intl.formatMessage({
                      id: 'HEADER.SAMPLE',
                    })} 2`}</th>
                  </tr>
                </thead>
                <tbody>
                  {fecha.Valores.map((valor, indexValor) => {
                    return (
                      <tr
                        key={`tabla_valores_loq_${index + 1}_fila_${indexValor}`}
                        className='text-right'
                      >
                        <td>{toValueWithPrecisionString(valor.Muestra1)}</td>
                        <td>{toValueWithPrecisionString(valor.Muestra2)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TablasMuestrasReporteLoQ
