import {FC} from 'react'
import {ReporteEp15a3PrecisionDTO} from '../../models/DTOs/SEM/ep15a3-precision/ReporteEp15a3PrecisionDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import {AlertWarning, AlertDanger} from '../../components/UI/alerts/Alerts'
import TablaDesempenioPrecisionReporte from '../../components/ep15a3-precision/reporte/TablaDesempenioPrecisionReporte'
import TablaResumenPrecisionReporte from '../../components/ep15a3-precision/reporte/TablaResumenPrecisionReporte'
import TablaCorridasReporte from '../../components/ep15a3/reporte/TablaCorridasReporte'
import VerificacionPrecision from '../../components/ep15a3/reporte/VerificacionPrecision'
import VerificacionPrecisionWithoutOutliers from '../../components/ep15a3/reporte/VerificacionPrecisionWithoutOutliers'
import {toValueWithPrecisionString} from '../../helpers'

const ReporteEp15a3Precision: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: ReporteEp15a3PrecisionDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='mb-10'>
          <div className='row'>
            <span className='col-2 form-label'>
              {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
            </span>
            <span className='col-5 form-label'>
              {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
            </span>
            <span className='col-3 form-label'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</span>
          </div>
          <div className='row'>
            <span className='col-2 text-gray-600'>{reporte.Experimento.Fecha}</span>
            <span className='col-5 text-gray-600'>{`${reporte.Experimento.ProcedimientoMedida.Equipo.Descripcion} / ${reporte.Experimento.ProcedimientoMedida.Equipo.Plataforma} | ${reporte.Experimento.ProcedimientoMedida.Mensurando.Analito} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Matriz} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades} | ${reporte.Experimento.ProcedimientoMedida.Metodo.Reactivo} / ${reporte.Experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
            <span className='col-3 text-gray-600'>{reporte.Experimento.Usuario}</span>
          </div>
        </div>
        {/* PRECISION */}
        {reporte.Experimento.Niveles.map((nivel, nivelIndex) => {
          return (
            <div
              className='card mb-5'
              style={{border: '1px ridge', pageBreakInside: 'avoid'}}
              key={`desplegable_precision_nivel_${nivel.Numero}`}
            >
              <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
                <div className='card-title align-items-start'>
                  <span className='fw-bold fs-2'>{`${intl.formatMessage({
                    id: 'HEADER.PRECISION_REPORT',
                  })} - ${intl.formatMessage({id: 'HEADER.LEVEL'})} ${nivel.Numero}`}</span>
                </div>
              </div>
              <div className='card-body py-8'>
                <div className='mb-10'>
                  <div className='row'>
                    <span className='col-3 form-label'>
                      {intl.formatMessage({id: 'HEADER.REAGENT'})}
                    </span>
                    <span className='col-3 form-label'>
                      {intl.formatMessage({id: 'SETTINGS.CALIPER'})}
                    </span>
                    <span className='col-3 form-label'>
                      {intl.formatMessage({id: 'HEADER.MATERIAL'})}
                    </span>
                  </div>
                  <div className='row'>
                    <span className='col-3 text-gray-600'>{`${nivel.Precision.Reactivo.Lote} ${
                      nivel.Precision.Reactivo.Vencimiento
                        ? `(${nivel.Precision.Reactivo.Vencimiento})`
                        : ''
                    }`}</span>
                    <span className='col-3 text-gray-600'>{`${nivel.Precision.Calibrador.Lote} ${
                      nivel.Precision.Calibrador.Vencimiento
                        ? `(${nivel.Precision.Calibrador.Vencimiento})`
                        : ''
                    }`}</span>
                    <span className='col-3 text-gray-600'>{`${nivel.Precision.Material.Nombre} | ${
                      nivel.Precision.Material.Lote
                    } ${
                      nivel.Precision.Material.Vencimiento
                        ? `(${nivel.Precision.Material.Vencimiento})`
                        : ''
                    }`}</span>
                  </div>
                </div>
                <div className='mb-10'>
                  <div className='row'>
                    <span className='col-2 form-label'>
                      {intl.formatMessage({id: 'HEADER.CONCENTRATION'})}
                    </span>
                    <span className='col-2 form-label'>
                      σ<sub>R</sub> (fabricante)
                    </span>
                    <span className='col-2 form-label'>
                      σ<sub>WL</sub> (fabricante)
                    </span>
                  </div>
                  <div className='row'>
                    <span className='col-2 text-gray-600'>
                      {toValueWithPrecisionString(nivel.Precision.Concentracion)}
                    </span>
                    <span className='col-2 text-gray-600'>
                      {toValueWithPrecisionString(nivel.Precision.SigmaRFab)}
                    </span>
                    <span className='col-2 text-gray-600'>
                      {toValueWithPrecisionString(nivel.Precision.SigmaWLFab)}
                    </span>
                  </div>
                </div>
                <TablaCorridasReporte indexNivel={nivelIndex} reporte={reporte} />
                {reporte.IsOutliersCountOk ? (
                  <>
                    <VerificacionPrecision indexNivel={nivelIndex} reporte={reporte} />
                    {reporte.Niveles[nivelIndex].Precision.InformeWithoutOutliers && (
                      <>
                        <h4>{intl.formatMessage({id: 'INFO.REMOVED_OUTLIER'})}</h4>
                        <VerificacionPrecisionWithoutOutliers
                          indexNivel={nivelIndex}
                          reporte={reporte}
                        />
                      </>
                    )}
                    {reporte.Niveles[nivelIndex].Precision.HasDataScatterWarning && (
                      <AlertWarning>
                        {intl.formatMessage({id: 'WARNING.DATA_SCATTER'})}
                      </AlertWarning>
                    )}
                  </>
                ) : (
                  <AlertDanger>{intl.formatMessage({id: 'WARNING.OUTLIERS_COUNT'})}</AlertDanger>
                )}
                {nivel.Precision.Comentarios && (
                  <div className='row'>
                    <div className='col-md-12'>
                      <span className='fs-5 fw-bold'>Comentarios</span>
                      <span className='text-gray-600'>{nivel.Precision.Comentarios}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
        {/* DESEMPENIO */}
        {reporte.IsOutliersCountOk && (
          <>
            <div className='card mb-5' style={{border: '1px ridge', pageBreakInside: 'avoid'}}>
              <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
                <div className='card-title align-items-start'>
                  <span className='fw-bold fs-2'>
                    {intl.formatMessage({id: 'HEADER.PERFORMANCE_REPORT'})}
                  </span>
                </div>
              </div>
              <div className='card-body py-8'>
                <TablaDesempenioPrecisionReporte reporte={reporte} />
              </div>
            </div>
            <div className='card mb-5' style={{border: '1px ridge', pageBreakInside: 'avoid'}}>
              <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
                <div className='card-title align-items-start'>
                  <span className='fw-bold fs-2'>
                    {intl.formatMessage({id: 'SETTINGS.SUMMARY'})}
                  </span>
                </div>
              </div>
              <div className='card-body py-8'>
                <TablaResumenPrecisionReporte reporte={reporte} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ReporteEp15a3Precision
