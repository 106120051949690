import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import Cleave from 'cleave.js/react'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  reporte: ReporteCompMetodosDTO
}

const ReporteMetodoCandidatoInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const metodoCandidato = reporte.Experimento.MetodoCandidato

  return (
    <div className='card mb-5' style={{border: '1px ridge'}}>
      <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
        <div className='card-title align-items-start col-7'>
          {intl.formatMessage({id: 'HEADER.CANDIDATE_METHOD'})}
        </div>
      </div>
      <div className='card-body py-8'>
        <div className='mb-5'>
          <div className='row'>
            <label className='col form-label'>
              {intl.formatMessage({id: 'MENU.MEASUREMENT_PROCEDURES'})}
            </label>
          </div>
          <div className='row'>
            <span className='col text-gray-600'>{`${metodoCandidato.ProcedimientoMedida?.Equipo.Descripcion} / ${metodoCandidato.ProcedimientoMedida?.Equipo.Plataforma} | ${metodoCandidato.ProcedimientoMedida?.Mensurando.Analito} / ${metodoCandidato.ProcedimientoMedida?.Mensurando.Matriz} / ${metodoCandidato.ProcedimientoMedida?.Mensurando.Unidades} | ${metodoCandidato.ProcedimientoMedida?.Metodo.Reactivo} / ${metodoCandidato.ProcedimientoMedida?.Metodo.PrincipioMedida}`}</span>
          </div>
        </div>
        <div className='mb-5'>
          <div className='row'>
            <label className='col-6 form-label'>{intl.formatMessage({id: 'HEADER.REAGENT'})}</label>
            <label className='col-6 form-label'>
              {intl.formatMessage({id: 'SETTINGS.CALIPER'})}
            </label>
          </div>
          <div className='row'>
            <span className='col-6 text-gray-600'>{`${metodoCandidato.Reactivo.Lote} ${
              metodoCandidato.Reactivo.Vencimiento
                ? `(${metodoCandidato.Reactivo.Vencimiento})`
                : ''
            }`}</span>
            <span className='col-6 text-gray-600'>{`${metodoCandidato.Calibrador.Lote} ${
              metodoCandidato.Calibrador.Vencimiento
                ? `(${metodoCandidato.Calibrador.Vencimiento})`
                : ''
            }`}</span>
          </div>
        </div>
        <div className='mb-5'>
          <div className='row'>
            <label className='col form-label'>
              {intl.formatMessage({id: 'HEADER.METROLOGICAL_TRACEABILITY'})}
            </label>
          </div>
          <div className='row'>
            <span className='col text-gray-600'>{metodoCandidato.TrazabilidadMetrologica}</span>
          </div>
        </div>
        <div className='mb-5'>
          <div className='row'>
            <label className='col form-label'>
              {intl.formatMessage({id: 'HEADER.MEASUREMENT_INTERVAL'})}
            </label>
          </div>
          <div className='row'>
            <span className='col text-gray-600'>{`${toValueWithPrecisionString(
              metodoCandidato.IntervaloMedicion.Min
            )} - ${toValueWithPrecisionString(metodoCandidato.IntervaloMedicion.Max)} (${
              metodoCandidato.ProcedimientoMedida?.Mensurando.Unidades
            })`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReporteMetodoCandidatoInfo
