import {FC, useState} from 'react'
import ScatterWithHistogramChartBuilder from './graficos/ScatterWithHistogramChartBuilderEqapt'
import RenderAnalisisChart from './graficos/RenderAnalisisChartEqapt'
import RenderAnalisisHistogram from './graficos/RenderAnalisisHistogramEqapt'
import {toAbsoluteUrl} from '../../../helpers'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useIntl} from 'react-intl'
import {ReporteCompMetodosEqaptDTO} from '../../../models/DTOs/SEM/comp-metodos-eqapt/ReporteCompMetodosEqaptDTO'

interface Props {
  reporte: ReporteCompMetodosEqaptDTO
}

const ReporteRenderGraficosAnalisisCompMetodosEqapt: FC<Props> = ({reporte}) => {
  const intl = useIntl()
  const animacionSelect = makeAnimated()

  const [cbChartTypes, setCbChartTypes] = useState([
    {
      value: 'diferencias_unidades_promedio',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.1'}),
    },
    {
      value: 'diferencias_porcentaje_promedio',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.2'}),
    },
  ])
  const [cbChartLimitTypes, setCbChartLimitTypes] = useState([
    {
      value: 'none',
      label: intl.formatMessage({id: 'METHOD_COMP.LIMITS.1'}),
    },
  ])
  const selectChartTypesOptions: {value: string; label: string}[] = [
    {
      value: 'diferencias_unidades_promedio',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.1'}),
    },
    {
      value: 'diferencias_porcentaje_promedio',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.2'}),
    },
    {
      value: 'diferencias_unidades_comparador',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.3'}),
    },
    {
      value: 'diferencias_porcentaje_comparador',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.4'}),
    },
    {
      value: 'diferencias_unidades_promedio_ranking',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.5'}),
    },
    {
      value: 'diferencias_porcentaje_promedio_ranking',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.6'}),
    },
    {
      value: 'diferencias_unidades_comparador_ranking',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.7'}),
    },
    {
      value: 'diferencias_porcentaje_comparador_ranking',
      label: intl.formatMessage({id: 'METHOD_COMP.CHARTS.8'}),
    },
  ]
  const selectChartLimitTypesOptions: {value: string; label: string}[] = [
    {
      value: 'none',
      label: intl.formatMessage({id: 'METHOD_COMP.LIMITS.1'}),
    },
    {
      value: 'bland_altman',
      label: intl.formatMessage({id: 'METHOD_COMP.LIMITS.2'}),
    },
    {
      value: 'mean_2stddev_plus_ci',
      label: intl.formatMessage({id: 'METHOD_COMP.LIMITS.3'}),
    },
    {
      value: 'mean_esa',
      label: intl.formatMessage({id: 'METHOD_COMP.LIMITS.4'}),
    },
  ]

  const getAvailableAnalisisCharts = () => {
    return cbChartTypes
  }

  const getAvailableAnalisisChartLimits = () => {
    return cbChartLimitTypes
  }

  const analisis = reporte.Analisis
  const precision = reporte.Precision
  const unidades = reporte.Experimento.MetodoCandidato.ProcedimientoMedida?.Mensurando.Unidades
  const selectedCharts = getAvailableAnalisisCharts()
  const selectedLimits = getAvailableAnalisisChartLimits()

  return (
    <>
      <div className={`${window.location.href.includes('/external') && 'd-none'}`}>
        <h1>{intl.formatMessage({id: 'HEADER.FILTER'})}</h1>
        <div className='mb-20 row'>
          <div className='col-6'>
            <label className='form-label'>{intl.formatMessage({id: 'HEADER.CHARTS'})}</label>
            <Select
              isMulti
              components={animacionSelect}
              closeMenuOnSelect={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#d6c6c9',
                  background: 'white',
                  height: 43,
                  borderWidth: 0.1,
                  boxShadow: 'none',
                }),
                valueContainer: (base, props) => ({
                  ...base,
                  overflowY: 'scroll',
                  maxHeight: 40,
                }),
              }}
              options={selectChartTypesOptions}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(choices) => {
                setCbChartTypes(
                  choices.map((c: any) => {
                    return c
                  })
                )
              }}
              value={cbChartTypes}
            />
          </div>
          <div className='col-6'>
            <label className='form-label'>{intl.formatMessage({id: 'HEADER.LIMITS'})}</label>
            <Select
              isMulti
              components={animacionSelect}
              closeMenuOnSelect={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#d6c6c9',
                  background: 'white',
                  height: 43,
                  borderWidth: 0.1,
                  boxShadow: 'none',
                }),
                valueContainer: (base, props) => ({
                  ...base,
                  overflowY: 'scroll',
                  maxHeight: 40,
                }),
              }}
              options={selectChartLimitTypesOptions}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(choices) => {
                setCbChartLimitTypes(
                  choices.map((c: any) => {
                    return c
                  })
                )
              }}
              value={cbChartLimitTypes}
            />
          </div>
        </div>
      </div>
      {selectedCharts.map(({value: chartType, label: chartTitle}) =>
        selectedLimits.map(({value: limitType}) => {
          let chartInfo
          switch (chartType) {
            case 'diferencias_unidades_promedio':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  intl.formatMessage({id: 'HEADER.AVERAGE_BOTH_METHODS'}),
                  `${intl.formatMessage({id: 'HEADER.CONCENTRATION_DIFFERENCE'})} (${unidades})`,
                  precision
                )
                .withValues(
                  analisis.Datos.map((dato) => {
                    return {
                      x: dato.Avg,
                      y: dato.Diff.Concentration,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_porcentaje_promedio':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  intl.formatMessage({id: 'HEADER.AVERAGE_BOTH_METHODS'}),
                  intl.formatMessage({id: 'HEADER.PERCENT_DIFFERENCE'}),
                  precision
                )
                .withValues(
                  analisis.Datos.map((dato) => {
                    return {
                      x: dato.Avg,
                      y: dato.DiffPercentAvg,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_unidades_comparador':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  intl.formatMessage({id: 'HEADER.COMPARATOR_METHOD'}),
                  `${intl.formatMessage({id: 'HEADER.CONCENTRATION_DIFFERENCE'})} (${unidades})`,
                  precision
                )
                .withValues(
                  analisis.Datos.map((dato) => {
                    return {
                      x: dato.X,
                      y: dato.Diff.Concentration,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_porcentaje_comparador':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  intl.formatMessage({id: 'HEADER.COMPARATOR_METHOD'}),
                  intl.formatMessage({id: 'HEADER.PERCENT_DIFFERENCE'}),
                  precision
                )
                .withValues(
                  analisis.Datos.map((dato) => {
                    return {
                      x: dato.X,
                      y: dato.Diff.Percent,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_unidades_promedio_ranking':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  'Ranking',
                  `${intl.formatMessage({id: 'HEADER.CONCENTRATION_DIFFERENCE'})} (${unidades})`,
                  precision
                )
                .withValues(
                  analisis.DatosRankedByAvg.map((dato: any) => {
                    return {
                      x: dato.Number,
                      y: dato.Diff.Concentration,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_porcentaje_promedio_ranking':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  'Ranking',
                  intl.formatMessage({id: 'HEADER.PERCENT_DIFFERENCE'}),
                  precision
                )
                .withValues(
                  analisis.DatosRankedByAvg.map((dato) => {
                    return {
                      x: dato.Number,
                      y: dato.DiffPercentAvg,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_unidades_comparador_ranking':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  'Ranking',
                  `${intl.formatMessage({id: 'HEADER.CONCENTRATION_DIFFERENCE'})} (${unidades})`,
                  precision
                )
                .withValues(
                  analisis.DatosRankedByX.map((dato) => {
                    return {
                      x: dato.Number,
                      y: dato.Diff.Concentration,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            case 'diferencias_porcentaje_comparador_ranking':
              chartInfo = new ScatterWithHistogramChartBuilder()
                .withInfo(
                  'Ranking',
                  intl.formatMessage({id: 'HEADER.PERCENT_DIFFERENCE'}),
                  precision
                )
                .withValues(
                  analisis.DatosRankedByX.map((dato) => {
                    return {
                      x: dato.Number,
                      y: dato.Diff.Percent,
                    }
                  })
                )
                .withHorizontalLineAt(0, '#525E64', '')
              break
            default:
              return
          }

          if (limitType !== 'none') {
            const minX = Math.min(...chartInfo.values.map((value: any) => value.x))
            const maxX = Math.max(...chartInfo.values.map((value: any) => value.x))

            if (chartType.includes('unidades')) {
              chartInfo.withHorizontalLineAt(analisis.DiffMean.Concentration, '#F7CA18', 'solid')

              switch (limitType) {
                case 'bland_altman':
                  chartInfo.withHorizontalPlotBand(
                    analisis.MeanMinus2StdDevIC95.Concentration.Value,
                    analisis.MeanPlus2StdDevIC95.Concentration.Value,
                    '#F7CA18',
                    '',
                    ''
                  )
                  break
                case 'mean_2stddev_plus_ci':
                  chartInfo
                    .withHorizontalPlotBand(
                      analisis.MeanIC95.Concentration.Range.Min,
                      analisis.MeanIC95.Concentration.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalPlotBand(
                      analisis.MeanPlus2StdDevIC95.Concentration.Range.Min,
                      analisis.MeanPlus2StdDevIC95.Concentration.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalPlotBand(
                      analisis.MeanMinus2StdDevIC95.Concentration.Range.Min,
                      analisis.MeanMinus2StdDevIC95.Concentration.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalLineAt(
                      analisis.MeanPlus2StdDevIC95.Concentration.Value,
                      '#F7CA18',
                      'shortdash'
                    )
                    .withHorizontalLineAt(
                      analisis.MeanMinus2StdDevIC95.Concentration.Value,
                      '#F7CA18',
                      'shortdash'
                    )
                  break
                case 'mean_esa':
                  chartInfo
                    .withCustomLine(
                      [
                        {
                          x: minX,
                          y: Math.max(
                            ...analisis.Datos.map((dato) => dato.DiffESa.Concentration.Min)
                          ),
                        },
                        {
                          x: maxX,
                          y: Math.min(
                            ...analisis.Datos.map((dato) => dato.DiffESa.Concentration.Min)
                          ),
                        },
                      ],
                      '#E43A45',
                      'solid'
                    )
                    .withCustomLine(
                      [
                        {
                          x: minX,
                          y: Math.min(
                            ...analisis.Datos.map((dato) => dato.DiffESa.Concentration.Max)
                          ),
                        },
                        {
                          x: maxX,
                          y: Math.max(
                            ...analisis.Datos.map((dato) => dato.DiffESa.Concentration.Max)
                          ),
                        },
                      ],
                      '#E43A45',
                      'solid'
                    )
                  break
              }
            } else {
              chartInfo.withHorizontalLineAt(analisis.DiffMean.Percent, '#F7CA18', 'solid')

              switch (limitType) {
                case 'bland_altman':
                  chartInfo.withHorizontalPlotBand(
                    analisis.MeanMinus2StdDevIC95.Percent.Value,
                    analisis.MeanPlus2StdDevIC95.Percent.Value,
                    '#F7CA18',
                    '',
                    ''
                  )
                  break
                case 'mean_2stddev_plus_ci':
                  chartInfo
                    .withHorizontalPlotBand(
                      analisis.MeanIC95.Percent.Range.Min,
                      analisis.MeanIC95.Percent.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalPlotBand(
                      analisis.MeanPlus2StdDevIC95.Percent.Range.Min,
                      analisis.MeanPlus2StdDevIC95.Percent.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalPlotBand(
                      analisis.MeanMinus2StdDevIC95.Percent.Range.Min,
                      analisis.MeanMinus2StdDevIC95.Percent.Range.Max,
                      '#E43A45',
                      '',
                      ''
                    )
                    .withHorizontalLineAt(
                      analisis.MeanPlus2StdDevIC95.Percent.Value,
                      '#F7CA18',
                      'shortdash'
                    )
                    .withHorizontalLineAt(
                      analisis.MeanMinus2StdDevIC95.Percent.Value,
                      '#F7CA18',
                      'shortdash'
                    )
                  break
                case 'mean_esa':
                  chartInfo
                    .withCustomLine(
                      [
                        {
                          x: minX,
                          y: Math.max(...analisis.Datos.map((dato) => dato.DiffESa.Percent.Min)),
                        },
                        {
                          x: maxX,
                          y: Math.min(...analisis.Datos.map((dato) => dato.DiffESa.Percent.Min)),
                        },
                      ],
                      '#E43A45',
                      'solid'
                    )
                    .withCustomLine(
                      [
                        {
                          x: minX,
                          y: Math.min(...analisis.Datos.map((dato) => dato.DiffESa.Percent.Max)),
                        },
                        {
                          x: maxX,
                          y: Math.max(...analisis.Datos.map((dato) => dato.DiffESa.Percent.Max)),
                        },
                      ],
                      '#E43A45',
                      'solid'
                    )
                  break
              }
            }
          }

          chartInfo = chartInfo.build()

          return (
            <div
              className='mb-10'
              key={`graficos_${chartType}_${limitType}`}
              style={{pageBreakInside: 'avoid'}}
            >
              <div className='row'>
                <h2 className='col-6'>{chartTitle}</h2>
                <h2 className='col-6'>{intl.formatMessage({id: 'HEADER.HISTOGRAM'})}</h2>
              </div>
              <div className='row mb-10'>
                <div className='col'>
                  <RenderAnalisisChart
                    info={chartInfo.scatterChart}
                    key={`cht_${chartType}_${limitType}_scatter`}
                  />
                  <div className='row margin-bottom-30 text-center'>
                    <div className='text-center' style={{margin: '0 auto'}}>
                      <span className='mx-3'>
                        <img src={toAbsoluteUrl('/media/otros/comp-metodos/Zero.png')} /> 0
                      </span>
                      {limitType !== 'none' ? (
                        <span className='mx-3'>
                          <img src={toAbsoluteUrl('/media/otros/comp-metodos/Media_line.png')} />{' '}
                          {intl.formatMessage({id: 'HEADER.MEAN'})}
                        </span>
                      ) : (
                        ''
                      )}
                      {limitType === 'mean_2stddev_plus_ci' ? (
                        <span className='mx-3'>
                          <img src={toAbsoluteUrl('/media/otros/comp-metodos/IC.png')} />{' '}
                          {intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL'})}
                        </span>
                      ) : (
                        ''
                      )}
                      {limitType === 'bland_altman' ? (
                        <span className='mx-3'>
                          <img src={toAbsoluteUrl('/media/otros/comp-metodos/Media_dash.png')} />{' '}
                          {intl.formatMessage({id: 'HEADER.MEAN'})} ± 1.96s
                        </span>
                      ) : (
                        ''
                      )}
                      {limitType === 'mean_esa' ? (
                        <span>
                          <img src={toAbsoluteUrl('/media/otros/comp-metodos/ESa.png')} /> ESa
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='col-md-5'>&nbsp;</div>
                  </div>
                </div>
                <div className='col'>
                  <RenderAnalisisHistogram
                    info={chartInfo.histogramChart}
                    key={`cht_${chartType}_${limitType}_histogram`}
                  />
                </div>
              </div>
            </div>
          )
        })
      )}
    </>
  )
}

export default ReporteRenderGraficosAnalisisCompMetodosEqapt
