import {FC} from 'react'
import {ReporteCompMetodosDTO} from '../../../../models/DTOs/SEM/comp-metodos/ReporteCompMetodosDTO'
import {useIntl} from 'react-intl'
import {convertToDecimal, toValueWithPrecisionString} from '../../../../helpers'
import {getByKey} from '../../../../models/DTOs/common/DoubleExpressionValueDTO'

interface Props {
  reporte: ReporteCompMetodosDTO
}

const ReporteCalculosCompMetodos: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const tipos = [
    {
      title: `${intl.formatMessage({id: 'HEADER.CONCENTRATION'})} (${
        reporte.Experimento.MetodoCandidato.ProcedimientoMedida?.Mensurando.Unidades
      })`,
      key: 'Concentration',
    },
    {
      title: `${intl.formatMessage({id: 'HEADER.PERCENT'})}`,
      key: 'Percent',
    },
  ]

  const analisis = reporte.Analisis
  const precision = reporte.Precision

  return (
    <>
      <h1 className='my-5'>{intl.formatMessage({id: 'HEADER.CALCULATIONS'})}</h1>
      <div className='mb-10'>
        <div className='row'>
          <label className='col-1 form-label'>ETa %</label>
          <label className='col-1 form-label'>ETa cc</label>
          <label className='col-1 form-label'>ESa %</label>
          <label className='col-1 form-label'>ESa cc</label>
        </div>
        <div className='row'>
          <span className='col-1 text-gray-600'>
            {toValueWithPrecisionString(reporte.Experimento.EspDesempAnalitico.ETa?.Percent) || '-'}
          </span>
          <span className='col-1 text-gray-600'>
            {toValueWithPrecisionString(
              reporte.Experimento.EspDesempAnalitico.ETa?.Concentration
            ) || '-'}
          </span>
          <span className='col-1 text-gray-600'>
            {toValueWithPrecisionString(reporte.Analisis.ESa.Percent) || '-'}
          </span>
          <span className='col-1 text-gray-600'>
            {toValueWithPrecisionString(reporte.Analisis.ESa.Concentration) || '-'}
          </span>
        </div>
      </div>
      <div className='mb-10'>
        <div className='row'>
          {tipos.map((tipo) => {
            return (
              <div
                key={`reporte_comp_metodos_calculos_tabla_${tipo.key}`}
                className='col table-responsive'
              >
                <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                  <thead
                    style={{
                      backgroundColor: '#f5f8ff',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    }}
                  >
                    <tr className='fw-bolder text-center'>
                      <th style={{width: '100%'}} colSpan={4}>
                        {tipo.title}
                      </th>
                    </tr>
                    <tr className='fw-bolder text-center'>
                      <th style={{width: '60%'}} colSpan={2} rowSpan={2}></th>
                      <th style={{width: '40%'}} colSpan={2}>
                        {intl.formatMessage({id: 'HEADER.CONFIDENCE_INTERVAL_95'})}
                      </th>
                    </tr>
                    <tr className='fw-bolder text-center'>
                      <th>{intl.formatMessage({id: 'HEADER.LOWER'})}</th>
                      <th>{intl.formatMessage({id: 'HEADER.UPPER'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='text-right'>
                      <td style={{width: '40%'}} className='text-left'>
                        N
                      </td>
                      <td style={{width: '20%'}} className='text-right'>
                        {analisis.N}
                      </td>
                    </tr>
                    <tr className='text-right'>
                      <td className='text-left'>
                        {intl.formatMessage({id: 'HEADER.DEGREES_FREEDOM_(N-1)'})}
                      </td>
                      <td>{analisis.DegreesFreedom}</td>
                    </tr>
                    <tr className='text-right'>
                      <td className='text-left'>
                        {intl.formatMessage({id: 'HEADER.DIFFERENCES_MEAN'})} (
                        <span style={{textDecoration: 'overline'}}>d</span>)
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanIC95, tipo.key).Value,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanIC95, tipo.key).Range.Min,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanIC95, tipo.key).Range.Max,
                          precision + 1
                        )}
                      </td>
                    </tr>
                    <tr className='text-right'>
                      <td className='text-left'>
                        {intl.formatMessage({id: 'HEADER.DIFFERENCES_DE'})}
                      </td>
                      <td>
                        {convertToDecimal(getByKey(analisis.DiffStdDev, tipo.key), precision + 1)}
                      </td>
                    </tr>
                    <tr className='text-right'>
                      <td className='text-left'>
                        <span style={{textDecoration: 'overline'}}>d</span> - 1.96s
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanMinus2StdDevIC95, tipo.key).Value,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanMinus2StdDevIC95, tipo.key).Range.Min,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanMinus2StdDevIC95, tipo.key).Range.Max,
                          precision + 1
                        )}
                      </td>
                    </tr>
                    <tr className='text-right'>
                      <td className='text-left'>
                        <span style={{textDecoration: 'overline'}}>d</span> + 1.96s
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanPlus2StdDevIC95, tipo.key).Value,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanPlus2StdDevIC95, tipo.key).Range.Min,
                          precision + 1
                        )}
                      </td>
                      <td>
                        {convertToDecimal(
                          getByKey(analisis.DiffMeanPlus2StdDevIC95, tipo.key).Range.Max,
                          precision + 1
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ReporteCalculosCompMetodos
