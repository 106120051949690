import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
import {ReporteUDTO} from '../../../models/DTOs/SEM/u/ReporteUDTO'
import {convertToDecimal} from '../../../helpers'
more(Highcharts)

interface Props {
  tipo: 'Concentration' | 'Percent'
  indexNivel: number
  reporte: ReporteUDTO
  unidades: string
}

const GraficoResumenU: FC<Props> = ({indexNivel, reporte, tipo, unidades}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  const title = `U (${unidades}) vs U<sup>obj</sup> (${unidades})`
  const isAceptado = reporte.ResumenNiveles[indexNivel].ConclusionMU[tipo].Name === 'aceptado'

  useEffect(() => {
    setOptions({
      chart: {
        height: '350px',
      },
      title: {
        text: title,
        useHTML: true,
      },
      xAxis: {
        categories: [`Nivel ${reporte.ResumenNiveles[indexNivel].Numero}`],
      },
      yAxis: {
        title: {
          text: `U (${unidades})`,
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: '#808080',
          },
        ],
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: true,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'U',
          type: 'column',
          data: [
            {
              y: +convertToDecimal(reporte.ResumenNiveles[indexNivel].UExp[tipo], 2),
              color: isAceptado ? '#0F0' : '#F00',
            },
          ],
          color: isAceptado ? '#0F0' : '#F00',
        },
        {
          name: `U<sub>obj</sub> (${unidades})`,
          type: 'errorbar',
          data: [[0, +convertToDecimal(reporte.ResumenNiveles[indexNivel].MUObj[tipo], 2)]],
          color: '#00F',
        },
      ],
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoResumenU
