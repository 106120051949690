import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ReporteCompMetodosEqaptDTO} from '../../../models/DTOs/SEM/comp-metodos-eqapt/ReporteCompMetodosEqaptDTO'

interface Props {
  reporte: ReporteCompMetodosEqaptDTO
}

const ReporteMetodoComparadorInfoEqapt: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const metodoComparador = reporte.Experimento.MetodoComparador

  return (
    <div className='card mb-5' style={{border: '1px ridge'}}>
      <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
        <div className='card-title align-items-start col-7'>
          {intl.formatMessage({id: 'HEADER.COMPARATOR_METHOD'})}
        </div>
      </div>
      <div className='card-body py-8'>
        <div className='mb-5'>
          <div className='row'>
            <label className='col-6 form-label'>{intl.formatMessage({id: 'HEADER.SCHEME'})}</label>
            <label className='col-6 form-label'>
              {intl.formatMessage({id: 'HEADER.COMPARISON_LEVEL'})}
            </label>
          </div>
          <div className='row'>
            <span className='col-6 text-gray-600'>{metodoComparador.Esquema}</span>
            <span className='col-6 text-gray-600'>{metodoComparador.NivelComparacion}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReporteMetodoComparadorInfoEqapt
