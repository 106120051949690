import {FC} from 'react'
import {ExperimentoUDTO} from '../../../models/DTOs/SEM/u/ExperimentoUDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'

interface Props {
  experimento: ExperimentoUDTO
}

const ReporteValoresAsignadosU: FC<Props> = ({experimento}) => {
  const intl = useIntl()

  const valoresAsignados = experimento.ValorAsignado
    ? [experimento.ValorAsignado]
    : experimento.ValoresAsignados

  return (
    <>
      {valoresAsignados?.map((valorAsignado) => {
        return (
          <div
            key={`valor_asignado_${valorAsignado.Numero}_u`}
            className='card mb-5'
            style={{border: '1px ridge'}}
          >
            <div className='card-header border-2 pt-5' style={{backgroundColor: '#f5f8fa'}}>
              <div className='card-title align-items-start col-7'>{`${intl.formatMessage({
                id: 'HEADER.ASSIGNED_VALUE',
              })} ${valorAsignado.Numero} (${intl.formatMessage({
                id: 'HEADER.NDM',
              })})`}</div>
            </div>
            <div className='card-body py-8'>
              <h4>
                u<sub>sesgo</sub>
              </h4>
              <div className='mb-8'>
                <div className='row'>
                  <label className='col-3 form-label'>CRM</label>
                  <label className='col-2 form-label'>
                    {intl.formatMessage({id: 'HEADER.ASSIGNED_VALUE'})}
                  </label>
                  <label className='col-2 form-label'>
                    U<sub>cref</sub> ({experimento.ProcedimientoMedida.Mensurando.Unidades})
                  </label>
                  <label className='col-2 form-label'>
                    u<sub>cref</sub> ({experimento.ProcedimientoMedida.Mensurando.Unidades})
                  </label>
                  <label className='col-3 form-label'>
                    {intl.formatMessage({id: 'HEADER.COVERAGE_FACTOR'})} (k)
                  </label>
                </div>
                <div className='row'>
                  <span className='col-3 text-gray-600'>{valorAsignado.uSesgo.CRM}</span>
                  <span className='col-2 text-gray-600'>
                    {toValueWithPrecisionString(valorAsignado.uSesgo.ValorAsignado)}
                  </span>
                  <span className='col-2 text-gray-600'>
                    {toValueWithPrecisionString(valorAsignado.uSesgo.UExpcref)}
                  </span>
                  <span className='col-2 text-gray-600'>
                    {toValueWithPrecisionString(valorAsignado.uSesgo.UCombcref)}
                  </span>
                  <span className='col-3 text-gray-600'>
                    {toValueWithPrecisionString(valorAsignado.uSesgo.KFactor)}
                  </span>
                </div>
              </div>
              <div className='row mb-8'>
                <label className='col-3 form-label'>
                  {intl.formatMessage({id: 'HEADER.DATE'})}
                </label>
                <br />
                <span className='text-gray-600'>{valorAsignado.FechaReplicas}</span>
              </div>
              <div className='col-3 table-responsive mb-8'>
                <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
                  <thead
                    style={{
                      backgroundColor: '#f5f8ff',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    }}
                  >
                    <tr className='fw-bolder text-center'>
                      <th style={{width: '50%'}}>{intl.formatMessage({id: 'HEADER.REPLICATE'})}</th>
                      <th style={{width: '50%'}}>
                        {intl.formatMessage({id: 'HEADER.VALUE'})} (
                        {experimento.ProcedimientoMedida.Mensurando.Unidades})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {valorAsignado.Replicas.map((replica) => {
                      return (
                        <tr
                          key={`valor_asignado_${valorAsignado.Numero}_replica_${replica.Numero}_u`}
                        >
                          <td className='text-center'>{replica.Numero}</td>
                          <td className='text-right'>
                            {toValueWithPrecisionString(replica.Valor)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ReporteValoresAsignadosU
