import {FC} from 'react'
import {ReporteVIRDTO} from '../../../models/DTOs/SEM/vir/ReporteVIRDTO'
import {useIntl} from 'react-intl'

interface Props {
  reporte: ReporteVIRDTO
}

const ReporteVIRExperimentoInfo: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <div className='mb-10'>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'HEADER.EVALUATIONDATE'})}
          </label>
          <label className='form-label col-5'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.OPERATOR'})}</label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-2'>{reporte.Experimento.Fecha}</span>
          <span className='text-gray-600 col-5'>{`${reporte.Experimento.ProcedimientoMedida.Equipo.Descripcion} / ${reporte.Experimento.ProcedimientoMedida.Equipo.Plataforma} | ${reporte.Experimento.ProcedimientoMedida.Mensurando.Analito} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Matriz} / ${reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades} | ${reporte.Experimento.ProcedimientoMedida.Metodo.Reactivo} / ${reporte.Experimento.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='text-gray-600 col-3'>{reporte.Experimento.Usuario}</span>
        </div>
      </div>
      <div className='mb-8'>
        <div className='row'>
          <label className='form-label col-6'>
            {intl.formatMessage({id: 'HEADER.POPULATION'})}
          </label>
        </div>
        <div className='row'>
          <span className='text-gray-600 col-6'>{reporte.Experimento.Poblacion}</span>
        </div>
      </div>
    </div>
  )
}

export default ReporteVIRExperimentoInfo
