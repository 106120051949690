import {FC} from 'react'
import {ReporteVIRDTO} from '../../../models/DTOs/SEM/vir/ReporteVIRDTO'
import {useIntl} from 'react-intl'
import {toValueWithPrecisionString} from '../../../helpers'
import TablaAnalisisValoresReporteVIR from './TablaAnalisisValoresReporteVIR'
import GraficoAnalisisReporteVIR from './GraficoAnalisisReporteVIR'

interface Props {
  reporte: ReporteVIRDTO
}

const AnalisisReporteVIR: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  return (
    <>
      <h1 className='my-5'>{intl.formatMessage({id: 'HEADER.DATA_ANALYSIS'})}</h1>
      <div className='mb-10'>
        <div className='row'>
          <label className='col-2 form-label'>{intl.formatMessage({id: 'HEADER.INTERVAL'})}</label>
          <label className='col-3 form-label'>
            {intl.formatMessage({id: 'HEADER.RESULTS_TOTAL_EXCLUDED'})}
          </label>
          <label className='col-3 form-label'>
            {intl.formatMessage({id: 'HEADER.LIMITS_EXCLUDED'})}
          </label>
        </div>
        <div className='row'>
          <span className='col-2 text-gray-600'>{`${toValueWithPrecisionString(
            reporte.Experimento.Intervalo.Min
          )} - ${toValueWithPrecisionString(reporte.Experimento.Intervalo.Max)} ${
            reporte.Experimento.ProcedimientoMedida.Mensurando.Unidades
          }`}</span>
          <span className='col-3 text-gray-600'>{`${reporte.Analisis.TotalValoresExcluded}/${reporte.Analisis.TotalValores}`}</span>
          <span className='col-3 text-gray-600'>{`${toValueWithPrecisionString(
            reporte.Analisis.MaxExludedValoresPercent
          )}% / ${toValueWithPrecisionString(reporte.Analisis.ValoresExludedPercent)}%`}</span>
        </div>
      </div>
      <div className='row mb-10'>
        <div className='col-5'>
          <TablaAnalisisValoresReporteVIR reporte={reporte} />
        </div>
        <div className='col-7'>
          <GraficoAnalisisReporteVIR reporte={reporte} />
        </div>
      </div>
    </>
  )
}

export default AnalisisReporteVIR
